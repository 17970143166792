import React, { memo, useCallback, useEffect } from 'react';
import { Box, IconButton, Stack, Typography } from '@mui/material';
import { LexicalComposer } from "@lexical/react/LexicalComposer";
import { RichTextPlugin } from "@lexical/react/LexicalRichTextPlugin";
import { ContentEditable } from "@lexical/react/LexicalContentEditable";
import { TablePlugin } from '@lexical/react/LexicalTablePlugin';
import LexicalErrorBoundary from "@lexical/react/LexicalErrorBoundary";
import { HeadingNode } from "@lexical/rich-text";
import { TableCellNode, TableNode, TableRowNode } from "@lexical/table";
import { ListItemNode, ListNode } from "@lexical/list";
import { ListPlugin } from "@lexical/react/LexicalListPlugin";
import EditorThemeClasses from "../../../components/lexical/wrappers/RichTextEditor/theme";
import { HistoryPlugin } from "@lexical/react/LexicalHistoryPlugin";
import { TabIndentationPlugin } from "@lexical/react/LexicalTabIndentationPlugin";
import { HorizontalRulePlugin } from "@lexical/react/LexicalHorizontalRulePlugin";
import { ClearEditorPlugin } from "@lexical/react/LexicalClearEditorPlugin";
import { AutoFocusPlugin } from "@lexical/react/LexicalAutoFocusPlugin";
import TableCellResizerPlugin from "../../../components/lexical/wrappers/RichTextEditor/TableCellResizer";
import SyncSummaryEditorPlugin from "../../../components/form/SyncSummaryEditorPlugin";
import { KeyTermNode } from '../../../components/lexical/nodes/KeyTermNode';
import { useResearchPanelData } from '../../../containers/ResearchPanelData/ResearchPanelData';
import { useTakerState } from '../../../containers/TakerDocumentState/TakerDocumentState';
import { Link } from '@mui/icons-material';

import '../../../components/lexical/wrappers/RichTextEditor/index.css';

const textType = "text/plain";
const htmlType = "text/html";

const KeyTerms = () => {
    const { activeTakerDocument } = useTakerState();
    const { documentKeyTermHolders } = useResearchPanelData();
    const {
        displayContext,
        researchPanelBasicState,
        focusedKeyTermIdentifier
    } = useResearchPanelData();

    const takerDocumentUploads = activeTakerDocument?.takerDocumentUploads;

    const doCopyReferenceLink = useCallback((keyTermName: string) => {
        const innerText = keyTermName;
        const hash = `_ktrefid=${window.crypto.randomUUID()}`;
        const link = `<a href="/referenceAsset#${hash}">${innerText}</a>`;
                
        const textBlob = new Blob([innerText], { type: textType });
        const htmlBlob = new Blob([link], { type: htmlType });
        navigator.clipboard.write([
            new ClipboardItem({
                [textType]: textBlob,
                [htmlType]: htmlBlob
            })
        ]);
    }, []);

    useEffect(() => {
        if (displayContext === "WORKFLOW"
            && focusedKeyTermIdentifier !== undefined
            && researchPanelBasicState.isOpen 
            && researchPanelBasicState.currentTab === 'KEY_TERMS'
        ) {
            const elem = document.getElementById(focusedKeyTermIdentifier);
            elem?.scrollIntoView({ 
                behavior: "smooth", 
                block: "center" 
            });
        }
    }, [
        focusedKeyTermIdentifier,
        researchPanelBasicState
    ]);

    if (takerDocumentUploads?.length === 0) {
        return (
            <Box paddingTop={1}>
                <Typography
                    variant="subtitle2"
                    gutterBottom
                >
                    No key terms
                </Typography>
            </Box>
        );
    }

    return (
        <Box
            width="100%"
            height="100%"
            overflow="auto"
        >
            {takerDocumentUploads?.map((tdu) => {
                const holder = documentKeyTermHolders[tdu.id];
                return (
                    <Box
                        key={tdu.id}
                        paddingTop={1}
                        paddingLeft={1}
                        paddingRight={1}
                    >
                        <Typography
                            variant="subtitle1"
                            textAlign="center"
                        >
                            {tdu.name}
                        </Typography>
                        <Stack
                            spacing={1}
                            paddingTop={1}
                        >
                            {(holder !== undefined) ? (
                                holder.documentKeyTerms.keyTerms.map(kt => (
                                    <Box
                                        key={kt.identifier}
                                        id={kt.identifier}
                                        sx={{
                                            width: '100%',
                                            borderRadius: 1,
                                            border: "1px solid rgb(195, 195, 195)",
                                            backgroundColor: 'white',
                                        }}
                                    >
                                        <Typography
                                            variant="subtitle2"
                                            gutterBottom
                                            sx={{
                                                paddingTop: 0.5,
                                                paddingLeft: 1
                                            }}
                                            display="flex"
                                            alignItems="center"
                                            gap={1}
                                        >
                                            <u>{kt.termName}</u>
                                            <IconButton
                                                size="small"
                                                onClick={() => doCopyReferenceLink(kt.termName)}
                                            >
                                                <Link />
                                            </IconButton>
                                        </Typography>
                                        <Box paddingBottom={1}>
                                            <LexicalComposer
                                                initialConfig={{
                                                    editable: false,
                                                    namespace: `view-summary-${kt.termName}`,
                                                    theme: EditorThemeClasses,
                                                    onError(error: any) {
                                                        console.error(error);
                                                    },
                                                    nodes: [
                                                        HeadingNode,
                                                        ListNode,
                                                        ListItemNode,
                                                        TableNode,
                                                        TableCellNode,
                                                        TableRowNode,
                                                        KeyTermNode
                                                    ],
                                                }}
                                            >
                                                <SyncSummaryEditorPlugin defaultValue={kt.summary} />
                                                <RichTextPlugin
                                                    contentEditable={
                                                        <div className="editor-scroller">
                                                            <div className="editor">
                                                                <ContentEditable
                                                                    className="editor-input"
                                                                    style={{
                                                                        paddingTop: '0px',
                                                                        paddingBottom: '0px',
                                                                        minHeight: '25px'
                                                                    }}
                                                                />
                                                            </div>
                                                        </div>
                                                    }
                                                    placeholder={<div className="editor-placeholder"></div>}
                                                    ErrorBoundary={LexicalErrorBoundary}
                                                />
                                                <ListPlugin />
                                                <HistoryPlugin />
                                                <AutoFocusPlugin />
                                                <TabIndentationPlugin />
                                                <HorizontalRulePlugin />
                                                <ClearEditorPlugin />
                                                <TablePlugin hasCellMerge />
                                                <TableCellResizerPlugin />
                                            </LexicalComposer>
                                        </Box>
                                    </Box>
                                ))
                            ) : (
                                <Typography
                                    variant="subtitle2"
                                    gutterBottom
                                >
                                    No key terms
                                </Typography>
                            )}
                        </Stack>
                    </Box>
                );
            })}
        </Box>
    );
};

export default memo(KeyTerms);