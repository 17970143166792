import { useState } from 'react';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import { Organization } from "../../redux/models/dataModelTypes";
import { useListOrganizationsQuery } from '../../redux/services/organization';
import PagedTable from '../../components/tables/pagedTable';
import { ManageOrganizationGuidance } from './manageOrganizationGuidance';
import { Article, Assistant, CardMembership } from '@mui/icons-material';
import { CrudButtonGroup } from '../../components/buttons/crudButtonGroup';
import { ManageOrganizationModelIntegrations } from './manageOrganizationModelIntegrations';

export const OrganizationTable = () => {
    const [editGuidance, setEditGuidance] = useState<string>();
    const [editModels, setEditModels] = useState<string>();
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(5);

    const { 
        data: organizations, 
        refetch: refetchOrganizations 
    } = useListOrganizationsQuery({page, limit});

    return (
        <>
            <PagedTable
                paginatedResponse={organizations}
                tableSpan={5}
                rowMapper={(org: Organization) => (               
                    <TableRow
                        key={org.id}
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                        <TableCell component="th" scope="row">
                            {org.name}
                        </TableCell>
                        <TableCell component="th" scope="row">
                            {new Date(org.createdAt).toLocaleString()}
                        </TableCell>
                        <TableCell style={{ width: 160 }} align="right">
                            <CrudButtonGroup 
                                buttons={[
                                    {
                                        icon: <Article/>,
                                        handleClick: () => setEditGuidance(org.id)
                                    },
                                    {
                                        icon: <Assistant/>,
                                        handleClick: () => setEditModels(org.id)
                                    }
                                ]} 
                            />
                            <ManageOrganizationGuidance
                                organization={org}
                                onAdd={() => refetchOrganizations()}
                                open={editGuidance === org.id}
                                onClose={() => setEditGuidance(undefined)} 
                            />
                            <ManageOrganizationModelIntegrations
                                organization={org}
                                onAdd={() => refetchOrganizations()}
                                open={editModels === org.id}
                                onClose={() => setEditModels(undefined)} 
                            />
                        </TableCell>
                    </TableRow>
                )}
                headers={[
                    "Name",
                    "Created At",
                    ""
                ]}
                page={page}
                limit={limit}
                setPage={setPage}
                setLimit={setLimit}
            />
        </>
    );
}
