import React, { memo } from "react";
import { Typography } from "@mui/material/";
import BoxWithHoverBg from "./BoxWithHoverBg";

interface QuestionMetaProps {
    commentMetadata: Record<string, any>;
    onClick: () => void;
}

const QuestionMeta = ({
    commentMetadata,
    onClick
}: QuestionMetaProps) => {
    const { 
        questionId, 
        questionLabel 
    } = commentMetadata;

    return (
        <BoxWithHoverBg
            data-testid="comments-drawer-question-meta"
            width="100%"
            onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
                const elem = questionId && document.getElementById(questionId);
                if (elem) {
                    elem.scrollIntoView({ block: 'center' });
                }
                onClick();
            }}
        >
            <Typography
                variant="body2"
                color="text.primary"
                noWrap
                data-testid="comments-drawer-question-label"
            >
                <strong>{questionLabel}</strong>
            </Typography>
        </BoxWithHoverBg>
    );
}

export default memo(QuestionMeta);
