import React from "react";
import Box from '@mui/material/Box';
import { SimpleModalWrapper } from "../../../components/dialog/wrappers/simpleModalWrapper";
import { Module } from "../../../types/builderv2.generated";
import { RJSFSchema } from '@rjsf/utils';
import validator from '@rjsf/validator-ajv8';
import { Form } from "../../../components/jsonschema/theme";
import CustomCommentaryWidget from "../SpecBuilder/CustomCommentaryWidget";

interface EditTableModuleModalProps {
    fullSchema: RJSFSchema;
    parentModule: Module | undefined;
    initialTopModule: Module;
    onUpdate: (b: Module) => void;
    onClose: () => void;
}

const EditTableModuleModal = ({
    fullSchema,
    parentModule,
    initialTopModule,
    onUpdate,
    onClose
}: EditTableModuleModalProps) => {
    const searchModuleHelper = (moduleId: string, module: Module): Module | null => {
        if (moduleId === module.id) {
            return module;
        }

        if (module.nestedModules) {
            for (const m of module.nestedModules) {
                let res = searchModuleHelper(moduleId, m);
                if (!!res) {
                    return res;
                }
            }
        }
        return null;
    };

    const addModuleHelper = (moduleId: string, moduleToAdd: Module, modules: Module[]) => {
        const newModules: Module[] = [];
        for (const m of modules) {
            const newModule: Module = JSON.parse(JSON.stringify(m));
            if (!newModule.nestedModules) {
                newModule.nestedModules = [];
            }
            newModule.nestedModules = addModuleHelper(moduleId, moduleToAdd, newModule.nestedModules);
            if (m.id === moduleId) {
                newModule.nestedModules.push(JSON.parse(JSON.stringify(moduleToAdd)));
            }
            newModules.push(newModule);
        }
        return newModules;
    };

    const updateModuleHelper = (moduleToUpdate: Module, modules: Module[]) => {
        const newModules: Module[] = [];
        for (const m of modules) {
            const newModule: Module = JSON.parse(JSON.stringify(m));
            if (m.id === moduleToUpdate.id) {
                newModules.push(JSON.parse(JSON.stringify(moduleToUpdate)));
            } else if (newModule.nestedModules) {
                newModule.nestedModules = updateModuleHelper(moduleToUpdate, newModule.nestedModules);
                newModules.push(newModule);
            } else {
                newModules.push(newModule);
            }
        }
        return newModules;
    };

    const updateModule = (m: Module) => {
        const newTopModule = updateModuleHelper(m, [initialTopModule])[0];
        onUpdate(newTopModule);
    };


    if (!parentModule) {
        return null;
    }

    return (
        <SimpleModalWrapper
            headerText="Table fulfillment"
            open
            handleClose={onClose}
            maxWidth='md'
        >
            <Box
                sx={{
                    width: '100%',
                    paddingTop: '3%',
                    alignContent: "center"
                }}
            >
                <Form
                    formData={parentModule.uiFulfillment}
                    onSubmit={(data, event) => {
                        let newMod: Module = JSON.parse(JSON.stringify(parentModule));
                        newMod.uiFulfillment = data.formData;
                        onClose();
                        updateModule(newMod);
                    }}
                    uiSchema={{
                        commentary: {
                            'ui:widget': 'CustomCommentaryWidget'
                        }
                    }}
                    widgets={{
                        CustomCommentaryWidget
                    }}
                    schema={({
                        $ref: "#/$defs/tableFulfillment",
                        $defs: fullSchema.$defs
                    } as RJSFSchema)}
                    validator={validator}
                />
            </Box>
        </SimpleModalWrapper>
    );
}

export default EditTableModuleModal;

