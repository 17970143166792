import React, { useState } from "react";
import Box from '@mui/material/Box';
import { SimpleModalWrapper } from "../../../components/dialog/wrappers/simpleModalWrapper";
import { BasicDataSpec, BasicTableDataSpec, GraphFulfillment, Module, TableFulfillment, TableWithSingleAnalysisDataSpec } from "../../../types/builderv2.generated";
import { Accordion, AccordionDetails, AccordionSummary, Button, Collapse, Grid, Tab, Tabs, TextField, Typography, } from "@mui/material";
import TabPanel, { a11yProps } from "../../../components/navigation/TabPanel";
import { RJSFSchema } from '@rjsf/utils';
import validator from '@rjsf/validator-ajv8';
import { Form } from "../../../components/jsonschema/theme";
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import { ArrowDownward, ArrowRight, Edit } from "@mui/icons-material";
import NodesEditor from "../SpecBuilder/nodes";
import VariablesEditor from "../SpecBuilder/variables";
import EdgesEditor from "../SpecBuilder/edges";
import { CrudButtonGroup, buttonItem } from "../../../components/buttons/crudButtonGroup";
import CustomCommentaryWidget from "../SpecBuilder/CustomCommentaryWidget";

interface InsertIntoModuleModalProps {
    parentModule: Module | undefined;
    initialTopModule: Module;
    onUpdate: (b: Module) => void;
    onClose: () => void;
}

const InsertIntoModuleModal = ({
    parentModule,
    initialTopModule,
    onUpdate,
    onClose
}: InsertIntoModuleModalProps) => {
    const [tabValue, setTabValue] = useState(0);
    const [newModuleId, setNewModuleId] = useState<string>('placeholder');
    const [newDisplayName, setNewDisplayName] = useState<string>('Placeholder');

    const addModuleHelper = (moduleId: string, moduleToAdd: Module, modules: Module[]) => {
        const newModules: Module[] = [];
        for (const m of modules) {
            const newModule: Module = JSON.parse(JSON.stringify(m));
            if (!newModule.nestedModules) {
                newModule.nestedModules = [];
            }
            newModule.nestedModules = addModuleHelper(moduleId, moduleToAdd, newModule.nestedModules);
            if (m.id === moduleId) {
                newModule.nestedModules.push(JSON.parse(JSON.stringify(moduleToAdd)));
            }
            newModules.push(newModule);
        }
        return newModules;
    };

    const addModule = (moduleId: string, m: Module) => {
        m.id = newModuleId;
        m.displayName = newDisplayName;
        const newTopModule = addModuleHelper(moduleId, m, [initialTopModule])[0];
        onUpdate(newTopModule);
    };

    const addBasicGraphModule = (moduleId: string) => {
        addModule(moduleId, {
            id: "placeholder",
            variables: [],
            nestedModules: [],
            dataSpec: {
                dataSpecType: "basic",
                fields: []
            } as BasicDataSpec,
            uiFulfillment: {
                fulfillmentType: "graph",
                nodes: [],
                edges: [],
                endConditions: []
            } as GraphFulfillment
        } as Module);
    };

    const addTableGraphModule = (moduleId: string) => {
        addModule(moduleId, {
            id: "placeholder",
            variables: [],
            nestedModules: [],
            dataSpec: {
                dataSpecType: "basic-table",
                fields: []
            } as BasicTableDataSpec,
            uiFulfillment: {
                fulfillmentType: "graph",
                nodes: [],
                edges: [],
                endConditions: []
            } as GraphFulfillment
        } as Module);
    };

    const addTableModule = (moduleId: string) => {
        addModule(moduleId, {
            id: "placeholder",
            variables: [],
            nestedModules: [],
            dataSpec: {
                dataSpecType: "table-with-single-analysis"
            } as TableWithSingleAnalysisDataSpec,
            uiFulfillment: {
                fulfillmentType: "table"
            } as TableFulfillment
        } as Module);
    };

    if (!parentModule) {
        return null;
    }

    return (
        <SimpleModalWrapper
            headerText={`In ${parentModule.displayName}`}
            open
            handleClose={onClose}
            maxWidth='md'
        >
            <Box width="100%">
                <Tabs
                    value={tabValue}
                    variant="scrollable"
                    scrollButtons="auto"
                    onChange={(event: React.SyntheticEvent, newValue: number) => setTabValue(newValue)}
                >
                    <Tab
                        label="Modules"
                        {...a11yProps(0)}
                    />
                    {parentModule.uiFulfillment?.fulfillmentType === "graph" && (
                        <Tab
                            label="Nodes"
                            {...a11yProps(1)}
                        />
                    )}
                </Tabs>
            </Box>
            <Box
                width="100%"
                overflow="auto"
                flexGrow={1}
                zIndex={1}
            >
                <TabPanel value={tabValue} index={0}>
                    <Box
                        width="100%"
                        alignItems="center"
                    >
                        <TextField
                            sx={{ m: 1 }}
                            size="small"
                            value={newModuleId}
                            onChange={(e) => {
                                setNewModuleId(e.target.value);
                            }}
                        />
                        <TextField
                            sx={{ m: 1 }}
                            size="small"
                            value={newDisplayName}
                            onChange={(e) => {
                                setNewDisplayName(e.target.value);
                            }}
                        />
                    </Box>
                    <Box
                        sx={{
                            width: '100%',
                            paddingTop: '3%',
                            alignContent: "center"
                        }}
                    >
                        <Button
                            sx={{ m: 1 }}
                            variant="contained"
                            size="small"
                            onClick={() => {
                                addBasicGraphModule(parentModule.id);
                                onClose();
                            }}
                        >
                            Basic Graph Module
                        </Button>
                        <Button
                            sx={{ m: 1 }}
                            variant="contained"
                            size="small"
                            onClick={() => {
                                addTableGraphModule(parentModule.id);
                                onClose();
                            }}
                        >
                            Table Graph Module
                        </Button>
                        <Button
                            sx={{ m: 1 }}
                            variant="contained"
                            size="small"
                            onClick={() => {
                                addTableModule(parentModule.id);
                                onClose();
                            }}
                        >
                            Table Module
                        </Button>
                    </Box>
                </TabPanel>
                {parentModule.uiFulfillment?.fulfillmentType === "graph" && (
                    <TabPanel value={tabValue} index={1}>



                    </TabPanel>
                )}
            </Box>
        </SimpleModalWrapper>
    );
}

export default InsertIntoModuleModal;





interface ModuleRowProps {
    praxiModule: Module;
    setEditModuleId: (mid: string) => void;
    onDelete: (moduleId: string) => void;
    level: number;
}

const ModuleRow = ({
    praxiModule,
    setEditModuleId,
    onDelete,
    level
}: ModuleRowProps) => {
    const [open, setOpen] = useState<boolean>(true);
    const buttons: buttonItem[] = [
        {
            disabled: (praxiModule.nestedModules?.length === 0),
            icon: (
                open ?
                    <ArrowDownward fontSize="small" /> :
                    <ArrowRight fontSize="small" />
            ),
            handleClick: () => {
                setOpen(!open);
            }
        },
        {
            icon: (<Edit fontSize="small" />),
            handleClick: () => {
                setEditModuleId(praxiModule.id);
            }
        }
    ];

    return (
        <React.Fragment>
            <TableRow
                sx={{
                    '& > *': { borderBottom: 'unset' },
                    "&:hover": {
                        bgcolor: "rgba(0, 0, 0, 0.04)",
                    }
                }}
            >
                <TableCell colSpan={level} />
                <TableCell component="th" scope="row">
                    {praxiModule.id}
                </TableCell>
                <TableCell component="th" scope="row">
                    {praxiModule.uiFulfillment?.fulfillmentType}
                </TableCell>
                <TableCell scope="row">
                    <CrudButtonGroup buttons={buttons} />
                </TableCell>
            </TableRow>
            <Collapse in={open}>
                {praxiModule.nestedModules?.map(pm => (
                    <TableRow>
                        <TableCell colSpan={3 + level}>
                            <ModuleRow
                                level={level + 1}
                                praxiModule={pm}
                                setEditModuleId={setEditModuleId}
                                onDelete={onDelete}
                            />
                        </TableCell>
                    </TableRow>
                ))}
            </Collapse>
        </React.Fragment>
    );
};

interface ModuleEditorProps {
    fullSchema: RJSFSchema;
    initialTopModule: Module;
    onUpdate: (b: Module) => void;
}

const ModuleEditor = ({
    fullSchema,
    initialTopModule,
    onUpdate
}: ModuleEditorProps) => {
    const [editModuleId, setEditModuleId] = React.useState<string>(initialTopModule.id);
    const [dataSpecOpen, setDataSpecOpen] = useState<boolean>(false);
    const [fulfillmentOpen, setFulfillmentOpen] = useState<boolean>(false);

    const searchModuleHelper = (moduleId: string, module: Module): Module | null => {
        if (moduleId === module.id) {
            return module;
        }

        if (module.nestedModules) {
            for (const m of module.nestedModules) {
                let res = searchModuleHelper(moduleId, m);
                if (!!res) {
                    return res;
                }
            }
        }
        return null;
    };

    const editModule = React.useMemo(() => 
        searchModuleHelper(editModuleId, initialTopModule), 
        [editModuleId, initialTopModule]
    );

    const addModuleHelper = (moduleId: string, moduleToAdd: Module, modules: Module[]) => {
        const newModules: Module[] = [];
        for (const m of modules) {
            const newModule: Module = JSON.parse(JSON.stringify(m));
            if (!newModule.nestedModules) {
                newModule.nestedModules = [];
            }
            newModule.nestedModules = addModuleHelper(moduleId, moduleToAdd, newModule.nestedModules);
            if (m.id === moduleId) {
                newModule.nestedModules.push(JSON.parse(JSON.stringify(moduleToAdd)));
            }
            newModules.push(newModule);
        }
        return newModules;
    };

    const searchParentModuleHelper = (moduleId: string, module: Module): Module | null => {
        if (module.nestedModules) {
            for (const m of module.nestedModules) {
                if (m.id === moduleId) {
                    return module;
                } else {
                    let res = searchParentModuleHelper(moduleId, m);
                    if (!!res) {
                        return res;
                    }
                }
            }
        }
        return null;
    };

    const updateModuleHelper = (moduleToUpdate: Module, modules: Module[]) => {
        const newModules: Module[] = [];
        for (const m of modules) {
            const newModule: Module = JSON.parse(JSON.stringify(m));
            if (m.id === moduleToUpdate.id) {
                newModules.push(JSON.parse(JSON.stringify(moduleToUpdate)));
            } else if (newModule.nestedModules) {
                newModule.nestedModules = updateModuleHelper(moduleToUpdate, newModule.nestedModules);
                newModules.push(newModule);
            } else {
                newModules.push(newModule);
            }
        }
        return newModules;
    };

    const updateModule = (m: Module) => {
        const newTopModule = updateModuleHelper(m, [initialTopModule])[0];
        onUpdate(newTopModule);
    };

    const onDeleteHelper = (moduleId: string, modules: Module[]) => {
        const newModules: Module[] = [];
        for (const m of modules) {
            if (m.id !== moduleId) {
                const newModule: Module = JSON.parse(JSON.stringify(m));
                if (m.nestedModules) {
                    newModule.nestedModules = onDeleteHelper(moduleId, m.nestedModules);
                }
                newModules.push(newModule);
            }
        }
        return newModules;
    };

    const onDelete = (moduleId: string) => {
        const newTopModule = onDeleteHelper(moduleId, [initialTopModule])[0];
        onUpdate(newTopModule);
    };

    if (!editModule) {
        return null;
    }

    return (
        <>
            <Grid container rowGap={1}>
                <Grid item xs={12} p={1}>
                    <Typography variant="h5">
                        {editModule.displayName} - ({editModule.id})
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Button
                        sx={{ m: 1 }}
                        variant="contained"
                        size="small"
                        onClick={() => setDataSpecOpen(true)}
                    >
                        Edit Data Specifications
                    </Button>
                    {editModule.uiFulfillment?.fulfillmentType === "table" && (
                        <Button
                            sx={{ m: 1 }}
                            variant="contained"
                            size="small"
                            onClick={() => setFulfillmentOpen(true)}
                        >
                            Edit Table Fulfillment Properties
                        </Button>
                    )}
                </Grid>
                <Grid item xs={12}>
                    <Accordion>
                        <AccordionSummary
                            aria-controls="variables-content"
                            id="variables-header"
                        >
                            Variables
                        </AccordionSummary>
                        <AccordionDetails>
                            <VariablesEditor
                                initialVariables={editModule.variableDefinitions}
                                fullSchema={fullSchema}
                                onUpdate={(vars) => {
                                    let newMod: Module = JSON.parse(JSON.stringify(editModule));
                                    newMod.variableDefinitions = vars;
                                    updateModule(newMod);
                                }}
                            />
                        </AccordionDetails>
                    </Accordion>
                </Grid>
                {(editModule.uiFulfillment && editModule.uiFulfillment.fulfillmentType === "graph") && (
                    <>
                        <Grid item xs={12}>
                            <Accordion>
                                <AccordionSummary
                                    aria-controls="nodes-content"
                                    id="nodes-header"
                                >
                                    Nodes
                                </AccordionSummary>
                                <AccordionDetails>
                                    <NodesEditor
                                        nodes={editModule.uiFulfillment.nodes || []}
                                        fullSchema={fullSchema}
                                        onUpdate={(nodes) => {
                                            let newMod: Module = JSON.parse(JSON.stringify(editModule));
                                            if (newMod.uiFulfillment && newMod.uiFulfillment.fulfillmentType === "graph") {
                                                newMod.uiFulfillment.nodes = nodes;
                                                updateModule(newMod);
                                            }
                                        }}
                                    />
                                </AccordionDetails>
                            </Accordion>
                        </Grid>
                        <Grid item xs={12}>
                            <Accordion>
                                <AccordionSummary
                                    aria-controls="edges-content"
                                    id="edges-header"
                                >
                                    Edges
                                </AccordionSummary>
                                <AccordionDetails>
                                    <EdgesEditor
                                        edges={editModule.uiFulfillment.edges || []}
                                        fullSchema={fullSchema}
                                        onUpdate={(edges) => {
                                            let newMod: Module = JSON.parse(JSON.stringify(editModule));
                                            if (newMod.uiFulfillment && newMod.uiFulfillment.fulfillmentType === "graph") {
                                                newMod.uiFulfillment.edges = edges;
                                                updateModule(newMod);
                                            }
                                        }}
                                    />
                                </AccordionDetails>
                            </Accordion>
                        </Grid>
                    </>
                )}
                {(editModule.uiFulfillment && editModule.uiFulfillment.fulfillmentType === "table") && (
                    <SimpleModalWrapper
                        headerText="Table fulfillment"
                        open={fulfillmentOpen}
                        handleClose={() => setFulfillmentOpen(false)}
                        maxWidth='md'
                    >
                        <Box
                            sx={{
                                width: '100%',
                                paddingTop: '3%',
                                alignContent: "center"
                            }}
                        >
                            <Form
                                formData={editModule.uiFulfillment}
                                onSubmit={(data, event) => {
                                    let newMod: Module = JSON.parse(JSON.stringify(editModule));
                                    newMod.uiFulfillment = data.formData;
                                    setFulfillmentOpen(false);
                                    updateModule(newMod);
                                }}
                                uiSchema={{
                                    commentary: {
                                        'ui:widget': 'CustomCommentaryWidget'
                                    }
                                }}
                                widgets={ {
                                    CustomCommentaryWidget
                                }}
                                schema={({
                                    $ref: "#/$defs/tableFulfillment",
                                    $defs: fullSchema.$defs
                                } as RJSFSchema)}
                                validator={validator}
                            />
                        </Box>
                    </SimpleModalWrapper>
                )}
                <SimpleModalWrapper
                    headerText="Data Specifications"
                    open={dataSpecOpen}
                    handleClose={() => setDataSpecOpen(false)}
                    maxWidth='md'
                >
                    <Box
                        sx={{
                            width: '100%',
                            paddingTop: '3%',
                            alignContent: "center"
                        }}
                    >
                        <Form
                            formData={editModule.dataSpec}
                            onSubmit={(data, event) => {
                                let newMod: Module = JSON.parse(JSON.stringify(editModule));
                                newMod.dataSpec = data.formData;
                                setDataSpecOpen(false);
                                updateModule(newMod);
                            }}
                            schema={({
                                anyOf: [
                                    {
                                        "$ref": "#/$defs/basicDataSpec"
                                    },
                                    {
                                        "$ref": "#/$defs/basicTableDataSpec"
                                    },
                                    {
                                        "$ref": "#/$defs/mappingQuestionDataSpec"
                                    },
                                    {
                                        "$ref": "#/$defs/tableWithSingleAnalysisDataSpec"
                                    }
                                ],
                                $defs: fullSchema.$defs
                            } as RJSFSchema)}
                            validator={validator}
                        />
                    </Box>
                </SimpleModalWrapper>
            </Grid>
        </>
    );
}


