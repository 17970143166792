import React, { useState, memo, useEffect } from 'react';
import { ListItemText, MenuItem, IconButton, ListItemIcon, Switch } from '@mui/material';
import { FilterList } from '@mui/icons-material';
import { useResearchPanelData } from '../../../../containers/ResearchPanelData/ResearchPanelData';
import StyledMenu from '../../../menu';
import { useDebounce } from '@uidotdev/usehooks';

const CustomFlowFilter = () => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const {
        applyLiteratureFilter,
        setApplyLiteratureFilter
    } = useResearchPanelData();

    const [localApplyLiteratureFilter, setLocalApplyLiteratureFilter] = useState(applyLiteratureFilter);
    const debouncedLocalApplyLiteratureFilter = useDebounce(localApplyLiteratureFilter, 250);

    useEffect(() => {
        setApplyLiteratureFilter(debouncedLocalApplyLiteratureFilter);
    }, [debouncedLocalApplyLiteratureFilter]);

    return (
        <>
            <IconButton
                data-testid="custom-flow-filter-button"
                size="small"
                onClick={(event: React.MouseEvent<HTMLElement>) => {
                    setAnchorEl(event.currentTarget);
                }}
            >
                <FilterList />
            </IconButton>
            <StyledMenu
                data-testid="custom-flow-filter-menu"
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={() => setAnchorEl(null)}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                slotProps={{
                    paper: {
                        style: {
                            maxHeight: "350px",
                            width: "35ch",
                        },
                    },
                }}
            >
                <MenuItem>
                    <ListItemIcon>
                        <Switch
                            checked={localApplyLiteratureFilter}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                setLocalApplyLiteratureFilter(event.target.checked);
                            }}
                        />
                    </ListItemIcon>
                    <ListItemText
                        primary="Apply Literature Filter"
                        primaryTypographyProps={{
                            noWrap: true
                        }}
                    />
                </MenuItem>
            </StyledMenu>
        </>
    );
}

export default memo(CustomFlowFilter);