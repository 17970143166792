import { createApi } from '@reduxjs/toolkit/query/react';
import { OrganizationMembership, User, UserAnalysis, UserOrganizationMembershipPayload } from "../models/dataModelTypes";
import { axiosBaseQuery } from '../reduxUtils/baseQuery';
import { PaginatedResponse } from '../models/commonTypes';

export const userApi = createApi({
    reducerPath: 'userApi',
    baseQuery: axiosBaseQuery(),
    tagTypes: ['Users', 'OrganizationUsers', 'UserAnalyses'],
    endpoints: (build) => ({
        listUsers: build.query<PaginatedResponse<User>, { page: number | void, limit: number | void }>({
            query: ({page = 1, limit = 10}) => ({
                url: `users?page=${page}&limit=${limit}`,
                method: "GET"
            }),            
            providesTags: (result) =>
                result
                ?
                    [
                        ...result.data.map(({ id }) => ({ type: 'Users', id } as const)),
                        { type: 'Users', id: 'LIST' },
                    ]
                :
                    [{ type: 'Users', id: 'LIST' }],
        }),
        addUser: build.mutation<User, Partial<User>>({
            query(data) {
                const { uid, email, roles, firstName, lastName } = data;
                return {
                    url: `users`,
                    method: 'POST',
                    data: {
                        uid,
                        roles,
                        email,
                        first_name: firstName,
                        last_name: lastName
                    }
                }
            },
            invalidatesTags: [{ type: 'Users', id: 'LIST' }],
        }),
        getUser: build.query<User, string>({
            query: (id) => ({
                url: `users/${id}`,
                method: "GET"
            }),
            providesTags: (result, error, id) => [{ type: 'Users', id }],
        }),
        updateUser: build.mutation<User, Partial<User>>({
            query(data) {
                const { id, roles, firstName, lastName } = data;
                return {
                    url: `users/${id}`,
                    method: 'PUT',
                    data: {
                        roles, 
                        first_name: firstName,
                        last_name: lastName
                    }                    
                }
            },
            invalidatesTags: (result, error, { id }) => [{ type: 'Users', id }],
        }),
        deleteUser: build.mutation<boolean, string>({
            query(id) {
                return {
                    url: `users/${id}`,
                    method: 'DELETE',
                }
            },
            invalidatesTags: (result, error, id) => [{ type: 'Users', id }],
        }),
        addUserOrganizationMembership: build.mutation<OrganizationMembership, Partial<UserOrganizationMembershipPayload>>({
            query(data) {
                const { userId, organizationId } = data
                return {
                    url: `users/${userId}/organization_memberships`,
                    method: 'POST',
                    data: {
                        organization_id: organizationId
                    },
                }
            },
            invalidatesTags: (result, error, { userId }) => [
                { type: 'Users', id: userId }, 
                { type: 'Users', id: 'LIST' }
            ],
        }),
        deleteUserOrganizationMembership: build.mutation<OrganizationMembership, Partial<OrganizationMembership>>({
            query(data) {
                const { id, userId } = data;
                return {
                    url: `users/${userId}/organization_memberships/${id}`,
                    method: 'DELETE'
                }
            },
            invalidatesTags: (result, error, { userId }) => [
                { type: 'Users', id: userId }, 
                { type: 'Users', id: 'LIST' }
            ],
        }),
        listUsersByOrg: build.query<PaginatedResponse<OrganizationMembership>, { page: number | void, limit: number | void, organizationId: string }>({
            query: ({page = 1, limit = 10, organizationId}) => ({
                url: `organizations/${organizationId}/users?page=${page}&limit=${limit}`,
                method: "GET"
            }),            
            providesTags: (result) =>
                result
                ?
                    [
                        ...result.data.map(({ id }) => ({ type: 'OrganizationUsers', id } as const)),
                        { type: 'OrganizationUsers', id: 'LIST' },
                    ]
                :
                    [{ type: 'OrganizationUsers', id: 'LIST' }],
        }),
        listUserAnalyses: build.query<PaginatedResponse<UserAnalysis>, { page: number | void, limit: number | void, organizationId: string }>({
            query: ({page = 1, limit = 10, organizationId}) => ({
                url: `user_analyses?organization_id=${organizationId}&page=${page}&limit=${limit}`,
                method: "GET"
            }),            
            providesTags: (result) =>
                result
                ?
                    [
                        ...result.data.map(({ id }) => ({ type: 'UserAnalyses', id } as const)),
                        { type: 'UserAnalyses', id: 'LIST' },
                    ]
                :
                    [{ type: 'UserAnalyses', id: 'LIST' }],
        }),
        addAdvisorGeneration: build.mutation<UserAnalysis, Partial<{ organizationId: string, transformType: string, customQuery: string, guidanceFilter: string[] }>>({
            query(data) {
                const { 
                    organizationId,
                    transformType,
                    customQuery,
                    guidanceFilter
                } = data;
                return {
                    url: `create_advisor_generation`,
                    method: 'POST',
                    data: {
                        organization_id: organizationId,
                        configuration: {
                            transform_type: transformType,
                            custom_query: customQuery,
                            guidance_filter: guidanceFilter
                        }
                    }
                }
            },
            invalidatesTags: (result, error, { }) => [
                { type: 'UserAnalyses', id: 'LIST' }
            ],
        }),
        getUserAnalysisPayload: build.query<any, string>({
            query: (id) => ({
                url: `user_analyses/${id}/payload`,
                method: "GET"
            })
        }),
    }),
})

export const {
    useListUsersQuery,
    useAddUserMutation,
    useGetUserQuery,
    useUpdateUserMutation,
    useDeleteUserMutation,
    useAddUserOrganizationMembershipMutation,
    useDeleteUserOrganizationMembershipMutation,
    useListUsersByOrgQuery,
    useAddAdvisorGenerationMutation,
    useGetUserAnalysisPayloadQuery
} = userApi;