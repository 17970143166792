import React, { useEffect, useState } from "react";
import { SimpleModalWrapper } from "../../components/dialog/wrappers/simpleModalWrapper";
import { Assistant, Organization } from "../../redux/models/dataModelTypes";
import FullConfig, { ConfigEntry } from "../../components/form/EntryBuilder";
import axios from "axios";

interface Props {
    organization: Organization;
    open: boolean;
    onAdd: () => void;
    onClose: () => void;
}

export const ManageOrganizationModelIntegrations = ({
    organization,
    open,
    onAdd,
    onClose
}: Props) => {
    const [assistants, setAssistants] = useState<Array<Assistant>>([]);
    const [assistantAssignments, setAssistantAssignments] = useState<Array<any>>([]);

    const fetchAssistantAssignments = () => {
        axios.get(
            `${window.__RUNTIME_CONFIG__.API_ENDPOINT}/organizations/${organization.id}/model_integrations?page=0&limit=1000`,
            { withCredentials: true }
        ).then((data) => {
            let res = data.data;
            setAssistantAssignments(res.data);
        }).catch((e) => {
            console.error(e);
        });
    };

    const deleteAssistantAssignment = async (id: string) => {
        const res = await axios.delete(
            `${window.__RUNTIME_CONFIG__.API_ENDPOINT}/organizations/${organization.id}/model_integrations/${id}`,
            { withCredentials: true }
        );
        setAssistantAssignments(assistantAssignments.filter((aa) => aa.id !== id));
    }

    const createAssistantAssignment = async (assistantId: string) => {
        const res = await axios.post(
            `${window.__RUNTIME_CONFIG__.API_ENDPOINT}/organizations/${organization.id}/model_integrations`,
            {
                assistant_id: assistantId
            },
            { withCredentials: true }
        );
        fetchAssistantAssignments();
    };

    useEffect(() => {
        fetchAssistantAssignments();
    }, []);

    useEffect(() => {
        axios.get(
            `${window.__RUNTIME_CONFIG__.API_ENDPOINT}/assistants?page=0&limit=1000`,
            { withCredentials: true }
        ).then((data) => {
            let res = data.data;
            setAssistants(res.data);
        }).catch((e) => {
            console.error(e);
        });
    }, []);

    return (
        <SimpleModalWrapper
            headerText="Manage Guidance"
            open={open}
            handleClose={onClose}
            maxWidth='md'
        >
            <FullConfig
                options={(assistants || []).map((a) => ({
                    id: a.id,
                    display: a.name
                }))}
                additionalFields={[]}
                entries={assistantAssignments.map((aa) => ({
                    id: aa.id,
                    key: aa.assistant.id
                } as ConfigEntry))}
                onDeleteEntry={(ce: ConfigEntry) => {
                    deleteAssistantAssignment(ce.id);
                }}
                keyOptionsGetter={(k: string) => undefined}
                onCreate={(d: any) => {
                    createAssistantAssignment(d.key);
                    onAdd();
                }}
                disableData
            />
        </SimpleModalWrapper>
    );
};

