import React from 'react';
import { addClassNamesToElement, removeClassNamesFromElement } from '@lexical/utils';
import {
    $applyNodeReplacement,
    EditorConfig,
    ElementNode,
    LexicalNode,
    NodeKey,
    SerializedElementNode,
    Spread,
} from 'lexical';

import "./index.css";

export type SerializedInnerReportSectionNode = Spread<{}, SerializedElementNode>;

export class InnerReportSectionNode extends ElementNode {
    /** @internal */
    __outOfSync?: boolean;
    __overriden?: boolean;
    __hasComment?: boolean;
    
    static override getType(): string {
        return 'inner-report-section';
    }

    static override clone(node: InnerReportSectionNode): InnerReportSectionNode {
        return new InnerReportSectionNode(node.__key);
    }

    static override importJSON(serializedNode: SerializedInnerReportSectionNode): InnerReportSectionNode {
        const node = $createInnerReportSectionNode();
        return node;
    }

    override exportJSON(): SerializedInnerReportSectionNode {
        return {
            ...super.exportJSON(),
            type: 'inner-report-section',
            version: 1,
        };
    }

    createDOM(config: EditorConfig): HTMLElement {
        const element = document.createElement('span');
        if (config.namespace !== "edit-report-section") {
            if (this.__outOfSync) {
                addClassNamesToElement(element, "Theme__reportSectionNeedsAttention");
            } else if (this.__overriden) {
                addClassNamesToElement(element, "Theme__reportSectionOverridden");
            } else {
                addClassNamesToElement(element, "Theme__reportSection");
            }

            if(this.__hasComment) {
                addClassNamesToElement(element, "Theme__reportSectionHasComment");
            }
        }
        return element;
    }

    updateDOM(
        prevNode: InnerReportSectionNode,
        element: HTMLElement,
        config: EditorConfig,
    ): boolean {
        if (config.namespace !== "edit-report-section") {
            removeClassNamesFromElement(element, "Theme__reportSectionNeedsAttention");
            removeClassNamesFromElement(element, "Theme__reportSectionOverridden");
            removeClassNamesFromElement(element, "Theme__reportSection");
            removeClassNamesFromElement(element, "Theme__reportSectionHasComment");

            if (this.__outOfSync) {
                addClassNamesToElement(element, "Theme__reportSectionNeedsAttention");
            } else if (this.__overriden) {
                addClassNamesToElement(element, "Theme__reportSectionOverridden");
            } else {
                addClassNamesToElement(element, "Theme__reportSection");
            }

            if(this.__hasComment) {
                addClassNamesToElement(element, "Theme__reportSectionHasComment");
            }
        }
        return false;
    }
}

export function $createInnerReportSectionNode(): InnerReportSectionNode {
    return $applyNodeReplacement(new InnerReportSectionNode());
}

export function $isInnerReportSectionNode(node: LexicalNode | null | undefined,): node is InnerReportSectionNode {
    return node instanceof InnerReportSectionNode;
}
