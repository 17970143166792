import { Box, Fade, IconButton, InputBase, Paper } from '@mui/material';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { autocompleteClasses } from '@mui/material/Autocomplete';
import Popper from '@mui/material/Popper';
import { styled } from '@mui/material/styles';
import { useResearchPanelData } from '../../../../containers/ResearchPanelData/ResearchPanelData';
import { Search } from '@mui/icons-material';
import { GuidanceChunk } from '../../../../redux/models/dataModelTypes';
import { useUserScopedAppData } from '../../../../containers/UserScopedAppData/UserScopedAppData';
import GuidanceSearchResults from './GuidanceSearchResults';
import { useGuidanceData } from '../../../../containers/GuidanceData';

const StyledPopper = styled(Popper)({
    [`& .${autocompleteClasses.listbox}`]: {
        boxSizing: 'border-box',
        '& ul': {
            padding: 0,
            margin: 0,
        },
    },
});

interface GuidanceSearchProps {
}

const GuidanceSearch = ({

}: GuidanceSearchProps) => {
    const {
        optionalBuilderDocumentId,
        guidanceSearchInput,
        setSelectedGuidanceId,
        setSelectedGuidanceItemId,
        activeGuidanceIds
    } = useResearchPanelData();
    const {
        guidanceReferences,
        getContentFromGuidance
    } = useGuidanceData();
    const { selectedOrgId } = useUserScopedAppData();

    const [inputValue, setInputValue] = useState<string>(guidanceSearchInput);
    const [searchedInputValue, setSearchedInputValue] = useState<string>('');

    const [guidanceToSelect, setGuidanceToSelect] = useState<GuidanceChunk | undefined>(undefined);

    useEffect(() => {
        if (!guidanceSearchInput || guidanceSearchInput === inputValue) {
            return;
        }

        setInputValue(guidanceSearchInput);
        setSearchedInputValue(guidanceSearchInput);
    }, [guidanceSearchInput]);

    const [isSearchOpen, setIsSearchOpen] = useState<boolean>(false);
    const [isSearchResultDirty, setIsSearchResultDirty] = useState<boolean>(false);
    const [loadingSearchSelection, setLoadingSearchSelection] = useState<boolean>(false);

    const searchBoxRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (inputValue === '') {
            setIsSearchOpen(false);
        } else {
            setIsSearchOpen(true);
        }
    }, [inputValue]);

    useEffect(() => {
        setIsSearchResultDirty(inputValue !== searchedInputValue);
    }, [inputValue, searchedInputValue]);

    useEffect(() => {
        if (!guidanceToSelect) {
            return;
        }

        (async () => {
            setLoadingSearchSelection(true);
            setSelectedGuidanceId(guidanceToSelect.guidanceId);

            // this will wait for the guide to load, then we should select it
            // makes for a better UX
            await getContentFromGuidance(guidanceToSelect.guidanceId, guidanceToSelect.contentId);

            setSelectedGuidanceItemId(guidanceToSelect.contentId);
            const headerElem = document.getElementById(guidanceToSelect.contentId);
            if (headerElem) {
                headerElem.scrollIntoView({
                    block: "center",
                    inline: "center",
                    behavior: "auto"
                });
            }

            setGuidanceToSelect(undefined);
            setLoadingSearchSelection(false);
        })();
    }, [
        guidanceToSelect
    ]);

    const guidanceFilter = useMemo(() => {
        const filter: Array<[string, string]> = [];
        for (const refs of guidanceReferences) {
            if (refs.indexedGuidance && activeGuidanceIds.includes(refs.indexedGuidance.guidanceId)) {
                filter.push([
                    refs.indexedGuidance.guidanceId, 
                    refs.indexedGuidanceVersion
                ]);
            }
        }
        return filter;
    }, [guidanceReferences, activeGuidanceIds]);

    return (
        <>
            <Box
                ref={searchBoxRef}
                display="flex"
                alignItems="center"
                margin={1}
                borderBottom="1px solid #ccc"
            >
                <InputBase
                    value={inputValue}
                    onChange={(e) => setInputValue(e.target.value)}
                    onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                            e.preventDefault();
                            setSearchedInputValue(inputValue);
                            setIsSearchOpen(true);
                        }
                    }}
                    fullWidth
                    disabled={loadingSearchSelection}
                    placeholder="Search"
                    onFocus={() => setIsSearchOpen(true)}
                    onBlur={() => setIsSearchOpen(false)}
                />
                <IconButton
                    aria-label="search"
                    size={"small"}
                    color={isSearchResultDirty ? "primary" : "default"}
                    onClick={() => {
                        setSearchedInputValue(inputValue);
                        setIsSearchOpen(true);
                    }}
                >
                    <Search />
                </IconButton>
            </Box>
            <StyledPopper
                sx={{ zIndex: 10002 }}
                open={isSearchOpen}
                anchorEl={searchBoxRef.current}
                placement="bottom"
                transition
                keepMounted
                onBlur={() => setIsSearchOpen(false)}
            >
                {({ TransitionProps }) => (
                    <Fade {...TransitionProps} timeout={350}>
                        <Paper
                            sx={{
                                maxHeight: '50vh',
                                width: '30vw',
                                overflow: 'auto',
                            }}
                        >
                            <GuidanceSearchResults
                                key={`${selectedOrgId}-${searchedInputValue}`}
                                guidanceFilter={guidanceFilter}
                                organizationId={selectedOrgId}
                                builderDocumentId={optionalBuilderDocumentId}
                                searchedValue={searchedInputValue}
                                isSearchResultDirty={isSearchResultDirty}
                                onSelectGuidanceChunk={(result: GuidanceChunk) => {
                                    setIsSearchOpen(false);
                                    setGuidanceToSelect(result);
                                }}
                            />
                        </Paper>
                    </Fade>
                )}
            </StyledPopper>
        </>
    );
};

export default GuidanceSearch;