import { v4 as uuidv4 } from 'uuid';

const _doForMatchingEntry = (
    thisEntryId: string, 
    indexEntries: any[],
    mutator: (indexEntry: any) => void
) => {
    for (const indexEntry of indexEntries) {
        if (indexEntry['id'] === thisEntryId) {
            mutator(indexEntry);
            break;
        } else {
            _doForMatchingEntry(thisEntryId, indexEntry['nested'], mutator);
        }
    }
};

class LabeledRefsHolder {
    labeledRefs: any[];
    constructor(_labeledRefs: any[]) {
        this.labeledRefs = JSON.parse(JSON.stringify(_labeledRefs));
    }

    moveHeaderUp(thisEntryId: string) {
        if (thisEntryId !== "" || thisEntryId !== undefined) {
            const updateLabeledRef = (indexEntries: any[]) => {
                let entries = [];
                let toSwap: undefined | number = undefined;
                for (let i = 0; i < indexEntries.length; i++) {
                    let indexEntry = indexEntries[i];
                    indexEntry['nested'] = updateLabeledRef(indexEntry['nested']);
                    entries.push(indexEntry);
                    if (indexEntry['id'] === thisEntryId) {
                        toSwap = i;
                    }
                }

                if (toSwap !== undefined) {
                    const temp = entries[toSwap];
                    entries[toSwap] = entries[toSwap - 1];
                    entries[toSwap - 1] = temp;
                }
                return entries;
            };
            this.labeledRefs = updateLabeledRef(this.labeledRefs);    
        }
    };

    moveHeaderDown(thisEntryId: string) {
        if (thisEntryId !== "" || thisEntryId !== undefined) {
            const updateLabeledRef = (indexEntries: any[]) => {
                let entries = [];
                let toSwap: undefined | number = undefined;
                for (let i = 0; i < indexEntries.length; i++) {
                    let indexEntry = indexEntries[i];
                    indexEntry['nested'] = updateLabeledRef(indexEntry['nested']);
                    entries.push(indexEntry);
                    if (indexEntry['id'] === thisEntryId) {
                        toSwap = i;
                    }
                }

                if (toSwap !== undefined) {
                    const temp = entries[toSwap];
                    entries[toSwap] = entries[toSwap + 1];
                    entries[toSwap + 1] = temp;
                }
                return entries;
            };
            this.labeledRefs = updateLabeledRef(this.labeledRefs);
        }
    };

    moveIntoParentHeader(thisEntryId: string) {
        if (thisEntryId !== "" || thisEntryId !== undefined) {
            const updateLabeledRef = (indexEntries: any[]) => {
                let entries = [];
                let removedEntries: any[] = [];
                for (let i in indexEntries) {
                    let indexEntry = indexEntries[i];
                    if (indexEntry['id'] === thisEntryId) {
                        removedEntries.push(indexEntry);
                    } else {
                        let res = updateLabeledRef(indexEntry['nested']);
                        indexEntry['nested'] = res.entries;

                        // Add original followed by any that were removed in the nested entries
                        entries.push(indexEntry);
                        if (res.removedEntries.length > 0) {
                            res.removedEntries.forEach((re) => entries.push(re));
                        }
                    }
                }
                return {
                    entries,
                    removedEntries
                };
            };

            let res = updateLabeledRef(this.labeledRefs);
            this.labeledRefs = res.entries;
        }
    };

    moveUnderSiblingHeader(thisEntryId: string, sibilingHeaderEntryId: string) {
        if (thisEntryId !== "" && sibilingHeaderEntryId !== undefined) {
            const removedEntries: any[] = [];
            const updateLabeledRef = (indexEntries: any[]) => {
                let entries = [];
                for (let i in indexEntries) {
                    let indexEntry = indexEntries[i];
                    if (indexEntry['id'] === thisEntryId) {
                        removedEntries.push(indexEntry);
                    } else {
                        indexEntry['nested'] = updateLabeledRef(indexEntry['nested']);
                        entries.push(indexEntry);
                    }
                }
                return entries;
            };

            const insertLabeledRef = (newEntry: any, indexEntries: any[]) => {
                let entries = [];
                for (let i in indexEntries) {
                    let indexEntry = indexEntries[i];
                    indexEntry['nested'] = insertLabeledRef(newEntry, indexEntry['nested']);

                    // insert as last child
                    if (indexEntry['id'] === sibilingHeaderEntryId) {
                        indexEntry['nested'].push(newEntry);
                    }
                    entries.push(indexEntry);

                }
                return entries;
            };

            let newLabeledRefs = updateLabeledRef(this.labeledRefs);
            if (removedEntries.length === 1) {
                const removedEntry = removedEntries[0];
                this.labeledRefs = insertLabeledRef(removedEntry, newLabeledRefs);
            }
        }
    };

    updateHeader(thisEntryId: string, label: string) {
        let mutatedEntry = undefined;
        if (thisEntryId !== "" || thisEntryId !== undefined) {
            _doForMatchingEntry(thisEntryId, this.labeledRefs, (indexEntry: any) => {
                indexEntry['nested'].push({
                    id: uuidv4(),
                    label: label,
                    nested: [],
                    hasContent: false,
                    label_tag_ids: [],
                    content_tag_ids: [],
                    alt_ids: []
                });
                mutatedEntry = indexEntry;
            });
        } else {
            mutatedEntry = {
                id: uuidv4(),
                label: label,
                nested: [],
                hasContent: false,
                label_tag_ids: [],
                content_tag_ids: [],
                alt_ids: [],
            }
            this.labeledRefs.push();
        }
        return mutatedEntry;
    };

    markAsReviewed(thisEntryId: string, isReviewed: boolean) {
        let mutatedEntry = undefined;
        if (thisEntryId !== "" || thisEntryId !== undefined) {
            _doForMatchingEntry(thisEntryId, this.labeledRefs, (indexEntry: any) => {
                indexEntry['reviewed'] = isReviewed;
                mutatedEntry = indexEntry;
            });
        }
        return mutatedEntry;
    };

    addContentTagIds(thisEntryId: string, contentTagIds: string[]) {
        let mutatedEntry = undefined;
        if (thisEntryId !== "" || thisEntryId !== undefined) {
            _doForMatchingEntry(thisEntryId, this.labeledRefs, (indexEntry: any) => {
                //TODO: add a failsafe to filter for duplicate content IDs
                indexEntry['content_tag_ids'] = [...(indexEntry['content_tag_ids'] ?? []), ...contentTagIds];
                mutatedEntry = indexEntry;
            });
        }
        return mutatedEntry;
    };

    addHeaderTagIds(thisEntryId: string, labelTagIds: string[]) {
        let mutatedEntry = undefined;
        if (thisEntryId !== "" || thisEntryId !== undefined) {
            _doForMatchingEntry(thisEntryId, this.labeledRefs, (indexEntry: any) => {
                //TODO: add a failsafe to filter for duplicate content IDs
                indexEntry['label_tag_ids'] = [...(indexEntry['label_tag_ids'] ?? []), ...labelTagIds];
            });
        }
        return mutatedEntry;
    };

    removeHeaderTagId(thisEntryId: string, tagId: string) {
        let mutatedEntry = undefined;
        if (thisEntryId !== "" || thisEntryId !== undefined) {
            _doForMatchingEntry(thisEntryId, this.labeledRefs, (indexEntry: any) => {
                indexEntry['label_tag_ids'] = indexEntry['label_tag_ids'].filter((id: string) => id !== tagId);
                mutatedEntry = indexEntry;
            });
        }
        return mutatedEntry;

    };

    removeContentTagId(thisEntryId: string, tagId: string) {
        let mutatedEntry = undefined;
        if (thisEntryId !== "" || thisEntryId !== undefined) {
            _doForMatchingEntry(thisEntryId, this.labeledRefs, (indexEntry: any) => {
                indexEntry['content_tag_ids'] = indexEntry['content_tag_ids'].filter((id: string) => id !== tagId);
                mutatedEntry = indexEntry;
            });
        }
        return mutatedEntry;

    };

    updateAliases(thisEntryId: string, altIds: string[]) {
        let mutatedEntry = undefined;
        if (thisEntryId !== "" || thisEntryId !== undefined) {
            _doForMatchingEntry(thisEntryId, this.labeledRefs, (indexEntry: any) => {
                indexEntry['alt_ids'] = altIds;
                mutatedEntry = indexEntry;
            });
        }
        return mutatedEntry;
    };

    updateHeaderInPlace(thisEntryId: string, label: string) {
        let mutatedEntry = undefined;
        if (thisEntryId !== "" || thisEntryId !== undefined) {
            _doForMatchingEntry(thisEntryId, this.labeledRefs, (indexEntry: any) => {
                indexEntry['label'] = label;
                mutatedEntry = indexEntry;
            });
        }
        return mutatedEntry;
    };
}

export default LabeledRefsHolder;