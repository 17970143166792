import React, { Suspense, useState } from 'react';
import { Box, Typography, useTheme } from "@mui/material";
import { APP_BAR_HEIGHT } from '../../navigation/SideNav';
import { useResearchPanelData } from '../../../containers/ResearchPanelData/ResearchPanelData';
import GuidanceOutline from './GuidanceOutline';
import RectangularSkeleton from '../../loading/RectangularSkeleton';
import GuidanceToolbar from './GuidanceToolbar';

const GuidancePdfViewLazy = React.lazy(() => import('./GuidancePdfView'));
const GuidanceLexicalViewLazy = React.lazy(() => import('./GuidanceLexicalView'));

const GuidanceMain = () => {
    const theme = useTheme();
    const { 
        targetDisplayMode,
        guidanceOutlineOpen
    } = useResearchPanelData();

    const [zoomLevel, setZoomLevel] = useState<number>(0.97);

    const drawerContainerStyle: React.CSSProperties = {
        height: `calc(100vh - ${2 * APP_BAR_HEIGHT}px)`,
        width: '100%',
        overflow: 'hidden',
        
    }

    const otherChildrenStyle: React.CSSProperties = {
        marginTop: `${3 * APP_BAR_HEIGHT}px`,
        height: `calc(100vh - ${3 * APP_BAR_HEIGHT}px)`,
        position: 'absolute',
        top: 0,
        left: 0,
        zIndex: 1,
        borderLeft: `1px solid ${theme.palette.divider}`,
    };

    if (guidanceOutlineOpen) {
        otherChildrenStyle.width = `calc(50vw - 200px)`;
        otherChildrenStyle.left = '200px';
    } else {
        otherChildrenStyle.width = '50vw';
    }

    return (
        <>
            <GuidanceToolbar 
                zoomLevel={zoomLevel}
                setZoomLevel={setZoomLevel}
            />
            <Box sx={drawerContainerStyle}>
                <GuidanceOutline 
                    topBuffer={3}
                    leftPosition="50vw"
                />
                <Box style={otherChildrenStyle}>
                    {targetDisplayMode === "NONE" ? (
                        <Box
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                            height="100%"
                            width="100%"
                        >
                            <Typography variant="h6">Select Literature</Typography>
                        </Box>
                    ) : (
                        <Suspense fallback={<RectangularSkeleton />}>
                            {targetDisplayMode === "PDF" && (
                                <GuidancePdfViewLazy
                                    zoomLevel={zoomLevel} 
                                />
                            )}
                            {(targetDisplayMode === "LEXICAL") && (
                                <GuidanceLexicalViewLazy />
                            )}
                        </Suspense>
                    )}
                </Box>
            </Box>
        </>
    );
};

export default GuidanceMain;
