import React, { 
    useEffect, 
    useState, 
    memo,
} from "react";
import BoxHighlighterLayer from "./BoxHighlighter/BoxHighlighterLayer";
import EditablePagesWrapper from "./EditablePagesWrapper";
import { LexicalDocument } from "../../types/taker/documentkeyterms.generated";
import { PdfDocumentProvider } from "./PdfViewer/PdfDocumentProvider";
import { clearCanvasCache } from "./PdfViewer/PdfPage";
import { usePdfHighlighter } from "./context";

import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';
import './index.css';

interface PdfHighlighterOptions {
    readOnly: boolean;
    canvasCacheSize: number;
}

interface PdfHighlighterProps {
    documentWidth: number;
    lexicalDocuments: LexicalDocument[];
    pdfUrlGetter: (lexicalDocumentIdentifier: string) => string;
    pdfNameGetter: (lexicalDocumentIdentifier: string) => string;
    options?: PdfHighlighterOptions;
}

export const DEFAULT_PDF_HIGHLIGHTER_OPTIONS: PdfHighlighterOptions = {
    readOnly: true,
    canvasCacheSize: 10,
};

const PdfHighlighter = ({
    documentWidth,
    lexicalDocuments,
    pdfUrlGetter,
    pdfNameGetter,
    options = DEFAULT_PDF_HIGHLIGHTER_OPTIONS
}: PdfHighlighterProps) => {
    const { 
        boxHighlightMode,
        applyFiltersToDocuments,
        keyTermIdentifierFilters
    } = usePdfHighlighter();
    const [parentDiv, setParentDiv] = useState<HTMLDivElement | null>(null);

    useEffect(
        () => clearCanvasCache(),
        [lexicalDocuments.length]
    );

    let componentKey = `editable-pages-wrapper-${lexicalDocuments.length}`;
    if (applyFiltersToDocuments) {
        componentKey += "-apply-filters";
    }
    if (keyTermIdentifierFilters) {
        componentKey += `-key-terms-${keyTermIdentifierFilters.length}`;
    }

    return (
        <div
            ref={(r) => {
                if (!r || r === parentDiv) {
                    return;
                }
                setParentDiv(r);
            }}
            style={{
                width: `${documentWidth}px`,
                margin: "0 auto",
                height: "100%",
                overflowX: "auto",
            }}
            data-testid="pages-editor-div"
        >
            <BoxHighlighterLayer enabled={!!boxHighlightMode}>
                <PdfDocumentProvider>
                    {parentDiv && (
                        <EditablePagesWrapper
                            key={componentKey}
                            readOnly={options.readOnly}
                            lexicalDocuments={lexicalDocuments}
                            parentDiv={parentDiv}
                            documentWidth={documentWidth}
                            pdfUrlGetter={pdfUrlGetter}
                            pdfNameGetter={pdfNameGetter}
                        />
                    )}
                </PdfDocumentProvider>
            </BoxHighlighterLayer>
        </div>
    );
}

export default memo(PdfHighlighter);
