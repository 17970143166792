import React, { useEffect, useState } from "react";
import { SimpleModalWrapper } from "../../components/dialog/wrappers/simpleModalWrapper";
import { Autocomplete, Box, Button, FormControl, Grid, MenuItem, Select, SelectChangeEvent, Stack, Switch, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TableRow, TextField, Typography } from "@mui/material/";
import { OrganizationGuidance, Organization, GuidanceDisplayMode, IndexedGuidance } from "../../redux/models/dataModelTypes";
import { useAddOrganizationGuidanceMutation, useRemoveOrganizationGuidanceMutation } from "../../redux/services/organization";
import { useLazyGetIndexedGuidancePackageVersionsQuery, useSearchIndexedGuidanceByNameQuery } from "../../redux/services/indexedGuidance";
import { CrudButtonGroup } from "../../components/buttons/crudButtonGroup";
import { Delete } from "@mui/icons-material";

const DISPLAY_MODES: GuidanceDisplayMode[] = [
    "NONE",
    "PDF",
    "LEXICAL"
];

interface Props {
    organization: Organization;
    onAdd: () => void;
    open: boolean;
    onClose: () => void;
}

export const ManageOrganizationGuidance = ({
    organization,
    onAdd,
    open,
    onClose
}: Props) => {
    const [inputValue, setInputValue] = useState('');
    const [value, setValue] = useState<IndexedGuidance | null>(null);
    const [version, setVersion] = useState<string>('latest');

    const [displayMode, setDisplayMode] = useState<GuidanceDisplayMode>('NONE');
    const [canCite, setCanCite] = useState<boolean>(true);

    const [availableVersions, setAvalailableVersions] = useState<string[]>([]);
    const { data: indexedGuidanceOptions } = useSearchIndexedGuidanceByNameQuery({
        page: 0,
        limit: 100,
        name: inputValue
    });

    const [trigger] = useLazyGetIndexedGuidancePackageVersionsQuery();

    const [addOrganizationGuidance, addOrganizationGuidanceRes] = useAddOrganizationGuidanceMutation();
    const [removeOrganizationGuidance, removeOrganizationGuidanceRes] = useRemoveOrganizationGuidanceMutation();

    useEffect(() => {
        if (value) {
            trigger(value.id).then((res) => {
                if (res.isSuccess) {
                    setAvalailableVersions(res.data.map(
                        s => s.substring(0, s.lastIndexOf("."))
                    ));
                }
            }).catch((error) => {
                console.error(error);
            });
            setVersion("latest");
        }
    }, [value]);

    useEffect(() => {
        if (addOrganizationGuidanceRes.isSuccess) {
            onAdd();
        }
    }, [addOrganizationGuidanceRes]);

    return (
        <SimpleModalWrapper
            headerText="Manage Guidance"
            open={open}
            handleClose={onClose}
            maxWidth='md'
        >
            <Grid container>
                <Grid
                    xs={12}
                    item
                    paddingTop={1}
                    paddingBottom={2}
                >
                    <TableContainer>
                        <Table>
                            <TableHead>
                                <TableCell
                                    variant="head"
                                    sx={{ fontWeight: "bolder" }}
                                >
                                    Name
                                </TableCell>
                                <TableCell
                                    variant="head"
                                    sx={{ fontWeight: "bolder" }}
                                >
                                    Version
                                </TableCell>
                                <TableCell
                                    variant="head"
                                    sx={{ fontWeight: "bolder" }}
                                >
                                    Can Cite
                                </TableCell>
                                <TableCell
                                    variant="head"
                                    sx={{ fontWeight: "bolder" }}
                                >
                                    Display Mode
                                </TableCell>
                                <TableCell
                                    variant="head"
                                    sx={{ fontWeight: "bolder" }}
                                >
                                    Created At
                                </TableCell>
                                <TableCell />
                            </TableHead>
                            <TableBody>
                                {(organization.organizationGuidance.length > 0) ? organization.organizationGuidance.map((og: OrganizationGuidance) => (
                                    <TableRow
                                        key={og.id}
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    >
                                        <TableCell component="th" scope="row">
                                            {og.indexedGuidance?.name}
                                        </TableCell>
                                        <TableCell component="th" scope="row">
                                            {og.indexedGuidanceVersion}
                                        </TableCell>
                                        <TableCell component="th" scope="row">
                                            {og.canCite ? "Yes" : "No"}
                                        </TableCell>
                                        <TableCell component="th" scope="row">
                                            {og.displayMode}
                                        </TableCell>
                                        <TableCell style={{ width: 160 }} align="right">
                                            {new Date(og.createdAt).toLocaleString()}
                                        </TableCell>
                                        <TableCell style={{ width: 160 }} align="right">
                                            <CrudButtonGroup
                                                buttons={[{
                                                    icon: (<Delete fontSize="small" />),
                                                    handleClick: () => {
                                                        removeOrganizationGuidance({
                                                            organizationId: og.organizationId,
                                                            id: og.id
                                                        });
                                                    }
                                                }]}
                                            />
                                        </TableCell>
                                    </TableRow>
                                )) : (
                                    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                        <TableCell colSpan={3} align="left">
                                            <i>none</i>
                                        </TableCell>
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
                <Grid
                    item
                    xs={12}
                    paddingBottom={2}
                >
                    <Stack direction="row" spacing={2}>
                        <Autocomplete
                            size="small"
                            sx={{ minWidth: "250px" }}
                            getOptionLabel={(option) =>
                                typeof option === 'string' ? option : option.name
                            }
                            filterOptions={(x) => x}
                            options={indexedGuidanceOptions?.data || []}
                            autoComplete
                            includeInputInList
                            filterSelectedOptions
                            value={value}
                            noOptionsText="No Guidance"
                            onChange={(event: any, newValue: IndexedGuidance | null) => {
                                setValue(newValue);
                            }}
                            onInputChange={(event, newInputValue) => {
                                setInputValue(newInputValue);
                            }}
                            renderInput={(params) => (
                                <TextField {...params} label="Add guidance" fullWidth />
                            )}
                            renderOption={(props, option) => {
                                return (
                                    <li {...props}>
                                        <Grid container alignItems="center">
                                            <Grid item sx={{ width: 'calc(100% - 44px)', wordWrap: 'break-word' }}>
                                                <Box component="span">
                                                    {option.name}
                                                </Box>
                                                <Typography variant="body2" color="text.secondary">
                                                    Added {new Date(option.createdAt).toLocaleString()}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </li>
                                );
                            }}
                        />
                        {value && (
                            <>
                                <FormControl sx={{ minWidth: 120 }}>
                                    <Select
                                        size="small"
                                        data-testid="guidance-version-select"
                                        value={version}
                                        onChange={(event: SelectChangeEvent<string>) => {
                                            setVersion(event.target.value);
                                        }}
                                        label=""
                                    >
                                        {availableVersions.map((av) => (
                                            <MenuItem value={av}>
                                                {av}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                                <FormControl sx={{ minWidth: 120 }}>
                                    <Switch
                                        size="small"
                                        data-testid="guidance-can-cite-select"
                                        checked={canCite}
                                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                            setCanCite(event.target.checked);
                                        }}
                                    />
                                </FormControl>
                                <FormControl sx={{ minWidth: 120 }}>
                                    <Select
                                        size="small"
                                        data-testid="guidance-display-mode-select"
                                        value={displayMode}
                                        onChange={(event: SelectChangeEvent<GuidanceDisplayMode>) => {
                                            setDisplayMode(event.target.value as GuidanceDisplayMode);
                                        }}
                                        label="Display Mode"
                                    >
                                        {DISPLAY_MODES.map((dm) => (
                                            <MenuItem value={dm}>
                                                {dm}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </>
                        )}
                        <Button
                            variant="contained"
                            onClick={() => {
                                if (value) {
                                    addOrganizationGuidance({
                                        organizationId: organization.id,
                                        indexedGuidanceId: value.id,
                                        indexedGuidanceVersion: version,
                                        canCite: canCite,
                                        displayMode: displayMode
                                    });
                                }
                            }}
                        >
                            Add
                        </Button>
                    </Stack>
                </Grid>
            </Grid>
        </SimpleModalWrapper>
    );
};

