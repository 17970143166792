import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { styled } from '@mui/material/styles';
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion';
import MuiAccordionSummary, {
    AccordionSummaryProps,
    accordionSummaryClasses,
} from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import { ArrowDropDownCircle } from '@mui/icons-material';
import GuidanceOutlineItem from './GuidanceOutlineItem';
import { Box, Typography, useTheme, Toolbar, Autocomplete, TextField } from "@mui/material";
import { blue } from '@mui/material/colors';
import { APP_BAR_HEIGHT, useSideNav } from '../../navigation/SideNav';
import GuidanceOutlineFilter from './GuidanceOutlineFilter';
import { useResearchPanelData } from '../../../containers/ResearchPanelData/ResearchPanelData';
import { createPortal } from 'react-dom';
import { BookmarkGuidance } from '../../../containers/ResearchPanelData/common';

const drawerWidth = 200;

interface BookmarkOption {
    name: string;
    group: "Questions";
    bookmarkGuidance: BookmarkGuidance[];
}

const Accordion = styled((props: AccordionProps) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
    backgroundColor: '#FBFCFE',
    '&:not(:last-child)': {
        borderBottom: 0,
    },
    '&::before': {
        display: 'none',
    },
    '& .Mui-expanded': {
        backgroundColor: blue[50],
    }
}));

const AccordionSummary = styled((props: AccordionSummaryProps) => (
    <MuiAccordionSummary
        expandIcon={<ArrowDropDownCircle sx={{ transform: 'rotate(-90deg)', }} />}
        {...props}
    />
))(({ theme }) => ({
    flexDirection: 'row-reverse',
    [`& .${accordionSummaryClasses.expandIconWrapper}.${accordionSummaryClasses.expanded}`]: {
        transform: 'rotate(90deg)',
    },
    [`& .${accordionSummaryClasses.content}`]: {
        marginLeft: theme.spacing(1),
    },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: 0,
}));

interface GuidanceOutlineProps {
    topBuffer: number
    leftPosition: string
}

const GuidanceOutline = ({ 
    topBuffer,
    leftPosition 
} : GuidanceOutlineProps) => {
    const theme = useTheme();
    const { isOpen: sideNavOpen } = useSideNav();
    const {
        guidanceOutlineOpen,
        setGuidanceOutlineOpen,
        selectedGuidanceId,
        setSelectedGuidanceId,
        bdgToDisplay,
        questionBookmarks,
        selectedQuestionBookmark,
        setSelectedQuestionBookmark
    } = useResearchPanelData();

    let selectedBookmark: BookmarkOption | null = null;
    if (selectedQuestionBookmark !== undefined) {
        const b = questionBookmarks.find(b => b.label === selectedQuestionBookmark);
        if (b) {
            selectedBookmark = {
                name: b.label,
                group: "Questions",
                bookmarkGuidance: b.guidance
            };
        }
    }

    // close the guidance outline when we toggle the side nav
    const [lastSideNavOpen, setLastSideNavOpen] = useState(sideNavOpen);
    useEffect(() => {
        if (sideNavOpen !== lastSideNavOpen) {
            setGuidanceOutlineOpen(false);
            setLastSideNavOpen(sideNavOpen);
        }
    }, [sideNavOpen]);

    const bookmarkOptions: BookmarkOption[] = useMemo(() => {
        const options: BookmarkOption[] = [];
        for (const b of questionBookmarks) {
            options.push({
                name: b.label,
                group: "Questions",
                bookmarkGuidance: b.guidance
            });
        }
        return options;   
    }, [
        questionBookmarks
    ]);

    const contentIdsByGuidanceId = useMemo(() => {
        const map = new Map<string, string[]>();
        if (!selectedBookmark) {
            return map;
        }

        for (const g of selectedBookmark.bookmarkGuidance) {
            if (!map.has(g.guidanceId)) {
                map.set(g.guidanceId, []);
            }
            map.get(g.guidanceId)?.push(...g.guidanceContentIds);
        }
        return map;
    }, [selectedBookmark]);

    if (!guidanceOutlineOpen) {
        return null;
    }
    return createPortal(
        <Box style={{
            marginTop: `${topBuffer * APP_BAR_HEIGHT}px`,
            height: `calc(100vh - ${topBuffer * APP_BAR_HEIGHT}px)`,
            width: drawerWidth,
            overflowX: 'hidden',
            overflowY: 'hidden',
            borderRight: `1px solid ${theme.palette.divider}`,
            top: 0,
            left: leftPosition,
            position: 'absolute',
            zIndex: 10000, // make this appear in a drawer
            backgroundColor: '#FBFCFE',
            transition: theme.transitions.create('width', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
            }),
        }}>
            <Toolbar
                variant="dense"
                disableGutters
                sx={{
                    width: "100%",
                    height: "48px",
                    alignItems: "center",
                    justifyContent: "space-between",
                }}
            >
                <Box
                    display="flex"
                    alignItems="center"
                    maxHeight="48px"
                    flexGrow={1}
                    flexDirection="row"
                    paddingLeft={0.5}
                    paddingRight={0.5}
                >
                    <Autocomplete
                        size='small'
                        fullWidth
                        data-testid="bookmarks-filter"
                        options={bookmarkOptions}
                        getOptionLabel={option => option.name}
                        groupBy={option => option.group}
                        value={selectedBookmark}
                        onChange={(e, value) => {
                            if (value === null) {
                                setSelectedQuestionBookmark(undefined);
                            } else if (value.group === "Questions") {
                                setSelectedQuestionBookmark(value.name);
                            }
                        }}
                        filterSelectedOptions
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                placeholder="Bookmarks"
                                variant="standard"
                                data-testid="bookmarks-filter-input"
                            />
                        )}
                        componentsProps={{
                            popper: {
                                sx: {
                                    zIndex: 10001,
                                }
                            }
                        }}
                    />
                </Box>
                <GuidanceOutlineFilter />
            </Toolbar>
            <Box
                display="flex"
                flexDirection="column"
                height="calc(100% - 48px)"
                flexGrow={1}
                sx={{ overflowY: "auto" }}
            >
                {bdgToDisplay
                    .filter(bdg => bdg.indexedGuidance)
                    .filter(bdg => 
                        (selectedBookmark === null) || 
                        (bdg.indexedGuidance && contentIdsByGuidanceId.has(bdg.indexedGuidance.guidanceId))
                    )
                    .map((bdg) => {
                        if (!bdg.indexedGuidance) {
                            return null;
                        }

                        const thisGuidanceId = bdg.indexedGuidance.guidanceId;
                        const fullName = bdg.canCite ?
                            bdg.indexedGuidance.name :
                            `${bdg.indexedGuidance.name} (Non-Citation)`;

                        const contentIds = contentIdsByGuidanceId.get(bdg.indexedGuidance.guidanceId)
                        return (
                            <Accordion
                                expanded={selectedGuidanceId === thisGuidanceId}
                                onChange={() => {
                                    setSelectedGuidanceId(prev => prev === thisGuidanceId ? undefined : thisGuidanceId);
                                }}
                            >
                                <AccordionSummary
                                    aria-controls={`${thisGuidanceId}-content`}
                                    id={`${thisGuidanceId}-header`}
                                >
                                    <Typography>{fullName}</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <GuidanceOutlineItem
                                        guidanceId={thisGuidanceId}
                                        labeledRefs={bdg.indexedGuidance.referenceMap['labeledRefs']}
                                        contentIds={contentIds}
                                    />
                                </AccordionDetails>
                            </Accordion>
                        );
                    }
                )}
            </Box>
        </Box>,
        document.body
    );
};

export default GuidanceOutline;
