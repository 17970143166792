import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
    Autocomplete,
    Box,
    Chip,
    Grid,
    IconButton,
    MenuItem,
    TextField,
    Typography
} from "@mui/material/";
import { SimpleModalWrapper } from "../../../components/dialog/wrappers/simpleModalWrapper";
import MiniToolbarWithShadow from "../../../components/navigation/Toolbars/MiniToolbarWithShadow";
import { AnimatedIconButton } from "../../../components/buttons/boltButton";
import { Assistant } from "@mui/icons-material";
import StyledMenu from "../../../components/menu";
import EditableSummary from "./EditableSummary";
import { useDebounce, useLocalStorage } from "@uidotdev/usehooks";
import { useTakerState } from "../../../containers/TakerDocumentState/TakerDocumentState";
import { useAddExecSummaryAnalysisMutation } from "../../../redux/services/taker";
import { ExecSummaryType } from "../../../redux/models/dataModelTypes";
import KeyTermGroup from "./KeyTermGroup";
import { useAddTakerDocumentDataMutation, useGetLatestTakerDocumentDataQuery, useUpdateTakerDocumentDataMutation } from "../../../redux/services/takerData";
import { skipToken } from "@reduxjs/toolkit/query";

const TWELVE_HOURS_MS = (1000 * 60 * 60 * 12);
const DEBOUNCE_DELAY = 2500;

interface Props {
    open: boolean;
    setOpen: (o: boolean) => void;
    readOnly: boolean;
}

export default ({
    open,
    setOpen,
    readOnly
}: Props) => {
    const {
        taker,
        activeTakerDocument
    } = useTakerState();
    const [localSummary, setLocalSummary] = useState<string>();
    const debouncedLocalSummary = useDebounce(localSummary, DEBOUNCE_DELAY);
    const [aiGeneratedAnchorEl, setAiGeneratedAnchorEl] = useState<null | HTMLElement>(null);
    const [filteredKeyTermGroupIds, setFilteredKeyTermGroupIds] = useLocalStorage<string[]>("ExecutiveSummary-FilteredKeyTermGroupIds", []);
    const [persistedSummary, setPersistedSummary] = useState<string>();

    const [aiSummaryJobPending, setAiSummaryJobPending] = useState<boolean>();
    const [aiSummaryJobDone, setAiSummaryJobDone] = useState<boolean>();

    const [addExecSummaryAnalysis, addExecSummaryAnalysisRes] = useAddExecSummaryAnalysisMutation();
    const [addTakerDocumentData, addTakerDocumentDataRes] = useAddTakerDocumentDataMutation();
    const [updateTakerDocumentData, updateTakerDocumentDataRes] = useUpdateTakerDocumentDataMutation();
    
    const {
        data: latestExecSummaryData,
        isUninitialized,
        isFetching,
    } = useGetLatestTakerDocumentDataQuery(!!activeTakerDocument ? 
        {
            takerDocumentId: activeTakerDocument.id,
            contentType: "EXECUTIVE_SUMMARY"
        } : 
        skipToken
    );

    useEffect(() => {
        if (!isUninitialized && latestExecSummaryData) {
            setLocalSummary(JSON.stringify(latestExecSummaryData.content));
            setPersistedSummary(JSON.stringify(latestExecSummaryData.content));
        }
    }, [
        isUninitialized,
        latestExecSummaryData
    ]);

    useEffect(() => {
        if (addTakerDocumentDataRes.isSuccess) {
            setPersistedSummary(JSON.stringify(addTakerDocumentDataRes.data.content));        
        }
        if (updateTakerDocumentDataRes.isSuccess) {
            setPersistedSummary(JSON.stringify(updateTakerDocumentDataRes.data.content));
        }
    }, [
        addTakerDocumentDataRes.fulfilledTimeStamp,
        updateTakerDocumentDataRes.fulfilledTimeStamp
    ]);

    const isDirty = useMemo(
        () => localSummary !== persistedSummary, 
        [
            persistedSummary,
            localSummary
        ]
    );

    const lastSavedTimestamp = useMemo(() => {
        if (isDirty || 
            addTakerDocumentDataRes.isLoading || 
            updateTakerDocumentDataRes.isLoading
        ) {
            return "saving...";
        }

        let ts = undefined;
        if (updateTakerDocumentDataRes.data) {
            ts = updateTakerDocumentDataRes.data.updatedAt;
        } else if (addTakerDocumentDataRes.data) {
            ts = addTakerDocumentDataRes.data.updatedAt;
        } else if (latestExecSummaryData) {
            ts = latestExecSummaryData.updatedAt;
        }

        if (!ts) {
            return;
        }

        const nowTs = new Date().getTime();
        const lastSavedDate = new Date(ts);
        if (nowTs - ts > TWELVE_HOURS_MS) {
        return `last saved on ${lastSavedDate.toLocaleString()}`;
        }
        return `last saved at ${lastSavedDate.toLocaleTimeString()}`;
    }, [
        isDirty,
        addTakerDocumentDataRes,
        updateTakerDocumentDataRes,
        latestExecSummaryData
    ]);

    const doUpsert = useCallback((summary: string) => {
        if (!isDirty) { 
            return;
        }
        
        if (taker && 
            activeTakerDocument && 
            latestExecSummaryData && 
            !isUninitialized
        ) {
            updateTakerDocumentData({
                takerId: taker.id,
                takerDocumentId: activeTakerDocument.id,
                id: latestExecSummaryData.id,
                content: JSON.parse(summary)
            });
        } else if (taker && 
            activeTakerDocument && 
            !isUninitialized
        ) {
            addTakerDocumentData({
                takerId: taker.id,
                takerDocumentId: activeTakerDocument?.id,
                contentType: "EXECUTIVE_SUMMARY",
                content: JSON.parse(summary)
            });
        }
    }, [
        isDirty,
        isUninitialized,
        isFetching,
        taker,
        activeTakerDocument,
        latestExecSummaryData
    ]);

    useEffect(() => {
        if (debouncedLocalSummary !== undefined) {
            doUpsert(debouncedLocalSummary);
        }
    }, [
        latestExecSummaryData,
        debouncedLocalSummary
    ]);

    const latestSummaryAnalysis = useMemo(() => {
        if (!activeTakerDocument) {
            return;
        }

        let sortedAnalyses = [...activeTakerDocument.executiveSummaryTakerDocumentAnalyses];
        sortedAnalyses.sort((a, b) => {
            return b.createdAt - a.createdAt
        });

        // return the latest analysis
        return sortedAnalyses[0];
    }, [activeTakerDocument?.executiveSummaryTakerDocumentAnalyses]);

    const latestSummaryPending = useMemo(() =>
        latestSummaryAnalysis?.state === "PENDING_GENERATION",
        [latestSummaryAnalysis]
    );

    const allKeyTermGroups = useMemo(() =>
        activeTakerDocument?.takerDocumentUploads || [],
        [activeTakerDocument]
    );

    // reset parameters
    useEffect(() => {
        if (!activeTakerDocument) {
            return;
        }

        // check and set overlap between keyTermGroupIds and filteredKeyTermGroupIds
        const keyTermGroupIds = activeTakerDocument.takerDocumentUploads.map(k => k.id);
        const intersection = filteredKeyTermGroupIds.filter(id => keyTermGroupIds.includes(id));
        setFilteredKeyTermGroupIds(intersection);
    }, [
        activeTakerDocument?.takerDocumentUploads
    ]);

    const createExecutiveSummarizationAnalysis = useCallback((summaryType: ExecSummaryType) => {
        if (filteredKeyTermGroupIds.length === 0 || 
            aiSummaryJobPending || 
            aiSummaryJobDone
        ) {
            return;
        }

        if (taker) {
            addExecSummaryAnalysis({
                takerId: taker.id,
                takerDocumentId: activeTakerDocument?.id,
                takerDocumentUploadIdFilter: filteredKeyTermGroupIds,
                execSummaryType: summaryType
            });
            setAiSummaryJobPending(true);
        } else {
            console.error("No takerDocumentUpload found");
        }
    }, [
        taker,
        activeTakerDocument,
        filteredKeyTermGroupIds
    ]);

    const getOptionLabel = (option: string) => {
        const kt = allKeyTermGroups.find(kt => kt.id === option);
        return kt ? kt.name : '';
    };

    let IconButtonCustom: (typeof IconButton) | (typeof AnimatedIconButton) = IconButton;
    if (aiSummaryJobPending) {
        IconButtonCustom = AnimatedIconButton;
    }

    const aiGeneratedOpen = Boolean(aiGeneratedAnchorEl);

    return (
        <SimpleModalWrapper
            headerText={(
                !!lastSavedTimestamp ?
                    `Executive Summary (${lastSavedTimestamp})` :
                    `Executive Summary`
            )}
            open={open}
            handleClose={() => setOpen(false)}
            maxWidth={false}
            fullScreen
        >
            <Grid
                container
                height="100%"
                width="100%"
            >
                <Grid
                    item
                    xs={6}
                    height="100%"
                >
                    <Box
                        style={{
                            height: '100%',
                            width: '100%',
                            display: 'flex',
                            flexDirection: 'column'
                        }}
                    >
                        <MiniToolbarWithShadow
                            variant="dense"
                            disableGutters
                            sx={{ width: "100%" }}
                        >
                            <Typography
                                variant="h6"
                                sx={{ paddingLeft: 1 }}
                            >
                                Summary
                            </Typography>
                            {!readOnly && (
                                <Box paddingRight={1}>
                                    <IconButtonCustom
                                        size="small"
                                        data-testid={`key-terms-ai-menu-button`}
                                        onClick={(event: React.MouseEvent<HTMLElement>) => {
                                            setAiGeneratedAnchorEl(event.currentTarget);
                                        }}
                                    >
                                        <Assistant />
                                    </IconButtonCustom>
                                    <StyledMenu
                                        id="key-terms-ai-menu"
                                        aria-labelledby="demo-positioned-button"
                                        anchorEl={aiGeneratedAnchorEl}
                                        open={aiGeneratedOpen}
                                        onClose={() => setAiGeneratedAnchorEl(null)}
                                    >
                                        <MenuItem
                                            disabled={latestSummaryPending}
                                            onClick={() => {
                                                createExecutiveSummarizationAnalysis("full");
                                                setAiGeneratedAnchorEl(null);
                                            }}
                                        >
                                            <Box className="menu-item-box-1">
                                                Generate Single Summary
                                            </Box>
                                        </MenuItem>
                                        <MenuItem
                                            disabled={latestSummaryPending}
                                            onClick={() => {
                                                createExecutiveSummarizationAnalysis("by_group");
                                                setAiGeneratedAnchorEl(null);
                                            }}
                                        >
                                            <Box className="menu-item-box-1">
                                                Generate Summary by Group
                                            </Box>
                                        </MenuItem>
                                        <MenuItem
                                            disabled={latestSummaryPending}
                                            onClick={() => {
                                                createExecutiveSummarizationAnalysis("by_document");
                                                setAiGeneratedAnchorEl(null);
                                            }}
                                        >
                                            <Box className="menu-item-box-1">
                                                Generate Summary by Document
                                            </Box>
                                        </MenuItem>
                                    </StyledMenu>
                                </Box>
                            )}
                        </MiniToolbarWithShadow>
                        <Box
                            width="100%"
                            overflow="auto"
                            flexGrow={1}
                            padding={1}
                        >
                            <EditableSummary
                                defaultValue={localSummary}
                                onChangeValue={(s: string) => {
                                    setLocalSummary(s);
                                }}
                                onJobDone={() => {
                                    setAiSummaryJobDone(true);
                                    setAiSummaryJobPending(false);
                                }}
                                onJobPending={() => {
                                    setAiSummaryJobDone(false);
                                    setAiSummaryJobPending(true);
                                }}
                            />
                        </Box>
                    </Box>
                </Grid>
                <Grid
                    item
                    xs={6}
                    height="100%"
                >
                    <Box
                        style={{
                            height: '100%',
                            width: '100%',
                            display: 'flex',
                            flexDirection: 'column'
                        }}
                    >
                        <MiniToolbarWithShadow
                            variant="dense"
                            disableGutters
                            sx={{
                                width: "100%",
                                paddingLeft: 1,
                                paddingRight: 1
                            }}
                        >
                            <Box display="flex" alignItems="center">
                                <Typography
                                    variant="h6"
                                    sx={{ paddingLeft: 1 }}
                                >
                                    Key Terms
                                </Typography>
                            </Box>
                            <Box display="flex" alignItems="center" minWidth="50%" paddingRight={1}>
                                <Autocomplete
                                    size='small'
                                    multiple
                                    fullWidth
                                    data-testid="key-terms-filter"
                                    options={allKeyTermGroups.map(kt => kt.id)}
                                    getOptionLabel={getOptionLabel}
                                    value={filteredKeyTermGroupIds}
                                    onChange={(event, newValue) => {
                                        setFilteredKeyTermGroupIds(newValue);
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            placeholder="Key Term Filter"
                                            variant="standard"
                                            data-testid="key-terms-filter-input"
                                        />
                                    )}
                                    renderTags={(tagValue, getTagProps) => {
                                        const allChips = tagValue.map((option, index) => {
                                            const kt = allKeyTermGroups.find(kt => kt.id === option);
                                            if (!kt) {
                                                return null;
                                            }
                                            return (
                                                <Chip
                                                    label={kt.name}
                                                    size='small'
                                                    {...getTagProps({ index })}
                                                    data-testid={`key-term-chip-${option}`}
                                                />
                                            );
                                        });
                                        return allChips
                                    }}
                                />
                            </Box>
                        </MiniToolbarWithShadow>
                        <Box
                            width="100%"
                            overflow="auto"
                            flexGrow={1}
                            paddingBottom={1}
                        >
                            {filteredKeyTermGroupIds.map((id) => {
                                let name = getOptionLabel(id);
                                return (
                                    <Box 
                                        key={id}
                                        paddingTop={1}
                                        paddingLeft={1}
                                        paddingRight={1}
                                    >
                                        <Typography 
                                            variant="subtitle1"
                                            textAlign="center"
                                        >
                                            {name}
                                        </Typography>
                                        <KeyTermGroup takerDocumentUploadId={id} />
                                    </Box>
                                );
                            })}
                        </Box>
                    </Box>
                </Grid>
            </Grid>
        </SimpleModalWrapper>
    );
}
