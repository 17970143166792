import React from "react";
import { SimpleModalWrapper } from "../../components/dialog/wrappers/simpleModalWrapper";
import { Box, Button } from "@mui/material/";
import * as yup from "yup";
import { TextFieldRhf } from "../../components/form/reactHookForm/textFieldRhf";
import { MultiSelectChipsAutoCompRhf } from "../../components/form/reactHookForm/multiSelectChipAutoCompRhf";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useAddUserMutation } from "../../redux/services/user";


interface Props {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

interface formInputs {
  uid: string | null;
  firstName: string;
  lastName: string;
  email: string;
  roles: string[];
}

const userRoles = [
  { "id": "BUILDER", "display": "Builder" },
  { "id": "TAKER", "display": "Taker" },
  { "id": "SUPERADMIN", "display": "Superadmin" },
  { "id": "INDEXER", "display": "Indexer" },
  { "id": "DEBUGGER", "display": "Debugger" },
  { "id": "RESEARCH", "display": "Research" }
];

export function CreateUserModal({ open, setOpen }: Props) {
  const [addUser, addUserResult] = useAddUserMutation()

  const schema = yup.object().shape({
    uid: yup.string(),
    firstName: yup.string().required(),
    lastName: yup.string().required(),
    email: yup.string().required(),
    roles: yup.array().required(),
  });

  const defaultValues = {
    uid: "",
    firstName: "",
    lastName: "",
    email: "",
    roles: [],
  };

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<formInputs>({
    resolver: yupResolver(schema),
    defaultValues: defaultValues,
  });

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmitCust = (d: any) => {
    addUser({
      uid: d.uid,
      roles: d.roles.map((r: any) => r.id),
      firstName: d.firstName,
      lastName: d.lastName,
      email: d.email
    });
    setOpen(false);
  };

  return (
    <SimpleModalWrapper
      headerText="Create User"
      open={open}
      handleClose={handleClose}
      maxWidth='md'
    >
      <Box>
        <form
          onSubmit={handleSubmit(handleSubmitCust)}
          style={{
            padding: "16px 0px 16px 0px",
            display: "flex",
            flexDirection: "column",
            width: "100%",
            height: "100%",
            justifyContent: "space-between",
          }}
        >
          <TextFieldRhf
            label="Firebase UID"
            fieldName="uid"
            sx={{ minHeight: "71px" }}
            control={control}
            errors={errors}
          />
          <TextFieldRhf
            label="First Name"
            fieldName="firstName"
            sx={{ minHeight: "71px" }}
            control={control}
            errors={errors}
          />
          <TextFieldRhf
            label="Last Name"
            fieldName="lastName"
            sx={{ minHeight: "71px" }}
            control={control}
            errors={errors}
          />
          <TextFieldRhf
            label="Email"
            fieldName="email"
            sx={{ minHeight: "71px" }}
            control={control}
            errors={errors}
          />
          <MultiSelectChipsAutoCompRhf
            options={userRoles}
            label="User Roles"
            placeholder="role"
            fieldName="roles"
            control={control}
            errors={errors}
            sx={{ minHeight: "71px" }}
          />
          <Box>
            <Button variant="contained" type="submit" sx={{ width: "30%" }}>
              Submit
            </Button>
            <Button
              variant="contained"
              sx={{ width: "30%", marginLeft: "30px" }}
              onClick={handleClose}
            >
              Cancel
            </Button>
          </Box>
        </form>
      </Box>
    </SimpleModalWrapper>
  );
}
