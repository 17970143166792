import React from "react";
import Box from '@mui/material/Box';
import { SimpleModalWrapper } from "../../../components/dialog/wrappers/simpleModalWrapper";
import { Module } from "../../../types/builderv2.generated";
import { RJSFSchema } from '@rjsf/utils';
import validator from '@rjsf/validator-ajv8';
import { Form } from "../../../components/jsonschema/theme";

interface ModuleEditorProps {
    fullSchema: RJSFSchema;
    parentModule: Module | undefined;
    initialTopModule: Module;
    onUpdate: (b: Module) => void;
    onClose: () => void;
}

const EditModuleDataModal = ({
    fullSchema,
    parentModule,
    initialTopModule,
    onUpdate,
    onClose
}: ModuleEditorProps) => {
    const searchModuleHelper = (moduleId: string, module: Module): Module | null => {
        if (moduleId === module.id) {
            return module;
        }

        if (module.nestedModules) {
            for (const m of module.nestedModules) {
                let res = searchModuleHelper(moduleId, m);
                if (!!res) {
                    return res;
                }
            }
        }
        return null;
    };

    const addModuleHelper = (moduleId: string, moduleToAdd: Module, modules: Module[]) => {
        const newModules: Module[] = [];
        for (const m of modules) {
            const newModule: Module = JSON.parse(JSON.stringify(m));
            if (!newModule.nestedModules) {
                newModule.nestedModules = [];
            }
            newModule.nestedModules = addModuleHelper(moduleId, moduleToAdd, newModule.nestedModules);
            if (m.id === moduleId) {
                newModule.nestedModules.push(JSON.parse(JSON.stringify(moduleToAdd)));
            }
            newModules.push(newModule);
        }
        return newModules;
    };

    const searchParentModuleHelper = (moduleId: string, module: Module): Module | null => {
        if (module.nestedModules) {
            for (const m of module.nestedModules) {
                if (m.id === moduleId) {
                    return module;
                } else {
                    let res = searchParentModuleHelper(moduleId, m);
                    if (!!res) {
                        return res;
                    }
                }
            }
        }
        return null;
    };

    const updateModuleHelper = (moduleToUpdate: Module, modules: Module[]) => {
        const newModules: Module[] = [];
        for (const m of modules) {
            const newModule: Module = JSON.parse(JSON.stringify(m));
            if (m.id === moduleToUpdate.id) {
                newModules.push(JSON.parse(JSON.stringify(moduleToUpdate)));
            } else if (newModule.nestedModules) {
                newModule.nestedModules = updateModuleHelper(moduleToUpdate, newModule.nestedModules);
                newModules.push(newModule);
            } else {
                newModules.push(newModule);
            }
        }
        return newModules;
    };

    const updateModule = (m: Module) => {
        const newTopModule = updateModuleHelper(m, [initialTopModule])[0];
        onUpdate(newTopModule);
    };

    const onDeleteHelper = (moduleId: string, modules: Module[]) => {
        const newModules: Module[] = [];
        for (const m of modules) {
            if (m.id !== moduleId) {
                const newModule: Module = JSON.parse(JSON.stringify(m));
                if (m.nestedModules) {
                    newModule.nestedModules = onDeleteHelper(moduleId, m.nestedModules);
                }
                newModules.push(newModule);
            }
        }
        return newModules;
    };

    if (!parentModule) {
        return null;
    }

    return (
        <SimpleModalWrapper
            headerText="Data Specifications"
            open
            handleClose={onClose}
            maxWidth='md'
        >
            <Box
                sx={{
                    width: '100%',
                    paddingTop: '3%',
                    alignContent: "center"
                }}
            >
                <Form
                    formData={parentModule.dataSpec}
                    onSubmit={(data, event) => {
                        let newMod: Module = JSON.parse(JSON.stringify(parentModule));
                        newMod.dataSpec = data.formData;
                        onClose();
                        updateModule(newMod);
                    }}
                    schema={({
                        anyOf: [
                            {
                                "$ref": "#/$defs/basicDataSpec"
                            },
                            {
                                "$ref": "#/$defs/basicTableDataSpec"
                            },
                            {
                                "$ref": "#/$defs/mappingQuestionDataSpec"
                            },
                            {
                                "$ref": "#/$defs/tableWithSingleAnalysisDataSpec"
                            }
                        ],
                        $defs: fullSchema.$defs
                    } as RJSFSchema)}
                    validator={validator}
                />
            </Box>
        </SimpleModalWrapper>
    );
}

export default EditModuleDataModal;