import React, { useCallback, useContext, useEffect, useMemo, useRef, useState, memo } from "react";
import { useTakerState } from "./TakerDocumentState";
import { TakerDocumentUpload } from "../../redux/models/dataModelTypes";
import { DocumentKeyTermsHolder, DocumentKeyTermsMutation } from "../../types/documentKeyTerms";
import {
    useDeleteTakerDocumentUploadDataMutation,
    useGetLatestKeyTermGroupDataQuery,
    useUpdateKeyTermGroupDataDiffMutation
} from "../../redux/services/takerData";
import { DocumentAnnotation, DocumentHighlight, KeyTerm, KeyTermSingleSummaryAnalysis, KeyTermTaggingAnalysis, KeyTermTaggingSummaryAnalysis, LexicalDocument } from "../../types/taker/documentkeyterms.generated";
import { dummyKeyTermData } from "./dummyKeyTermData";
import { useSnackbar } from "notistack";

export interface KeyTermSummaryUpdate {
    takerDocumentUploadId: string;
    termName: string;
    summary: string;
};

interface DocumentKeyTermsService {
    // Key terms basic modifiers
    createNewKeyTerm: (tn: string) => KeyTerm;
    createKeyTerms: (kts: KeyTerm[]) => void;
    removeKeyTerm: (tn: string) => void;
    removeAllKeyTerms: () => void;
    updateKeyTermInfo: (id: string, tn: string, c: string[]) => void;
    updateKeyTermInfoAndSummary: (id: string, tn: string, c: string[], s: string) => void;
    updateKeyTermName: (id: string, tn: string) => void;
    updateKeyTermSummary: (id: string, s: string) => void;
    updateKeyTermSummaries: (ktsus: KeyTermSummaryUpdate[]) => void;
    shiftKeyTermUp: (id: string) => void;
    shiftKeyTermDown: (id: string) => void;
    insertKeyTermAtPosition: (sid: string, tid: string) => void;
    addDocumentAnnotation: (tns: string[], da: DocumentAnnotation) => void;
    removeDocumentAnnotation: (tn: string, da: DocumentAnnotation) => void;
    addKeyTermSingleSummaryAnalysis: (id: string, a: KeyTermSingleSummaryAnalysis) => void;
    removeKeyTermAnalysis: (id: string, aId: string) => void;
    addKeyTermTaggingSummaryAnalysis: (id: string, a: KeyTermTaggingSummaryAnalysis) => void;
    addKeyTermTaggingAnalysis: (id: string, a: KeyTermTaggingAnalysis) => void;
    addHiddenForKeyTermTaggingAnalysis: (id: string, aIdx: number, h: string) => void;
    markSummaryAddressedForKeyTermAnalysis: (id: string, aIdx: number) => void;
    acceptAiKeyTerm: (id: string) => void;

    containsKeyTerm: (tn: string) => boolean;
    bulkUpdateDocumentAnnotations: (tnls: string[][], ogTnls: string[][], das: DocumentAnnotation[]) => void;

    // Lexical document modifiers
    createLexicalDocument: (ld: LexicalDocument) => void;
    removeLexicalDocument: (di: string) => void;

    // Fetch transformed data
    getTakerDocumentName: (documentId: string) => string;
    getTextForAnnotation: (annotationId: string) => string;
    getTextContentForDocumentHighlights: (id: string, pn: number, dh: DocumentHighlight[]) => string;
    getMockKeyTermSummary: (tn: string) => string;

    setOpenDetailsModal(id: string | undefined): void;

    // Raw data
    keyTerms?: KeyTerm[];
    lexicalDocuments?: LexicalDocument[];
    currentlyOpenDetailsModal: number | undefined;
    indexedDocumentAnnotations: Record<string, Record<number, DocumentAnnotation[]>> | undefined;
}

interface KeyTermGroupStateHookData {
    initStateCount: number;
    isKeyTermsUninitialized: boolean;
    lastSavedTimestamp: number | undefined;
    hasSavedKeyTermsData: boolean;
    isKeyTermsDataLoading: boolean;
    currentlyOpenDetailsModal: number | undefined;
    isKeyTermGroupStateDirty: boolean;
    takerDocumentUpload?: TakerDocumentUpload;
    deleteTakerDocumentUploadData: () => void;
    documentKeyTermsService: DocumentKeyTermsService;
}

const Context = React.createContext({});

const SAVE_DELAY = 4000;

export function useKeyTermGroupState(): KeyTermGroupStateHookData {
    return useContext(Context) as KeyTermGroupStateHookData;
}

interface KeyTermGroupStateInnerProps {
    takerDocumentUploadId: string;
    children: any;
}

const KeyTermGroupStateInner = ({
    takerDocumentUploadId,
    children
}: KeyTermGroupStateInnerProps) => {
    const {
        taker,
        takerDocumentId,
        takerPermissionState,
        takerDocumentUploads,
        isTakerDocumentUninitialized
    } = useTakerState();

    const [
        updateKeyTermGroupDataDiff,
        updateKeyTermGroupDataDiffRes
    ] = useUpdateKeyTermGroupDataDiffMutation();

    const [
        deleteTakerDocumentUploadData,
    ] = useDeleteTakerDocumentUploadDataMutation();

    const {
        data: latestKeyTermsData,
        isFetching: isKeyTermsDataLoading,
        isUninitialized: isKeyTermsDataUninitialized,
        isError: isKeyTermsDataError,
        isSuccess: isKeyTermsDataSuccess
    } = useGetLatestKeyTermGroupDataQuery(takerDocumentUploadId);

    const [updatingJob, setUpdatingJob] = useState<any>(null);
    const [initStateCount, setInitStateCount] = useState<number>(0);
    const [isStateDirty, setIsStateDirty] = useState<boolean>(true);
    const [lastMarkedClean, setLastMarkedClean] = useState<number>();
    const [openDetailsModal, setOpenDetailsModal] = useState<string | undefined>(undefined);

    const [indexedDocumentAnnotations, setIndexedDocumentAnnotations] = useState<Record<string, Record<number, DocumentAnnotation[]>>>();

    useEffect(() => {
        if (isKeyTermsDataSuccess || updateKeyTermGroupDataDiffRes.isSuccess) {
            setIsStateDirty(false);
            if (updateKeyTermGroupDataDiffRes.isSuccess) {
                setLastMarkedClean(updateKeyTermGroupDataDiffRes.data.updatedAt);
            } else if (isKeyTermsDataSuccess) {
                setLastMarkedClean(latestKeyTermsData.updatedAt);
            }
        }
    }, [isKeyTermsDataSuccess, latestKeyTermsData, updateKeyTermGroupDataDiffRes]);

    const targetTakerDocumentUpload = useMemo(() => {
        if (takerDocumentUploads) {
            for (const takerDocumentUpload of takerDocumentUploads) {
                if (takerDocumentUpload.id === takerDocumentUploadId) {
                    return takerDocumentUpload;
                }
            }
        }
    }, [takerDocumentUploads, takerDocumentUploadId]);

    const documentIdToNameForCurrentGroup = useMemo(() => {
        let documentIdToNameMap: Record<string, string> = {};
        if (targetTakerDocumentUpload?.fileUpload?.fileUploadItems) {
            for (let i = 0; i < targetTakerDocumentUpload?.fileUpload?.fileUploadItems?.length; i++) {
                documentIdToNameMap[targetTakerDocumentUpload?.fileUpload?.fileUploadItems[i].id] = targetTakerDocumentUpload?.fileUpload?.fileUploadItems[i].label;
            }
        }
        return documentIdToNameMap
    }, [targetTakerDocumentUpload?.fileUpload?.fileUploadItems?.length]);

    const activeTargetDocumentKeyTermsState = useRef<DocumentKeyTermsHolder | undefined>(
        latestKeyTermsData && new DocumentKeyTermsHolder(latestKeyTermsData.content)
    );
    const uncommittedMutations = useRef<DocumentKeyTermsMutation[]>([]);
    const doSyncUpdate = useRef(false);

    const doDeleteTakerDocumentUploadDataCallback = useCallback(() => {
        if (taker && latestKeyTermsData) {
            deleteTakerDocumentUploadData({
                id: latestKeyTermsData.id,
                takerId: taker.id,
                takerDocumentId: takerDocumentId
            });
        }
    }, [deleteTakerDocumentUploadData, latestKeyTermsData, taker, takerDocumentId]);

    useEffect(() => {
        if (!!latestKeyTermsData) {
            activeTargetDocumentKeyTermsState.current = new DocumentKeyTermsHolder(
                latestKeyTermsData.content
            );
            setInitStateCount(initStateCount + 1);
            setIndexedDocumentAnnotations(activeTargetDocumentKeyTermsState.current.buildIndexedDocumentAnnotations());
        }
    }, [latestKeyTermsData?.id]);

    const doStateUpdateFunc = (newState: DocumentKeyTermsHolder) => {
        // no updates for non read/roles
        if (!takerPermissionState.isReadWrite) {
            return
        }
        activeTargetDocumentKeyTermsState.current = newState;
        uncommittedMutations.current.push(...newState.mutations);
        setIsStateDirty(true);

        if (updatingJob) {
            clearTimeout(updatingJob)
        }

        let newUpdatingJob = setTimeout(() => {
            if (taker &&
                latestKeyTermsData &&
                activeTargetDocumentKeyTermsState.current &&
                uncommittedMutations.current.length > 0
            ) {
                updateKeyTermGroupDataDiff({
                    id: latestKeyTermsData.id,
                    mutations: [...uncommittedMutations.current]
                });
                uncommittedMutations.current = [];
            }
        }, SAVE_DELAY);
        setUpdatingJob(newUpdatingJob);
    };

    const doStateUpdate = useRef(doStateUpdateFunc);
    useEffect(() => {
        doStateUpdate.current = doStateUpdateFunc;
    });

    useEffect(() => {
        if (activeTargetDocumentKeyTermsState.current && isStateDirty) {
            setIndexedDocumentAnnotations(activeTargetDocumentKeyTermsState.current.buildIndexedDocumentAnnotations());
        };
    }, [isStateDirty]);

    const annotationIdToHighlightedText = useMemo(() => {
        const parser = new DOMParser();
        let annotationIdToHighlightedText: Record<string, string> = {};
        if (indexedDocumentAnnotations && activeTargetDocumentKeyTermsState?.current?.documentKeyTerms?.lexicalDocuments) {
            for (const identifier in indexedDocumentAnnotations) {
                let currentLexicalDocument = activeTargetDocumentKeyTermsState.current.documentKeyTerms.lexicalDocuments.find(ld => ld.identifier === identifier);
                for (const page in indexedDocumentAnnotations[identifier]) {
                    const pageHtml = (currentLexicalDocument?.lexicalPages[page] as any).root?.children[0].rawHtml;
                    const pageDocument = parser.parseFromString(pageHtml, "text/html");
                    for (const annotation of indexedDocumentAnnotations[identifier][page]) {
                        let entryString = "";
                        for (let documentHighlight of annotation.documentHighlights) {
                            entryString += pageDocument.getElementById(documentHighlight.elementId)?.innerText;
                        }
                        annotationIdToHighlightedText[annotation.annotationId] = entryString;
                    }
                }
            }
        };
        return annotationIdToHighlightedText;
    }, [indexedDocumentAnnotations]);

    const lastSavedTimestamp = useMemo(
        () => !isKeyTermsDataError && (lastMarkedClean || latestKeyTermsData?.updatedAt),
        [
            isKeyTermsDataError,
            lastMarkedClean,
            latestKeyTermsData
        ]
    );

    const hasSavedKeyTermsData = useMemo(
        () => !isKeyTermsDataError && !!latestKeyTermsData?.updatedAt,
        [
            isKeyTermsDataError,
            latestKeyTermsData
        ]
    );

    const getTextContentForDocumentHighlights = useCallback((lexicalDocumentIdentifier: string, pageIndex: number, documentHighlights: DocumentHighlight[]) => {
        if (activeTargetDocumentKeyTermsState?.current?.documentKeyTerms?.lexicalDocuments) {
            const currentLexicalDocument = activeTargetDocumentKeyTermsState.current.documentKeyTerms.lexicalDocuments.find(ld => ld.identifier === lexicalDocumentIdentifier);
            if (!currentLexicalDocument) {
                return;
            }

            const page = currentLexicalDocument.lexicalPages[pageIndex] as any;
            const pageDocument = (new DOMParser()).parseFromString(page['root']['children'][0]['rawHtml'], "text/html");

            let entryString = "";
            for (const docHighlight of documentHighlights) {
                entryString += pageDocument.getElementById(docHighlight.elementId)?.innerText;
            }
            return entryString;
        };
    }, [activeTargetDocumentKeyTermsState]);

    return (
        <Context.Provider
            value={{
                initStateCount: initStateCount,
                isKeyTermsUninitialized: isTakerDocumentUninitialized || isKeyTermsDataUninitialized,
                currentlyOpenDetailsModal: openDetailsModal,
                lastSavedTimestamp,
                hasSavedKeyTermsData,
                isKeyTermGroupStateDirty: isStateDirty,
                isKeyTermsDataLoading,
                takerDocumentUpload: targetTakerDocumentUpload,
                deleteTakerDocumentUploadData: () => doDeleteTakerDocumentUploadDataCallback(),
                documentKeyTermsService: {
                    createNewKeyTerm: (tn) => {
                        if (activeTargetDocumentKeyTermsState.current && !activeTargetDocumentKeyTermsState.current.containsKeyTerm(tn)) {
                            const newState = new DocumentKeyTermsHolder(activeTargetDocumentKeyTermsState.current.documentKeyTerms);
                            const newTerm = newState.createNewKeyTerm(tn);
                            doStateUpdate.current(newState);
                            return newTerm;
                        }
                    },
                    createKeyTerms: (kts) => {
                        if (activeTargetDocumentKeyTermsState.current) {
                            const newState = new DocumentKeyTermsHolder(activeTargetDocumentKeyTermsState.current.documentKeyTerms);
                            for (const kt of kts) {
                                newState.createKeyTerm(kt);
                            }
                            doStateUpdate.current(newState);
                        }
                    },
                    removeKeyTerm: (tn) => {
                        if (activeTargetDocumentKeyTermsState.current) {
                            const newState = new DocumentKeyTermsHolder(activeTargetDocumentKeyTermsState.current.documentKeyTerms);
                            newState.removeKeyTerm(tn);
                            doStateUpdate.current(newState);
                        }
                    },
                    removeAllKeyTerms: () => {
                        if (activeTargetDocumentKeyTermsState.current) {
                            const newState = new DocumentKeyTermsHolder(activeTargetDocumentKeyTermsState.current.documentKeyTerms);
                            newState.removeAllKeyTerms();
                            doStateUpdate.current(newState);
                        }
                    },
                    updateKeyTermInfo: (id, tn, c) => {
                        if (activeTargetDocumentKeyTermsState.current) {
                            const newState = new DocumentKeyTermsHolder(activeTargetDocumentKeyTermsState.current.documentKeyTerms);
                            newState.updateKeyTermInfo(id, tn, c);
                            doStateUpdate.current(newState);
                        }
                    },
                    updateKeyTermInfoAndSummary: (id, tn, c, s) => {
                        if (activeTargetDocumentKeyTermsState.current) {
                            const newState = new DocumentKeyTermsHolder(activeTargetDocumentKeyTermsState.current.documentKeyTerms);
                            newState.updateKeyTermInfoAndSummary(id, tn, c, s);
                            doStateUpdate.current(newState);
                        }
                    },
                    updateKeyTermSummary: (id, s) => {
                        if (activeTargetDocumentKeyTermsState.current) {
                            const newState = new DocumentKeyTermsHolder(activeTargetDocumentKeyTermsState.current.documentKeyTerms);
                            newState.updateKeyTermSummary(id, s);
                            doStateUpdate.current(newState);
                        }
                    },
                    updateKeyTermName: (id, tn) => {
                        if (activeTargetDocumentKeyTermsState.current) {
                            const newState = new DocumentKeyTermsHolder(activeTargetDocumentKeyTermsState.current.documentKeyTerms);
                            newState.updateKeyTermName(id, tn);
                            doStateUpdate.current(newState);
                        }
                    },
                    updateKeyTermSummaries: (ktsus: KeyTermSummaryUpdate[]) => {
                        if (activeTargetDocumentKeyTermsState.current) {
                            const newState = new DocumentKeyTermsHolder(activeTargetDocumentKeyTermsState.current.documentKeyTerms);
                            for (const ktsu of ktsus) {
                                let target = newState.documentKeyTerms.keyTerms.find(kt => kt.termName === ktsu.termName);
                                if (target && target.identifier) {
                                    newState.updateKeyTermSummary(
                                        target.identifier,
                                        ktsu.summary
                                    );
                                }
                            }
                            doStateUpdate.current(newState);
                        }
                    },
                    shiftKeyTermUp: (id) => {
                        if (activeTargetDocumentKeyTermsState.current) {
                            const newState = new DocumentKeyTermsHolder(activeTargetDocumentKeyTermsState.current.documentKeyTerms);
                            newState.updateKeyTermPosition(id, -1);
                            doStateUpdate.current(newState);
                        }
                    },
                    shiftKeyTermDown: (id) => {
                        if (activeTargetDocumentKeyTermsState.current) {
                            const newState = new DocumentKeyTermsHolder(activeTargetDocumentKeyTermsState.current.documentKeyTerms);
                            newState.updateKeyTermPosition(id, 1);
                            doStateUpdate.current(newState);
                        }
                    },
                    insertKeyTermAtPosition: (sid, tid) => {
                        if (activeTargetDocumentKeyTermsState.current) {
                            const newState = new DocumentKeyTermsHolder(activeTargetDocumentKeyTermsState.current.documentKeyTerms);
                            newState.insertKeyTermAtPosition(sid, tid);
                            doStateUpdate.current(newState);
                        }
                    },
                    addDocumentAnnotation: (tns, da) => {
                        if (activeTargetDocumentKeyTermsState.current) {
                            const newState = new DocumentKeyTermsHolder(activeTargetDocumentKeyTermsState.current.documentKeyTerms);
                            newState.addDocumentAnnotation(tns, da);
                            doStateUpdate.current(newState);
                        }
                    },
                    removeDocumentAnnotation: (tn, da) => {
                        if (activeTargetDocumentKeyTermsState.current) {
                            const newState = new DocumentKeyTermsHolder(activeTargetDocumentKeyTermsState.current.documentKeyTerms);
                            newState.removeDocumentAnnotation(tn, da);
                            doStateUpdate.current(newState);
                        }
                    },
                    addHiddenForKeyTermTaggingAnalysis(id, aIdx, h) {
                        if (activeTargetDocumentKeyTermsState.current) {
                            const newState = new DocumentKeyTermsHolder(activeTargetDocumentKeyTermsState.current.documentKeyTerms);
                            newState.addHiddenForKeyTermTaggingAnalysis(id, aIdx, h);
                            doStateUpdate.current(newState);
                        }
                    },
                    markSummaryAddressedForKeyTermAnalysis(id, aIdx) {
                        if (activeTargetDocumentKeyTermsState.current) {
                            const newState = new DocumentKeyTermsHolder(activeTargetDocumentKeyTermsState.current.documentKeyTerms);
                            newState.markSummaryAddressedForKeyTermAnalysis(id, aIdx);
                            doStateUpdate.current(newState);
                        }
                    },
                    addKeyTermSingleSummaryAnalysis(id, a) {
                        if (activeTargetDocumentKeyTermsState.current) {
                            const newState = new DocumentKeyTermsHolder(activeTargetDocumentKeyTermsState.current.documentKeyTerms);
                            newState.addKeyTermSingleSummaryAnalysis(id, a);
                            doStateUpdate.current(newState);
                        }
                    },
                    addKeyTermTaggingSummaryAnalysis(id, a) {
                        if (activeTargetDocumentKeyTermsState.current) {
                            const newState = new DocumentKeyTermsHolder(activeTargetDocumentKeyTermsState.current.documentKeyTerms);
                            newState.addKeyTermTaggingSummaryAnalysis(id, a);
                            doStateUpdate.current(newState);
                        }
                    },
                    addKeyTermTaggingAnalysis(id, a) {
                        if (activeTargetDocumentKeyTermsState.current) {
                            const newState = new DocumentKeyTermsHolder(activeTargetDocumentKeyTermsState.current.documentKeyTerms);
                            newState.addKeyTermTaggingAnalysis(id, a);
                            doStateUpdate.current(newState);
                        }
                    },
                    removeKeyTermAnalysis(id, aId) {
                        if (activeTargetDocumentKeyTermsState.current) {
                            const newState = new DocumentKeyTermsHolder(activeTargetDocumentKeyTermsState.current.documentKeyTerms);
                            newState.removeKeyTermAnalysis(id, aId);
                            doStateUpdate.current(newState);
                        }
                    },
                    acceptAiKeyTerm(id) {
                        if (activeTargetDocumentKeyTermsState.current) {
                            const newState = new DocumentKeyTermsHolder(activeTargetDocumentKeyTermsState.current.documentKeyTerms);
                            newState.acceptAiKeyTerm(id);
                            doStateUpdate.current(newState);
                        }
                    },
                    containsKeyTerm: (tn: string) => {
                        if (activeTargetDocumentKeyTermsState.current) {
                            return activeTargetDocumentKeyTermsState.current.containsKeyTerm(tn);
                        }
                        return false;
                    },
                    bulkUpdateDocumentAnnotations(tnls, ogTnls, das) {
                        if (activeTargetDocumentKeyTermsState.current) {
                            const newState = new DocumentKeyTermsHolder(activeTargetDocumentKeyTermsState.current.documentKeyTerms);
                            for (let i = 0; i < tnls.length; i++) {
                                let termNames = tnls[i];
                                let ogTermNames = ogTnls[i];

                                // remove terms that no longer appear in the list, original - new
                                let removed = [...ogTermNames].filter(tn => !termNames.includes(tn));
                                for (const tn of removed) {
                                    newState.removeDocumentAnnotation(tn, das[i]);
                                }

                                // find new terms appear in the list, new - original
                                let added = [...termNames].filter(tn => !ogTermNames.includes(tn));
                                newState.addDocumentAnnotation(added, das[i]);
                            }
                            doStateUpdate.current(newState);
                        }
                    },
                    createLexicalDocument: (ld: LexicalDocument) => {
                        if (activeTargetDocumentKeyTermsState.current) {
                            const newState = new DocumentKeyTermsHolder(activeTargetDocumentKeyTermsState.current.documentKeyTerms);
                            newState.createLexicalDocument(ld);
                            doSyncUpdate.current = true;
                            doStateUpdate.current(newState);
                        }
                    },
                    removeLexicalDocument: (di) => {
                        if (activeTargetDocumentKeyTermsState.current) {
                            const newState = new DocumentKeyTermsHolder(activeTargetDocumentKeyTermsState.current.documentKeyTerms);
                            newState.removeLexicalDocument(di);
                            doStateUpdate.current(newState);
                        }
                    },
                    getTakerDocumentName(documentId: string) {
                        return documentIdToNameForCurrentGroup[documentId];
                    },
                    getMockKeyTermSummary: (tn: string) => {
                        for (const kt of dummyKeyTermData.keyTerms) {
                            if (kt.termName === tn) {
                                return kt.summary;
                            }
                        }
                    },
                    getTextContentForDocumentHighlights,
                    getTextForAnnotation: (annotationId: string) => {
                        return annotationIdToHighlightedText[annotationId];
                    },
                    setOpenDetailsModal(id: string | undefined) {
                        setOpenDetailsModal(id);
                    },
                    keyTerms: activeTargetDocumentKeyTermsState.current &&
                        activeTargetDocumentKeyTermsState.current.documentKeyTerms.keyTerms,
                    lexicalDocuments: activeTargetDocumentKeyTermsState.current &&
                        activeTargetDocumentKeyTermsState.current.documentKeyTerms.lexicalDocuments,
                    indexedDocumentAnnotations
                } as DocumentKeyTermsService
            }}
        >
            {children}
        </Context.Provider>
    );
};

interface KeyTermGroupStateProps {
    takerDocumentUploadId: string | null;
    children: any;
}

const KeyTermGroupState = ({
    takerDocumentUploadId,
    children
}: KeyTermGroupStateProps) => {
    if (!takerDocumentUploadId) {
        return (
            <>
                {children}
            </>
        );
    }
    return (
        <KeyTermGroupStateInner
            takerDocumentUploadId={takerDocumentUploadId}
            children={children}
        />
    );
}

export default memo(KeyTermGroupState);
