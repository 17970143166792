import React, { useEffect, useMemo, useState, memo, useCallback } from "react";
import {
    Box,
    Grid,
    Typography,
    Toolbar,
    IconButton,
    Skeleton,
} from "@mui/material/";
import { useKeyTermGroupState } from "../../../../containers/TakerDocumentState/KeyTermGroupState";
import { useTakerState } from "../../../../containers/TakerDocumentState/TakerDocumentState";
import { skipToken } from "@reduxjs/toolkit/query";
import {
    useCancelTakerDocumentUploadAnalysisJobMutation,
    useGetTakerDocumentAnalysisPayloadQuery,
    useGetTakerDocumentUploadAnalysisPayloadQuery,
} from "../../../../redux/services/taker";
import { LexicalComposer } from "@lexical/react/LexicalComposer";
import { RichTextPlugin } from "@lexical/react/LexicalRichTextPlugin";
import { ContentEditable } from "@lexical/react/LexicalContentEditable";
import { TablePlugin } from '@lexical/react/LexicalTablePlugin';
import LexicalErrorBoundary from "@lexical/react/LexicalErrorBoundary";
import { HeadingNode } from "@lexical/rich-text";
import { TableCellNode, TableNode, TableRowNode } from "@lexical/table";
import { ListItemNode, ListNode } from "@lexical/list";
import { ListPlugin } from "@lexical/react/LexicalListPlugin";
import EditorThemeClasses from "../../wrappers/RichTextEditor/theme";
import { HistoryPlugin } from "@lexical/react/LexicalHistoryPlugin";
import { TabIndentationPlugin } from "@lexical/react/LexicalTabIndentationPlugin";
import { HorizontalRulePlugin } from "@lexical/react/LexicalHorizontalRulePlugin";
import { ClearEditorPlugin } from "@lexical/react/LexicalClearEditorPlugin";
import TableCellResizerPlugin from "../../wrappers/RichTextEditor/TableCellResizer";
import { KeyTermNode } from "../../nodes/KeyTermNode";
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { $createParagraphNode, $createTextNode, $getRoot } from "lexical";
import { $insertFirst, $insertNodeToNearestRoot } from "@lexical/utils";
import { Check, Close } from "@mui/icons-material";
import { blue } from "@mui/material/colors";
import { useQuery } from '@tanstack/react-query';
import { TakerDocumentAnalysis, TakerDocumentUploadAnalysis } from "../../../../redux/models/dataModelTypes";

import '../../wrappers/RichTextEditor/index.css';

async function fetchTakerDocumentAnalysis(analysisId: string): Promise<TakerDocumentAnalysis> {
    let baseUrl = !!window.__RUNTIME_CONFIG__ ? window.__RUNTIME_CONFIG__.API_ENDPOINT : "localhost";
    let response = await fetch(
        `${baseUrl}/taker_document_analysis/${analysisId}`,
        {
            credentials: 'include',
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        }
    )
    return await response.json();
}

async function fetchTakerDocumentUploadAnalysis(analysisId: string): Promise<TakerDocumentUploadAnalysis> {
    let baseUrl = !!window.__RUNTIME_CONFIG__ ? window.__RUNTIME_CONFIG__.API_ENDPOINT : "localhost";
    let response = await fetch(
        `${baseUrl}/taker_document_upload_analysis/${analysisId}`,
        {
            credentials: 'include',
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        }
    )
    return await response.json();
}

interface SyncSummaryEditorPluginProps {
    value: string | undefined;
}

const SyncSummaryPlugin = ({ value }: SyncSummaryEditorPluginProps) => {
    const [editor] = useLexicalComposerContext();

    useEffect(() => {
        const isEmpty = (value === undefined || value === "");
        if (!isEmpty) {
            const currentStringValue = JSON.stringify(editor.getEditorState().toJSON());
            const incomingValueDiffers = currentStringValue !== value;
            if (incomingValueDiffers) {
                try {
                    // Update editor's editorState directly
                    const editorState = editor.parseEditorState(value);
                    if (!editorState.isEmpty()) {
                        editor.setEditorState(editorState);
                    }
                } catch (e) {
                    // Update editor's editorState with a plain text input
                    editor.update(
                        () => {
                            // Remove existing content
                            const root = $getRoot();
                            root.clear();

                            // Insert a single paragraph with text
                            const paragraph = $createParagraphNode();
                            $insertFirst(paragraph, $createTextNode(value));
                            $insertNodeToNearestRoot(paragraph);
                        }
                    );
                }
            }
        }
    }, [value, editor]);

    return null;
};

export interface AiGeneratedSummaryComponentMetadata {
    ogKeyTermName?: string;
};

interface AiGeneratedSummaryComponentProps {
    analysisId: string;
    demoData?: string;
    onRemove: (withAccept: boolean, summary?: string) => void;
    onJobDone: () => void;
    onJobPending: () => void;
    acceptAiGeneratedResultsFlag: boolean;
    rejectAiGeneratedResultsFlag: boolean;
    payloadType: "DOCUMENT" | "DOCUMENT_UPLOAD";
    metadata: AiGeneratedSummaryComponentMetadata;
}

const AiGeneratedSummaryComponent = ({
    analysisId,
    demoData,
    onRemove,
    onJobDone,
    onJobPending,
    acceptAiGeneratedResultsFlag,
    rejectAiGeneratedResultsFlag,
    payloadType,
    metadata
}: AiGeneratedSummaryComponentProps) => {
    const { 
        takerDocumentId, 
        takerPermissionState,
        activeTakerDocument,
        takerDocumentAnalysesInProgress,
        takerDocumentUploadAnalysesInProgress,
    } = useTakerState();
    const { takerDocumentUpload } = useKeyTermGroupState();
    const [cancelJob, cancelJobResult] = useCancelTakerDocumentUploadAnalysisJobMutation();

    const [pendingGeneration, setPendingGeneration] = useState<boolean | undefined>(undefined);
    const [failureMessage, setFailureMessage] = useState<string | undefined>(undefined);
    const [localSummary, setLocalSummary] = useState<string>();

    const [lastAcceptAiGeneratedResultsFlag, setLastAcceptAiGeneratedResultsFlag] = useState<boolean>(acceptAiGeneratedResultsFlag);
    const [lastRejectAiGeneratedResultsFlag, setLastRejectAiGeneratedResultsFlag] = useState<boolean>(rejectAiGeneratedResultsFlag);

    const shouldSkipDocumentAnalysis = (!analysisId || analysisId === "fake" || payloadType === "DOCUMENT_UPLOAD");
    const shouldSkipDocumentUploadAnalysis = (!analysisId || analysisId === "fake" || payloadType === "DOCUMENT");

    const isTakerDocumentAnalysisInProgress = takerDocumentAnalysesInProgress.includes(analysisId);
    const isTakerDocumentUploadAnalysisInProgress = takerDocumentUploadAnalysesInProgress.includes(analysisId);

    const {
        data: tduAnalysisPayload,
        isSuccess: tduAnalysisPayloadSuccess,
        refetch: tduAnalysisPayloadRefetch
    } = useGetTakerDocumentUploadAnalysisPayloadQuery(
        shouldSkipDocumentUploadAnalysis ? skipToken : analysisId
    );

    const {
        data: tdAnalysisPayload,
        isSuccess: tdAnalysisPayloadSuccess,
        refetch: tdAnalysisPayloadRefetch
    } = useGetTakerDocumentAnalysisPayloadQuery(
        shouldSkipDocumentAnalysis ? skipToken : analysisId
    );

    let isSuccess: boolean | undefined = undefined;
    if (payloadType === "DOCUMENT_UPLOAD") {
        isSuccess = tduAnalysisPayloadSuccess;
    } else if (payloadType === "DOCUMENT") {
        isSuccess = tdAnalysisPayloadSuccess;
    }

    let analysisPayload: any = undefined;
    if (payloadType === "DOCUMENT_UPLOAD") {
        analysisPayload = tduAnalysisPayload;
    } else if (payloadType === "DOCUMENT") {
        analysisPayload = tdAnalysisPayload;
    }

    useEffect(() => {
        if (cancelJobResult.isSuccess) {
            onRemove(false);
        }
    }, [cancelJobResult.isSuccess]);

    const refreshAnalysisPayload = useCallback(() => {
        if (payloadType === "DOCUMENT_UPLOAD") {
            tduAnalysisPayloadRefetch();
        } else if (payloadType === "DOCUMENT") {
            tdAnalysisPayloadRefetch();
        }
    }, [
        payloadType,
        tduAnalysisPayloadRefetch,
        tdAnalysisPayloadRefetch
    ]);

    useEffect(() => {
        if (lastAcceptAiGeneratedResultsFlag !== acceptAiGeneratedResultsFlag) {
            if (!isSuccess) {
                return;
            }
            onRemove(true, localSummary);
            setLastAcceptAiGeneratedResultsFlag(acceptAiGeneratedResultsFlag);
        }
    }, [
        isSuccess,
        localSummary,
        acceptAiGeneratedResultsFlag
    ]);

    useEffect(() => {
        if (lastRejectAiGeneratedResultsFlag !== rejectAiGeneratedResultsFlag) {
            if (!isSuccess) {
                return;
            }
            onRemove(false);
            setLastRejectAiGeneratedResultsFlag(rejectAiGeneratedResultsFlag);
        }
    }, [
        isSuccess, 
        rejectAiGeneratedResultsFlag
    ]);

    const isOwner = takerPermissionState.allGrants.includes("OWNER");

    const matchingSingleGenerationAnalysis = useMemo(() => {
        return takerDocumentUpload?.singleKeyTermsTakerDocumentUploadAnalyses?.find(
            (analysis) => (analysis.id === analysisId)
        );
    }, [takerDocumentUpload]);

    const matchingTaggingSummaryAnalysis = useMemo(() => {
        return takerDocumentUpload?.taggingAndSummarizationTakerDocumentUploadAnalyses.find(
            (analysis) => (analysis.id === analysisId)
        );
    }, [takerDocumentUpload]);

    const matchingExecutiveSummaryAnalysis = useMemo(() => {
        return activeTakerDocument?.executiveSummaryTakerDocumentAnalyses.find(
            (analysis) => (analysis.id === analysisId)
        );
    }, [activeTakerDocument]);

    let isTda = false;
    let isTdua = false;
    let tda: TakerDocumentAnalysis | undefined = undefined;
    let tdua: TakerDocumentUploadAnalysis | undefined = undefined;
    if (matchingSingleGenerationAnalysis) {
        isTdua = true;
        tdua = matchingSingleGenerationAnalysis;
    } else if (matchingTaggingSummaryAnalysis) {
        isTdua = true;
        tdua = matchingTaggingSummaryAnalysis;
    } else if (matchingExecutiveSummaryAnalysis) {
        isTda = true;
        tda = matchingExecutiveSummaryAnalysis;
    }

    const {
        data: latestTakerDocumentAnalysis,
    } = useQuery({
        queryKey: ['TakerDocumentAnalysis', analysisId],
        queryFn: async () => await fetchTakerDocumentAnalysis(analysisId),
        initialData: tda,
        refetchInterval: (isTakerDocumentAnalysisInProgress ? 1000 : false),
        enabled: isTda
    });

    const {
        data: latestTakerDocumentUploadAnalysis,
    } = useQuery({
        queryKey: ['TakerDocumentUploadAnalysis', analysisId],
        queryFn: async () => await fetchTakerDocumentUploadAnalysis(analysisId),
        initialData: tdua,
        refetchInterval: (isTakerDocumentUploadAnalysisInProgress ? 1000 : false),
        enabled: isTdua
    });

    useEffect(() => {
        let isMounted = true;

        // this is a single analysis generation
        if (latestTakerDocumentUploadAnalysis?.type === "SINGLE_SUMMARY") {
            // If the analysis is still pending generation
            if (latestTakerDocumentUploadAnalysis.state === "PENDING_GENERATION") {
                setPendingGeneration(true);
                onJobPending();
            } else if (latestTakerDocumentUploadAnalysis.state === "PENDING_REVIEW") {
                setPendingGeneration(false);
                refreshAnalysisPayload();
                onJobDone();
            } else if (latestTakerDocumentUploadAnalysis.state === "PENDING_GENERATION_CANCELED") {
                let msg = latestTakerDocumentUploadAnalysis.data['job_failure'];
                if (!msg) {
                    msg = "The generation was canceled or something went wrong.";
                }
                setFailureMessage(msg);
            }
        } else if (latestTakerDocumentUploadAnalysis?.type === "ISOLATED_TAGGING_AND_SUMMARIZATION") {
            // If the analysis is still pending generation
            if (latestTakerDocumentUploadAnalysis.state === "PENDING_GENERATION") {
                setPendingGeneration(true);
                onJobPending();
            } else if (latestTakerDocumentUploadAnalysis.state === "PENDING_REVIEW") {
                setPendingGeneration(false);
                refreshAnalysisPayload();
                onJobDone();
            } else if (latestTakerDocumentUploadAnalysis.state === "PENDING_GENERATION_CANCELED") {
                let msg = latestTakerDocumentUploadAnalysis.data['job_failure'];
                if (!msg) {
                    msg = "The generation was canceled or something went wrong.";
                }
                setFailureMessage(msg);
            }
        } else if (latestTakerDocumentAnalysis?.type === "EXECUTIVE_SUMMARY") {
            if (latestTakerDocumentAnalysis.state === "PENDING_GENERATION") {
                setPendingGeneration(true);
                onJobPending();
            } else if (latestTakerDocumentAnalysis.state === "PENDING_REVIEW") {
                setPendingGeneration(false);
                refreshAnalysisPayload();
                onJobDone();
            } else if (latestTakerDocumentAnalysis.state === "PENDING_GENERATION_CANCELED") {
                let msg = latestTakerDocumentAnalysis.data['job_failure'];
                if (!msg) {
                    msg = "The generation was canceled or something went wrong.";
                }
                setFailureMessage(msg);
            }
        } else if (demoData) {
            // This is a demo generation.
            // Set as if we are done generating and then start the animation sequence.
            setPendingGeneration(false);
            onJobPending();

            const words = demoData.split(' ');
            for (let i = 0; i < words.length; i++) {
                setTimeout(() => {
                    if (isMounted) {
                        // Replace first child, remove the addition children
                        const contatedTokens = words.slice(0, i + 1).join(' ');
                        setLocalSummary(contatedTokens);
                    }
                }, (300 * (i + 1)));
            }
            setTimeout(() => {
                if (isMounted) {
                    onJobDone();
                }
            }, (300 * words.length));
        }

        return () => {
            isMounted = false;
        }
    }, [
        demoData,
        latestTakerDocumentAnalysis,
        latestTakerDocumentUploadAnalysis
    ]);

    useEffect(() => {
        if (!isSuccess) {
            return;
        }

        if ((latestTakerDocumentUploadAnalysis?.type === "SINGLE_SUMMARY") && analysisPayload) {
            setLocalSummary(analysisPayload.summary);
        } else if ((latestTakerDocumentUploadAnalysis?.type === "ISOLATED_TAGGING_AND_SUMMARIZATION") && analysisPayload && metadata.ogKeyTermName) {
            if (analysisPayload['summaries']) {
                const summary = (analysisPayload['summaries'] as Record<string, string>)[metadata.ogKeyTermName];
                if (summary) {
                    setLocalSummary(summary);
                }
            }
        } else if ((latestTakerDocumentAnalysis?.type === "EXECUTIVE_SUMMARY") && analysisPayload) {
            setLocalSummary(analysisPayload.summary);
        }
    }, [
        latestTakerDocumentAnalysis,
        latestTakerDocumentUploadAnalysis,
        isSuccess,
        analysisPayload,
        metadata
    ]);

    return (
        <Box
            marginBottom={1}
            padding={0}
            sx={{
                backgroundColor: blue[50],
                borderRadius: 2,
                border: `2px solid ${blue[400]}`,
            }}
        >
            <Grid
                container
            >
                <Grid item xs={12}>
                    <Toolbar
                        variant="dense"
                        sx={{
                            justifyContent: "space-between",
                            alignItems: "center",
                        }}
                    >
                        <Box display="flex" alignItems="center">
                            <Typography variant="subtitle1">
                                AI Summary
                            </Typography>
                        </Box>
                        {(isOwner && pendingGeneration === false) && (
                            <Box>
                                <IconButton
                                    size="small"
                                    color="success"
                                    disabled={failureMessage !== undefined}
                                    onClick={() => onRemove(true, localSummary)}
                                >
                                    <Check />
                                </IconButton>
                                <IconButton
                                    size="small"
                                    color="error"
                                    onClick={() => onRemove(false)}
                                >
                                    <Close />
                                </IconButton>
                            </Box>
                        )}
                        {(isOwner && pendingGeneration === true) && (
                            <Box>
                                <IconButton
                                    size="small"
                                    color="primary"
                                    onClick={() => cancelJob({
                                        id: analysisId,
                                        takerDocumentId: takerDocumentId
                                    })}
                                >
                                    <Close />
                                </IconButton>
                            </Box>
                        )}
                    </Toolbar>
                </Grid>
                <Grid
                    item
                    xs={12}
                    paddingBottom={1}
                >
                    {pendingGeneration === true && (
                        <Box
                            paddingLeft={1}
                            paddingRight={1}
                            width="100%"
                        >
                            <Skeleton
                                animation="wave"
                                variant="rectangular"
                                width="100%"
                                height="35px"
                            />
                        </Box>
                    )}
                    {(pendingGeneration === false && failureMessage === undefined) && (
                        <LexicalComposer
                            initialConfig={{
                                editable: false,
                                namespace: `ai-generated-summary-${analysisId}`,
                                theme: EditorThemeClasses,
                                onError(error: any) {
                                    console.error(error);
                                },
                                nodes: [
                                    HeadingNode,
                                    ListNode,
                                    ListItemNode,
                                    TableNode,
                                    TableCellNode,
                                    TableRowNode,
                                    KeyTermNode
                                ]
                            }}
                        >
                            <RichTextPlugin
                                contentEditable={
                                    <div className="editor-scroller">
                                        <div className="editor">
                                            <ContentEditable
                                                className="editor-input"
                                                style={{
                                                    paddingTop: '10px',
                                                    paddingBottom: '0px',
                                                    minHeight: '25px'
                                                }}
                                            />
                                        </div>
                                    </div>
                                }
                                placeholder={<div className="editor-placeholder"></div>}
                                ErrorBoundary={LexicalErrorBoundary}
                            />
                            <SyncSummaryPlugin value={localSummary} />
                            <ListPlugin />
                            <HistoryPlugin />
                            <TabIndentationPlugin />
                            <HorizontalRulePlugin />
                            <ClearEditorPlugin />
                            <TablePlugin hasCellMerge />
                            <TableCellResizerPlugin />
                        </LexicalComposer>
                    )}
                    {(pendingGeneration === false && failureMessage !== undefined) && (
                        <Box
                            paddingLeft={1}
                            paddingRight={1}
                            width="100%"
                        >
                            <Typography>
                                <i>{failureMessage}</i>
                            </Typography>
                        </Box>
                    )}
                </Grid>
            </Grid>
        </Box>
    );
};

export default memo(AiGeneratedSummaryComponent);