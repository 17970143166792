import React, { useContext, PropsWithChildren, useState, useEffect, useMemo, memo } from 'react';
import { useNavigate } from 'react-router-dom';
import { pageOption } from '../../data/pages';
import { useGetCurrentApplicationQuery } from '../../redux/services/application';
import { useGetFeatureFlagFileQuery } from '../../redux/services/staticFile';
import { FlagProvider } from './FlagProvider';
import { useSelector } from '../../redux/reduxUtils/functions';
import { RootReducerType } from '../../redux/models/reduxTypes';
import { useSnackbar } from 'notistack';
import { FeatureFlags } from '../../types/featureflags.generated';
import { useLocalStorage } from '@uidotdev/usehooks';

interface UserScopedAppDataHookData {
    selectedOrgId: string;
    setSelectedOrgId: (oid: string) => void;
    flagProvider: FlagProvider;
    isDebugger: boolean;
}

type UserScopedAppDataHook = () => UserScopedAppDataHookData;

const Context = React.createContext({});

export const useUserScopedAppData: UserScopedAppDataHook = () => {
    return useContext(Context) as UserScopedAppDataHookData;
}

interface UserScopedAppDataContainerProps {
    featureFlagDefaultOverrides?: FeatureFlags;
    children: React.ReactNode;
}

const UserScopedAppDataContainer: React.FC<PropsWithChildren<UserScopedAppDataContainerProps>> = ({
    featureFlagDefaultOverrides = {},
    children
}: UserScopedAppDataContainerProps) => {
    const navigate = useNavigate();
    const { user } = useSelector((state: RootReducerType) => state.auth);
    const { data: applicationList } = useGetCurrentApplicationQuery();
    const { data: featureFlagSchema } = useGetFeatureFlagFileQuery();
    const [orgId, setOrgId] = useLocalStorage<string>('orgid', '');

    const isDebugger = useMemo(() => !!user?.roles.includes('DEBUGGER'), [user]);

    useEffect(() => {
        if (orgId === '' && user?.organizationMemberships) {        
            if (user.organizationMemberships.length == 0 && !isDebugger) {
                // redirect to error page, TODO: redirect to more informative page
                navigate(pageOption.AUTH0_ERROR.route);
            } else {
                // get earliest membership
                let sortedMemberships = [...user.organizationMemberships]
                sortedMemberships.sort((a, b) => a.createdAt - b.createdAt);
                if (sortedMemberships.length !== 0) {
                    setOrgId(sortedMemberships[0].organization.id);
                }
            }
        }
    }, [
        isDebugger,
        user, 
        orgId
    ]);

    const flagProvider = useMemo(() => {
        const provider = new FlagProvider(orgId)

        // Set Global Defaults
        if (featureFlagSchema) {
            provider.setDefaultFlagValues(featureFlagSchema);
        }

        // Set the flag values specified by application configs
        if (applicationList) {
            provider.setConfigurationEntriesForApplicationTags(applicationList);
        }

        // Set any overrides we may pass in directly (for testing)
        if (featureFlagDefaultOverrides) {
            provider.setPartialDefaultFlagValues(featureFlagDefaultOverrides);
        }

        return provider;
    }, [
        applicationList, 
        orgId, 
        featureFlagSchema, 
        featureFlagDefaultOverrides
    ]);

    return (
        <Context.Provider
            value={{
                selectedOrgId: orgId,
                setSelectedOrgId: (oid: string) => {
                    setOrgId(oid);
                    navigate(pageOption.HOME.route);
                },
                flagProvider,
                isDebugger
            }}>
            {children}
        </Context.Provider>
    );
};

export default memo(UserScopedAppDataContainer);