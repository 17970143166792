import React, { useEffect, useState, memo } from 'react';
import { Box, Button, Divider, Stack, TextField } from "@mui/material";
import { useResearchPanelData } from '../../../../containers/ResearchPanelData/ResearchPanelData';
import { Send } from '@mui/icons-material';
import { useDebounce } from '@uidotdev/usehooks';
import { useAddAdvisorGenerationMutation } from '../../../../redux/services/user';
import { useUserScopedAppData } from '../../../../containers/UserScopedAppData/UserScopedAppData';
import { useQueryClient } from '@tanstack/react-query'
import CustomFlowFilter from './CustomFlowFilter';

const CustomUserFlow = () => {
    const { selectedOrgId } = useUserScopedAppData();
    const {
        researchPanelAdvisorState,
        setResearchPanelAdvisorState,
        applyLiteratureFilter,
        activeGuidanceIds
    } = useResearchPanelData();
    const [
        addAdvisorGeneration,
        addAdvisorGenerationResult
    ] = useAddAdvisorGenerationMutation();
    const queryClient = useQueryClient();

    const [localQuery, setLocalQuery] = useState(researchPanelAdvisorState.customState?.query);
    const debouncedLocalQuery = useDebounce(localQuery, 250);

    useEffect(() => {
        if (researchPanelAdvisorState.customState === undefined) {
            return;
        }

        setResearchPanelAdvisorState(prev => ({
            ...researchPanelAdvisorState,
            customState: {
                ...researchPanelAdvisorState.customState,
                query: debouncedLocalQuery
            }
        }));
    }, [
        debouncedLocalQuery
    ]);

    useEffect(() => {
        if (addAdvisorGenerationResult.isSuccess) {
            setLocalQuery("");
            queryClient.invalidateQueries({
                queryKey: ['UserAnalyses']
            });
        }
    }, [addAdvisorGenerationResult]);

    return (
        <Stack
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            border="1px solid lightgray"
            borderRadius={1}
            marginBottom={0.5}
            divider={
                <Divider
                    sx={{
                        marginLeft: 1,
                        marginRight: 1
                    }}
                    orientation="horizontal"
                    flexItem
                />
            }
        >
            <TextField
                value={localQuery}
                onChange={(e) => {
                    setLocalQuery(e.target.value);
                }}
                placeholder="Type request here..."
                variant="standard"
                size="small"
                fullWidth
                multiline
                maxRows={4}
                disabled={addAdvisorGenerationResult.isLoading}
                inputProps={{
                    sx: { padding: 1 }
                }}
                InputProps={{
                    disableUnderline: true,
                    autoComplete: 'off'
                }}
            />
            <Box
                width="100%"
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                padding={1}
            >
                <CustomFlowFilter />
                <Button
                    color="primary"
                    size="small"
                    variant="contained"
                    disabled={addAdvisorGenerationResult.isLoading || localQuery === ""}
                    sx={{ margin: 0 }}
                    onClick={() => {
                        if (researchPanelAdvisorState.customState === undefined) {
                            return;
                        }

                        addAdvisorGeneration({
                            organizationId: selectedOrgId,
                            transformType: "CUSTOM",
                            customQuery: localQuery,
                            guidanceFilter: (applyLiteratureFilter ? activeGuidanceIds : [])
                        });
                    }}
                    endIcon={<Send />}
                >
                    Send
                </Button>
            </Box>
        </Stack>
    );
};

export default memo(CustomUserFlow);

