import React, { useMemo, memo } from 'react';
import { Handle, Position, NodeProps, NodeResizer } from '@xyflow/react';
import { Box, IconButton, Typography } from '@mui/material';
import { Add, Dataset, Edit } from '@mui/icons-material';
import { useBuilderData } from './context';
import { ResizableNodeType } from './common';

const HEADER_HEIGHT = 32;

/**
 * A custom node component that is resizable.
 */
const ResizableNode = ({ data, selected }: NodeProps<ResizableNodeType>) => {
  const {
    builderHolder,
    startInsertIntoModule,
    startEditDataForModule,
    startEditNode,
    startEditModule,
    startEditTableModule
  } = useBuilderData();

  const targetNode = useMemo(() => {
    const module = builderHolder.getModule([...data.moduleIds]);
    if (!module || module.uiFulfillment?.fulfillmentType !== "graph") {
      return;
    }
    return module.uiFulfillment.nodes?.find((n) => n.id === data.nodeId);
  }, [
    builderHolder,
    data.moduleIds,
    data.nodeId
  ]);

  const optonalReferenceModule = useMemo(() => {
    if (data.nodeType === "module" && targetNode?.nodeType === "module") {
      return builderHolder.getModule([...data.moduleIds, targetNode.moduleId]);
    }
  }, [
    builderHolder,
    targetNode,
    data.moduleIds,
    data.nodeId
  ]);

  const canInsertIntoModule = useMemo(() => {
    if (data.nodeType === "question" || data.nodeType === "end") {
      return false;
    }

    // only return true if the module is a graph
    return (
      optonalReferenceModule?.uiFulfillment?.fulfillmentType === "graph"
      && optonalReferenceModule?.dataSpec?.dataSpecType === "basic"
    );
  }, [
    optonalReferenceModule,
    data.nodeType
  ]);

  const canEditDataForModule = useMemo(() => {
    if (data.nodeType === "question" || data.nodeType === "end") {
      return false;
    }
    return !(
      optonalReferenceModule?.uiFulfillment?.fulfillmentType === "graph"
      && optonalReferenceModule?.dataSpec?.dataSpecType === "basic-table"
    );
  }, [
    optonalReferenceModule,
    data.nodeType
  ]);

  const canEdit = useMemo(() => {
    if (data.nodeType === "question" || data.nodeType === "end") {
      return true;
    }

    return !(
      optonalReferenceModule?.uiFulfillment?.fulfillmentType === "graph"
      && optonalReferenceModule?.dataSpec?.dataSpecType === "basic-table"
    );
  }, [
    optonalReferenceModule,
    data.nodeType
  ]);

  const headerText = useMemo(() => {
    // for tables we show this like a question
    if (optonalReferenceModule && optonalReferenceModule.uiFulfillment?.fulfillmentType === "table") {
      return optonalReferenceModule.uiFulfillment.tableLabel;
    } else if (optonalReferenceModule?.uiFulfillment?.fulfillmentType === "graph" && optonalReferenceModule?.dataSpec?.dataSpecType === "basic") {
      return optonalReferenceModule?.displayName || optonalReferenceModule.id;
    } else if (targetNode?.nodeType === "question") {
      return targetNode?.questionLabel || 'Question';
    } else if (targetNode?.nodeType === "end") {
      return targetNode.label || 'Conclusion';
    }
    return '';
  }, [
    optonalReferenceModule,
    targetNode
  ]);

  const bodyText = useMemo(() => {
    // for tables we show this like a question
    if (optonalReferenceModule && optonalReferenceModule.uiFulfillment?.fulfillmentType === "table") {
      return (
        <Typography variant="body2">
          {optonalReferenceModule.uiFulfillment.tableText?.replace(/\{(.*?)\}/g, "<variable>")}
        </Typography>
      );
    } else if (
      optonalReferenceModule?.uiFulfillment?.fulfillmentType === "graph"
      && optonalReferenceModule.dataSpec?.dataSpecType === "basic-table"
    ) {
      return (
        <Typography variant="body2" textAlign='center'>
          {optonalReferenceModule.displayName}
        </Typography>
      );
    } else if (targetNode?.nodeType === "question") {
      // Show question text, truncated or not
      return (
        <Typography variant="body2" >
          {targetNode.questionText.replace(/\{(.*?)\}/g, "<variable>")}
        </Typography>
      );
    } else if (targetNode?.nodeType === "end") {
      // Possibly show node.statement
      return (
        <Typography
          variant="body2"
          textAlign='center'
          alignItems='center'
        >
          {targetNode.statement?.replace(/\{(.*?)\}/g, "<variable>")}
        </Typography>
      );
    }
    return null;
  }, [
    optonalReferenceModule,
    targetNode
  ]);

  return (
    <>
      <NodeResizer />
      <div
        style={{
          width: '100%',
          height: '100%',
          border: selected ? '3px solid blue' : '2px solid #ddd',
          background: '#fff',
        }}
      >
        {/* Header bar */}
        {!(optonalReferenceModule?.uiFulfillment?.fulfillmentType === "graph" && optonalReferenceModule.dataSpec?.dataSpecType === "basic-table") && (
          <Box
            sx={{
              height: HEADER_HEIGHT,
              display: 'flex',
              alignItems: 'center',
              px: 1
            }}
          >
            <Typography
              variant="subtitle2"
              noWrap
              sx={{ fontWeight: 'bold', fontSize: '0.8rem', paddingLeft: 1, paddingRight: 1 }}
            >
              {headerText}
            </Typography>
            <Box
              display="flex"
              justifyContent="flex-end"
              sx={{ flexGrow: 1 }}
            >
              {/* Only visible for modules that can have nested elements (graphs) */}
              {canInsertIntoModule && (
                <IconButton
                  size="small"
                  onClick={() => {
                    if (!optonalReferenceModule) {
                      return;
                    }
                    startInsertIntoModule([...data.moduleIds, optonalReferenceModule.id])
                  }}
                >
                  <Add />
                </IconButton>
              )}

              {/* only visibile for modules */}
              {canEditDataForModule && (
                <IconButton
                  size="small"
                  onClick={() => {
                    if (!optonalReferenceModule) {
                      return;
                    }
                    startEditDataForModule([...data.moduleIds, optonalReferenceModule.id]);
                  }}
                >
                  <Dataset />
                </IconButton>
              )}

              {/* Should behave differently for a question/end vs module node */}
              {canEdit && (
                <IconButton
                  size="small"
                  onClick={() => {
                    if ((data.nodeType === "question" || data.nodeType === "end")
                      && data.nodeId) {
                      startEditNode([...data.moduleIds], data.nodeId);
                    } else if (data.nodeType === "module" && optonalReferenceModule) {
                      if (optonalReferenceModule.uiFulfillment?.fulfillmentType === "table") {
                        startEditTableModule([...data.moduleIds, optonalReferenceModule.id]);
                      } else {
                        startEditModule([...data.moduleIds, optonalReferenceModule.id]);
                      }
                    }
                  }}
                >
                  <Edit />
                </IconButton>
              )}
            </Box>
          </Box>
        )}

        {/* Body section */}
        <Box
          sx={{
            flex: 1,
            p: 1,
            whiteSpace: "pre-wrap"
          }}
        >
          {bodyText}
        </Box>
      </div>
      {/* Connection handles for React Flow */}
      <Handle type="source" position={Position.Bottom} id="a" />
      <Handle type="target" position={Position.Top} id="b" />
    </>
  );
};

export default memo(ResizableNode);
