import React, { useEffect, useRef, memo, useState } from 'react'
import { useInfiniteQuery } from '@tanstack/react-query'
import { useVirtualizer } from '@tanstack/react-virtual'
import { useUserScopedAppData } from '../../../containers/UserScopedAppData/UserScopedAppData';
import { UserAnalysis } from '../../../redux/models/dataModelTypes';
import AdvisorUserResponse from './AdvisorUserResponse';
import { Box } from '@mui/material';
import { blue } from '@mui/material/colors';
import RectangularLoading from '../../loading/RectangularLoading';
import { useLocation } from 'react-router-dom';
import { useResearchPanelData } from '../../../containers/ResearchPanelData/ResearchPanelData';
import { IdToGuidanceInfo } from './types';

async function fetchServerPage(
    organizationId: string,
    limit: number,
    page: number = 0,
): Promise<{
    rows: Array<UserAnalysis>;
    nextPage: number;
}> {
    let baseUrl = !!window.__RUNTIME_CONFIG__ ? window.__RUNTIME_CONFIG__.API_ENDPOINT : "localhost";
    let response = await fetch(
        `${baseUrl}/user_analyses?organization_id=${organizationId}&page=${page}&limit=${limit}`,
        {
            credentials: 'include',
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        }
    )

    let jsonResponse = await response.json();
    let hasNext = jsonResponse["metadata"]["has_next"];
    return {
        rows: jsonResponse["data"],
        nextPage: hasNext ? (page + 1) : -1
    };
}

function AdvisorUserResponses() {
    const { selectedOrgId } = useUserScopedAppData();
    const { hash } = useLocation();
	const {
        guidanceSearchInput,
        setGuidanceSearchInput 
    } = useResearchPanelData();
    const {
        status,
        data,
        error,
        isFetchingNextPage,
        fetchNextPage,
        hasNextPage,
    } = useInfiniteQuery({
        queryKey: ['UserAnalyses', selectedOrgId],
        queryFn: (ctx) => fetchServerPage(selectedOrgId, 20, ctx.pageParam),
        getNextPageParam: (lg) => lg.nextPage === -1 ? undefined : lg.nextPage,
        initialPageParam: 0,
        refetchOnReconnect: true
    });

    const userAnalyses = data ? data.pages.flatMap((d) => d.rows) : [];

    const parentRef = useRef<HTMLDivElement>(null)

    const rowVirtualizer = useVirtualizer({
        count: hasNextPage ? userAnalyses.length + 1 : userAnalyses.length,
        getScrollElement: () => parentRef.current,
        estimateSize: () => 100,
        overscan: 10
    });

    const [idToGuidanceInfo, setIdToGuidanceInfo] = useState<IdToGuidanceInfo>({});

	useEffect(() => {
        // parse _gcid parameter out of the hash
        if (hash.indexOf("_gcid") !== -1) {
            const gcid = hash.substring(hash.indexOf("_gcid") + 6);
            const info = idToGuidanceInfo[gcid];
            if (!info) {
                return;
            }

            if (guidanceSearchInput === info.contentAlias) {
                return;
            }
            setGuidanceSearchInput(info.contentAlias);
        }
    }, [hash]);	

    useEffect(() => {
        const [lastItem] = [...rowVirtualizer.getVirtualItems()].reverse()
        if (!lastItem) {
            return
        }

        if (
            lastItem.index >= userAnalyses.length - 1 &&
            hasNextPage &&
            !isFetchingNextPage
        ) {
            fetchNextPage()
        }
    }, [
        hasNextPage,
        fetchNextPage,
        userAnalyses.length,
        isFetchingNextPage,
        rowVirtualizer.getVirtualItems(),
    ]);

    const items = rowVirtualizer.getVirtualItems();

    return (
        <Box
            flexGrow={1}
            overflow="hidden"
            width="100%"
            sx={{
                backgroundColor: 'white',
                border: `2px solid ${blue[200]}`,
                borderRadius: 1,
            }}
        >
            {status === 'pending' ? (
                <RectangularLoading />
            ) : status === 'error' ? (
                <span>Error: {error.message}</span>
            ) : (
                <div
                    ref={parentRef}
                    style={{
                        height: '100%',
                        width: '100%',
                        overflowY: 'auto',
                    }}
                >
                    <div
                        style={{
                            height: `${rowVirtualizer.getTotalSize()}px`,
                            width: '100%',
                            position: 'relative',
                        }}
                    >
                        <div
                            style={{
                                position: 'absolute',
                                top: 0,
                                left: 0,
                                width: '100%',
                                transform: `translateY(${items[0]?.start ?? 0}px)`,
                            }}
                        >
                            {items.map((virtualRow) => {
                                const isLoaderRow = virtualRow.index > userAnalyses.length - 1
                                const userAnalysis = userAnalyses[virtualRow.index];
                                return (
                                    <Box
                                        key={virtualRow.index}
                                        padding={1}
                                        component="div"
                                        data-index={virtualRow.index}
                                        ref={rowVirtualizer.measureElement}
                                    >
                                        {isLoaderRow ? (
                                            hasNextPage ? 'Loading more...' : 'Nothing more to load'
                                        ) : (
                                            <AdvisorUserResponse 
                                                key={userAnalysis.id} 
                                                userAnalysis={userAnalysis} 
                                                isFirstIndex={virtualRow.index === 0}
                                                onUpdateMetadata={(m) => {
                                                    if (!m?.used_guidance_list || !m?.link_replacement_lookup) {
                                                        return;
                                                    }

                                                    const contentIdToGuidanceInfo: IdToGuidanceInfo = {};
                                                    for (const g of m.used_guidance_list) {
                                                        contentIdToGuidanceInfo[g.content_id] ={ 
                                                            contentId: g.content_id, 
                                                            contentAlias: g.content_alias,
                                                            guidanceId: g.guidance_id,
                                                        };
                                                    }

                                                    const map: IdToGuidanceInfo = {};
                                                    for (const [key, value] of Object.entries(m.link_replacement_lookup)) {
                                                        map[key] = contentIdToGuidanceInfo[value];
                                                    }
                                                    setIdToGuidanceInfo(prev => ({
                                                        ...prev,
                                                        ...map
                                                    }));
                                                }}
                                            />
                                        )}
                                    </Box>
                                )
                            })}
                        </div>
                    </div>
                </div>
            )}
        </Box>
    )
}

export default memo(AdvisorUserResponses);
