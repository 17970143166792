import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { mergeRegister } from '@lexical/utils';
import { LexicalEditor } from 'lexical';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { createPortal } from 'react-dom';
import { DocumentAnnotation } from '../../../../types/taker/documentkeyterms.generated';
import { usePdfHighlighter } from '../../../pdfHighlighter/context';

import './index.css';

function useFloatingTextToolbar(
  editor: LexicalEditor,
  anchorElem: HTMLElement,
  annotations: DocumentAnnotation[],
  isV2Render: boolean,
  lexicalDocumentIdentifier: string,
  pageIndex: number
): JSX.Element | null {
  const {
    injectAdditionFloatingToolbar,
    injectEditFloatingToolbar
  } = usePdfHighlighter();

  const [isHighlightingText, setIsHighlightingText] = useState(false);
  const [selectedElementId, setSelectedElementId] = useState<string>();

  const annotatedElementIdSet = useMemo(
    () => new Set(annotations.flatMap(da => da.documentHighlights.map(dh => dh.elementId))),
    [annotations]
  );

  const updatePopup = useCallback(() => {
    editor.getEditorState().read(() => {
      // Should not to pop up the floating toolbar when using IME input
      if (editor.isComposing()) {
        return;
      }
      
      const nativeSelection = window.getSelection();
      const rootElement = editor.getRootElement();

      if (
        (nativeSelection !== null && (rootElement === null || !rootElement.contains(nativeSelection.anchorNode))) 
        || nativeSelection === null
      ) {
        setSelectedElementId(undefined);
        setIsHighlightingText(false);
        return;
      }

      let isRangeSelection = nativeSelection.type === "Range";
      let isCaretSelection = nativeSelection.type === "Caret";
      if (isCaretSelection) {
        const anchorNode = nativeSelection.anchorNode;
        if (anchorNode !== null) {
          // Parent element is expected to be a word.
          const parentElement = anchorNode.parentElement;
          if (parentElement) {
            const grandparentElement = parentElement?.parentElement;
            let selectedSubIdInSet = undefined;
            if (parentElement.id) {
              // check words, lines
              const idParts: string[] = parentElement.id.split('-');
              for (let i = 0; i < 3; i++) {
                let subId = idParts.slice(0, (idParts.length - i)).join('-');
                if (annotatedElementIdSet.has(subId)) {
                  selectedSubIdInSet = subId;
                  break;
                }
              }
            } else if (grandparentElement && grandparentElement.parentElement) {
              // in the case where we have only IDs on the textbox level
              // check textboxes
              const id = grandparentElement.parentElement.id;
              if (annotatedElementIdSet.has(id)) {
                selectedSubIdInSet = id;
              }
            }
            setSelectedElementId(selectedSubIdInSet);
          } else {
            setSelectedElementId(undefined);
          }
        } else {
          setSelectedElementId(undefined);
        }
        setIsHighlightingText(false);
      } else if (isRangeSelection) {
        const rawTextContent = nativeSelection.toString().replace(/\n/g, '');
        if (!nativeSelection.isCollapsed && rawTextContent === '') {
          setIsHighlightingText(false);
        } else {
          setIsHighlightingText(true);
        }
        setSelectedElementId(undefined);
      } else {
        setSelectedElementId(undefined);
        setIsHighlightingText(false);
      }
    });
  }, [editor, annotatedElementIdSet]);

  useEffect(() => {
    document.addEventListener('selectionchange', updatePopup);
    return () => {
      document.removeEventListener('selectionchange', updatePopup);
    };
  }, [updatePopup]);

  useEffect(() => {
    return mergeRegister(
      editor.registerUpdateListener(() => {
        updatePopup();
      }),
      editor.registerRootListener(() => {
        if (editor.getRootElement() === null) {
          setIsHighlightingText(false);
          setSelectedElementId(undefined);
        }
      }),
    );
  }, [editor, updatePopup]);

  if (isHighlightingText) {
    return createPortal(
      injectAdditionFloatingToolbar(
        editor,
        anchorElem,
        isV2Render,
        lexicalDocumentIdentifier,
        pageIndex
      ), 
      anchorElem
    );
  } else if (selectedElementId !== undefined) {
    return createPortal(
      injectEditFloatingToolbar(
        editor,
        anchorElem,
        selectedElementId
      ),
      anchorElem,
    );
  }
  return null;
}

export default function FloatingAnnotationToolbarPlugin({
  anchorElem = document.body,
  annotations,
  isV2Render,
  lexicalDocumentIdentifier,
  pageIndex
}: {
  anchorElem?: HTMLElement;
  annotations: DocumentAnnotation[];
  isV2Render: boolean;
  lexicalDocumentIdentifier: string;
  pageIndex: number;
}): JSX.Element | null {
  const [editor] = useLexicalComposerContext();
  return useFloatingTextToolbar(
    editor,
    anchorElem,
    annotations,
    isV2Render,
    lexicalDocumentIdentifier,
    pageIndex
  );
}