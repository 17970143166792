import React, { useMemo } from 'react';
import { Box, Button, Grid, Typography } from "@mui/material";
import { useResearchPanelData } from '../../../../containers/ResearchPanelData/ResearchPanelData';
import SourceMaterialFlow from './SourceMaterialFlow';
import TypedText from '../../../animatedComponents/TypedText';
import { Edit } from '@mui/icons-material';
import CustomWorkflowFlow from './CustomWorkflowFlow';
import CustomUserFlow from './CustomUserFlow';
import { useGuidanceData } from '../../../../containers/GuidanceData';

interface AdvisorFlowProps {
    inWorkflow?: boolean
}

const AdvisorFlow = ({ inWorkflow }: AdvisorFlowProps) => {
    const {
        researchPanelAdvisorState,
        setResearchPanelAdvisorState
    } = useResearchPanelData();
    const { guidanceReferences } = useGuidanceData();

    const title = useMemo(() => {
        if (researchPanelAdvisorState.topLevelSelection === "SOURCE_MATERIALS") {
            return "What is it regarding?";
        } else if (researchPanelAdvisorState.topLevelSelection === "CUSTOM") {
            return "What would you like help with?";
        }
        return "What would you like help with?";
    }, [
        researchPanelAdvisorState.topLevelSelection,
    ]);

    return (
        <>
            <Box
                padding={2}
                width="100%"
                display="flex"
                alignItems="center"
                justifyContent="center"
            >
                <Typography
                    variant="h6"
                    textAlign="center"
                >
                    <TypedText text={title} />
                </Typography>
            </Box>
            {!researchPanelAdvisorState?.topLevelSelection && (
                <Grid
                    padding={2}
                    container
                    columnGap={1}
                    justifyContent="center"
                >
                    <Button
                        key="Custom"
                        variant="outlined"
                        disabled={guidanceReferences.length === 0}
                        onClick={() => {
                            setResearchPanelAdvisorState(prev => ({
                                ...prev,
                                topLevelSelection: "CUSTOM",
                                customState: {}
                            }));
                        }}
                        startIcon={<Edit />}
                    >
                        Custom
                    </Button>
                    {inWorkflow && (
                        <>
                            <Button
                                key="Source Materials"
                                variant="outlined"
                                onClick={() => {
                                    setResearchPanelAdvisorState(prev => ({
                                        ...prev,
                                        topLevelSelection: "SOURCE_MATERIALS",
                                        sourceMaterialsState: {}
                                    }))
                                }}
                            >
                                Source Materials
                            </Button>
                            <Button
                                key="Questionnaire"
                                variant="outlined"
                                disabled
                                onClick={() => { }}
                            >
                                Questionnaire
                            </Button>
                            <Button
                                key="Report"
                                variant="outlined"
                                disabled
                                onClick={() => { }}
                            >
                                Report
                            </Button>
                        </>
                    )}
                </Grid>
            )}
            {(researchPanelAdvisorState.topLevelSelection === "SOURCE_MATERIALS") && (
                <SourceMaterialFlow />
            )}
            {(researchPanelAdvisorState.topLevelSelection === "CUSTOM") && (
                inWorkflow ? (
                    <CustomWorkflowFlow />
                ) : (
                    <CustomUserFlow />
                )
            )}
        </>
    );
};

export default AdvisorFlow;
