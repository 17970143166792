import React, { useState } from "react";
import { useParams } from "react-router-dom";
import {
  Box,
  Button,
  Typography,
  Tabs,
  Tab,
  Divider
} from "@mui/material/";
import SideNav from "../../components/navigation/SideNav";
import { Assistant, AssistantAlgorithmParam, AssistantCapabilities } from "../../redux/models/dataModelTypes";
import { TextFieldRhf } from "../../components/form/reactHookForm/textFieldRhf";
import { SwitchRhf } from "../../components/form/reactHookForm/switchRhf";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import FullConfig, { ConfigEntry } from "../../components/form/EntryBuilder";
import { useAddAlgoParamMutation, useDeleteAlgoParamMutation, useGetAssistantQuery, useUpdateAssistantCapabilitiesMutation, useUpdateAssistantMutation } from "../../redux/services/assistant";


const getOptionsForAlgoParams = (capabilities: AssistantCapabilities) => {
    let allOptions = []
    if (capabilities.canAnnotateDocuments) {
        allOptions.push(...[
            {
                display: `Match key term example`, 
                id: `match_key_term_example`,
                description: `Whether or not to filter examples by the key term label.`
            },
            {
                display: `Max Number of Key Kerm Examples`, 
                id: `max_key_term_examples`,
                description: `Maximum number of examples to include in the prompt.`
            },
            {
                display: `Tagging Examples`, 
                id: `tagging_examples`,
                description: `Filepath in Google Cloud Storage`
                
            },
        ]);
    } 
    if (capabilities.canSummarize) {
        allOptions.push(...[
            {
                display: `Match example`, 
                id: `match_example`,
                description: `Whether or not to filter examples by the key term label.`
            },
            {
                display: `Max Number of Examples`, 
                id: `max_examples`,
                description: `Maximum number of examples to include in the prompt.`
            },
            {
                display: `Summary with Category Examples`, 
                id: `summary_with_category_examples`,
                description: `Filepath in Google Cloud Storage`
            },
            {
                display: `Summary without Category Examples`, 
                id: `summary_without_category_examples`,
                description: `Filepath in Google Cloud Storage`
            },
            {
                display: `Rephrase Summary without Category Examples`, 
                id: `rephrase_summary_without_category_examples`,
                description: `Filepath in Google Cloud Storage`
            }
        ]);
    }
    return allOptions;
}


interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}


interface AssistantUpdateFormProps {
  assistant: Assistant;
}

interface AssistantFormInputs {
    name: string;
    description: string;
};

interface AssistantCapabilitiesFormInputs {
    canSummarize: boolean;
    canQa: boolean;
    canRewriteReport: boolean;
    canAnnotateDocuments: boolean;
    canSimulateFulfillment: boolean;
    canAdvisorPrimary: boolean;
    canAdvisorSecondary: boolean;
    canDoDocumentEmbedding: boolean;
};

const assistantSchema = yup.object().shape({
    name: yup.string().required(),
    description: yup.string().required(),
});

const assistantCapabilitiesSchema = yup.object().shape({
    canSummarize: yup.boolean().required(),
    canQa: yup.boolean().required(),
    canRewriteReport: yup.boolean().required(),
    canAnnotateDocuments: yup.boolean().required(),
    canSimulateFulfillment: yup.boolean().required(),
    canAdvisorPrimary: yup.boolean().required(),
    canAdvisorSecondary: yup.boolean().required(),
    canDoDocumentEmbedding: yup.boolean().required(),
});

const AssistantUpdateForm = ({ assistant }: AssistantUpdateFormProps) => {
    const [updateAssistant, updateAssistantResult] = useUpdateAssistantMutation();
    const [updateAssistantCapabilities, updateAssistantCapabilitiesResult] = useUpdateAssistantCapabilitiesMutation();
    
    const {
        control: assistantControl,
        handleSubmit: assistantHandleSubmit,
        formState: { errors: assistantErrors },
    } = useForm<AssistantFormInputs>({
        resolver: yupResolver(assistantSchema),
        defaultValues: {
            name: assistant.name,
            description: assistant.description,
        },
    });

    const {
        control: assistantCapabilitiesControl,
        handleSubmit: assistantCapabilitiesHandleSubmit,
        formState: { errors: assistantCapabilitiesErrors },
    } = useForm<AssistantCapabilitiesFormInputs>({
        resolver: yupResolver(assistantCapabilitiesSchema),
        defaultValues: {
            canSummarize: Boolean(assistant.capabilities.canSummarize),
            canQa: Boolean(assistant.capabilities.canQa),
            canRewriteReport: Boolean(assistant.capabilities.canRewriteReport),
            canAnnotateDocuments: Boolean(assistant.capabilities.canAnnotateDocuments),
            canSimulateFulfillment: Boolean(assistant.capabilities.canSimulateFulfillment),
            canAdvisorPrimary: Boolean(assistant.capabilities.canAdvisorPrimary),
            canAdvisorSecondary: Boolean(assistant.capabilities.canAdvisorSecondary),
            canDoDocumentEmbedding: Boolean(assistant.capabilities.canDoDocumentEmbedding),
        },
    });

    const assistantHandleSubmitCust = (d: AssistantFormInputs) => {
        updateAssistant({
            id: assistant.id,
            name: d.name,
            description: d.description
        });
    };

    const assistantCapabilitiesHandleSubmitCust = (d: AssistantCapabilitiesFormInputs) => {
        console.log(d);
        if (d.canSummarize !== assistant.capabilities.canSummarize
            || d.canQa !== assistant.capabilities.canQa
            || d.canRewriteReport !== assistant.capabilities.canRewriteReport
            || d.canAnnotateDocuments !== assistant.capabilities.canAnnotateDocuments
            || d.canSimulateFulfillment !== assistant.capabilities.canSimulateFulfillment
            || d.canAdvisorPrimary !== assistant.capabilities.canAdvisorPrimary
            || d.canAdvisorSecondary !== assistant.capabilities.canAdvisorSecondary
            || d.canDoDocumentEmbedding !== assistant.capabilities.canDoDocumentEmbedding
        ) {
            updateAssistantCapabilities({
                id: assistant.capabilities.id,
                assistantId: assistant.id,
                canSummarize: d.canSummarize, 
                canQa: d.canQa, 
                canRewriteReport: d.canRewriteReport,
                canAnnotateDocuments: d.canAnnotateDocuments,
                canSimulateFulfillment: d.canSimulateFulfillment,
                canAdvisorPrimary: d.canAdvisorPrimary,
                canAdvisorSecondary: d.canAdvisorSecondary,
                canDoDocumentEmbedding: d.canDoDocumentEmbedding,
            });
        }
    };

    return (
        <>
            <form 
                onSubmit={assistantHandleSubmit(assistantHandleSubmitCust)}
                style={{
                    display: "flex",
                    flexDirection: "column",
                }}
            >
                <TextFieldRhf
                    sx={{ marginTop: "20px" }}
                    label="Name"
                    fullWidth={false}
                    fieldName="name"
                    variant="outlined"
                    control={assistantControl}
                    errors={assistantErrors}
                />
                <TextFieldRhf
                    sx={{ marginTop: "20px" }}
                    label="Description"
                    multiline={true}
                    fieldName="description"
                    variant="outlined"
                    control={assistantControl}
                    errors={assistantErrors}
                />
                <Box sx={{ marginTop: "20px" }}>
                    <Button 
                        variant="contained" 
                        type="submit"
                    >
                        Save
                    </Button>
                </Box>
            </form>
            <Divider sx={{ marginTop: "20px" }} />
            <form 
                onSubmit={assistantCapabilitiesHandleSubmit(assistantCapabilitiesHandleSubmitCust)}
                style={{
                    display: "flex",
                    flexDirection: "column",
                }}
            >               
                <Box sx={{ marginTop: "20px" }}>
                    <Typography variant="h6">
                        Capabilities
                    </Typography>
                </Box>
                <SwitchRhf
                    sx={{ marginTop: "20px" }}
                    label="Summarize"
                    fieldName="canSummarize"
                    control={assistantCapabilitiesControl}
                />
                <SwitchRhf
                    sx={{ marginTop: "20px" }}
                    label="Question Answering"
                    fieldName="canQa"
                    control={assistantCapabilitiesControl}
                />
                <SwitchRhf
                    sx={{ marginTop: "20px" }}
                    label="Rewrite Report"
                    fieldName="canRewriteReport"
                    control={assistantCapabilitiesControl}
                />
                <SwitchRhf
                    sx={{ marginTop: "20px" }}
                    label="Document Tagging"
                    fieldName="canAnnotateDocuments"
                    control={assistantCapabilitiesControl}
                />
                <SwitchRhf
                    sx={{ marginTop: "20px" }}
                    label="Simulate Fulfillment"
                    fieldName="canSimulateFulfillment"
                    control={assistantCapabilitiesControl}
                />
                <SwitchRhf
                    sx={{ marginTop: "20px" }}
                    label="Advisor Primary"
                    fieldName="canAdvisorPrimary"
                    control={assistantCapabilitiesControl}
                />
                <SwitchRhf
                    sx={{ marginTop: "20px" }}
                    label="Advisor Secondary"
                    fieldName="canAdvisorSecondary"
                    control={assistantCapabilitiesControl}
                />
                <SwitchRhf
                    sx={{ marginTop: "20px" }}
                    label="Document Embedding"
                    fieldName="canDoDocumentEmbedding"
                    control={assistantCapabilitiesControl}
                />
                <Box sx={{ marginTop: "20px" }}>
                    <Button 
                        variant="contained" 
                        type="submit"
                    >
                        Save
                    </Button>
                </Box>
            </form>
        </>
    );
}

const AssistantPage = () => {
    const { id } = useParams<{ id: any }>();
    const [tabValue, setTabValue] = useState(0);

    const { data: assistant } = useGetAssistantQuery(id);
    const [addAlgoParam, addAlgoParamResult] = useAddAlgoParamMutation();
    const [deleteAlgoParam, deleteAlgoParamResult] = useDeleteAlgoParamMutation();

    const handleChange = (event: React.SyntheticEvent, newValue: number) => setTabValue(newValue);

    return (
        <SideNav>
            <Box 
                padding={1}
                height="100%"
                overflow="auto"
            >
                {assistant && (
                    <Box>
                        <Typography variant="h5">{`Based on ${assistant.languageModel.name}`}</Typography>
                        <Typography paragraph>
                            {assistant.languageModel.description}
                        </Typography>
                    </Box>
                )}
                <Tabs 
                value={tabValue} 
                onChange={handleChange} 
                aria-label="basic tabs example"
                >
                    <Tab label="Properties and Capabilities" {...a11yProps(0)} />
                    <Tab label="Algorithm Parameters" {...a11yProps(1)} />
                </Tabs>
                <TabPanel value={tabValue} index={0}>
                    {(assistant && assistant.capabilities) && (
                        <AssistantUpdateForm assistant={assistant}/>
                    )}
                </TabPanel>
                <TabPanel value={tabValue} index={1}>
                    {(assistant) && (
                        <FullConfig
                            options={getOptionsForAlgoParams(
                                assistant.capabilities
                            )}
                            additionalFields={[]}
                            entries={
                                assistant.algorithmParams.map((ap: AssistantAlgorithmParam) => ({
                                    id: ap.id,
                                    key: ap.key,
                                    rawData: ap.value,
                                    formattedData: ap.value
                                } as ConfigEntry))
                            }
                            onDeleteEntry={(ce: ConfigEntry) => {
                                if (assistant) {
                                    deleteAlgoParam({
                                        id: ce.id,
                                        assistantId: assistant.id
                                    });
                                }
                            }}
                            keyOptionsGetter={(k: string) => undefined}
                            onCreate={(d: any) => {
                                if (assistant) {
                                    addAlgoParam({
                                        assistantId: assistant.id,
                                        key: d.key,
                                        value: d.data
                                    });
                                }
                            }}
                        />
                    )}
                </TabPanel>
            </Box>
        </SideNav>
    );
}

export default AssistantPage;
