import React, {
    createContext,
    useContext,
    memo,
    useState,
    useEffect,
    useMemo
} from "react";
import { useTakerState } from "../../../../containers/TakerDocumentState/TakerDocumentState";
import { TakerDocumentAnalysis } from "../../../../redux/models/dataModelTypes";
import { useQuery } from "@tanstack/react-query";
import { TakerStateHolder, FulfillmentStateHolder } from "../../../../types/taker";
import { MultipleWorkflowQuestionAnalysis } from "../../../../types/taker/uidatastate.generated";

async function fetchTakerDocumentAnalysis(analysisId?: string): Promise<TakerDocumentAnalysis> {
    if (!analysisId) {
        return Promise.reject();
    }

    let baseUrl = !!window.__RUNTIME_CONFIG__ ? window.__RUNTIME_CONFIG__.API_ENDPOINT : "localhost";
    let response = await fetch(
        `${baseUrl}/taker_document_analysis/${analysisId}`,
        {
            credentials: 'include',
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        }
    )
    return await response.json();
}

async function fetchTakerDocumentAnalysisPayload(analysisId?: string): Promise<any> {
    if (!analysisId) {
        return Promise.reject();
    }

    let baseUrl = !!window.__RUNTIME_CONFIG__ ? window.__RUNTIME_CONFIG__.API_ENDPOINT : "localhost";
    let response = await fetch(
        `${baseUrl}/taker_document_analysis/${analysisId}/payload`,
        {
            credentials: 'include',
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        }
    )
    return await response.json();
}

interface AiWorkflowGenerationContextType {
    pendingGeneration: boolean | undefined;
    failureMessage: string | undefined;
    localTakerDocumentHolder: TakerStateHolder | undefined;
    localFulfillmentStateHolder: FulfillmentStateHolder | undefined;
    scopedVars: any;
    workingAnalysis: MultipleWorkflowQuestionAnalysis | undefined;
    allAnsweredIds: string[];
    lastAnsweredIds: string[];
    latestTakerDocumentAnalysis: TakerDocumentAnalysis | undefined;
}

const AiWorkflowGenerationContext = createContext<AiWorkflowGenerationContextType | undefined>(undefined);

interface AiWorkflowGenerationProviderProps {
    children: React.ReactNode;
};

const AiWorkflowGenerationProvider: React.FC<AiWorkflowGenerationProviderProps> = ({
    children,
}) => {
    const {
        activeTakerDocument,
        takerDocumentAnalysesInProgress,
        activeTargetTakerState
    } = useTakerState();

    const workingAnalysisMap = useMemo(() => {
        if (!activeTargetTakerState?.uiDataSchema.analysisList) {
            return {};
        }

        const map: Record<string, MultipleWorkflowQuestionAnalysis> = {};
        for (const a of activeTargetTakerState.uiDataSchema.analysisList) {
            if (a.analysisType === "MULTIPLE_WORKFLOW_QUESTION") {
                map[a.analysisId] = a;
            }
        }
        return map;
    }, [
        activeTargetTakerState?.uiDataSchema.analysisList
    ]);

    // This are specifically for the "bulk generation"
    const [matchingTakerDocumentAnalysis, setMatchingTakerDocumentAnalysis] = useState<TakerDocumentAnalysis | undefined>();
    const [workingAnalysis, setWorkingAnalysis] = useState<MultipleWorkflowQuestionAnalysis | undefined>();
    const [allAnsweredIds, setAllAnsweredIds] = useState<string[]>([]);
    const [lastAnsweredIds, setLastAnsweredIds] = useState<string[]>([]);
    const [pendingGeneration, setPendingGeneration] = useState<boolean | undefined>(undefined);
    const [failureMessage, setFailureMessage] = useState<string | undefined>(undefined);
    const [localTakerDocumentHolder, setLocalTakerDocumentHolder] = useState<TakerStateHolder>();
    const [localFulfillmentStateHolder, setLocalFulfillmentStateHolder] = useState<FulfillmentStateHolder>();
    const [scopedVars, setScopedVars] = useState<any>();

    useEffect(() => {
        if (!activeTakerDocument) {
            return;
        }

        // sort the topWorkflowQuestionTakerDocumentAnalyses by createdAt and
        // filter out the ones that are more than 1 week old.
        const qualifiedSortedTdus = [...activeTakerDocument.topWorkflowQuestionTakerDocumentAnalyses]
            .sort((a, b) => b.createdAt - a.createdAt)
            .filter((a) => (Date.now() - a.createdAt) < 604800000);

        // return the first (latest) analysis that is in the list of allAnalysisIds
        for (const tdu of qualifiedSortedTdus) {
            if (workingAnalysisMap[tdu.id]) {
                setMatchingTakerDocumentAnalysis(tdu);
                setWorkingAnalysis(workingAnalysisMap[tdu.id]);
                return;
            }
        }
    }, [
        workingAnalysisMap,
        activeTargetTakerState?.uiDataSchema.analysisList,
        activeTakerDocument?.topWorkflowQuestionTakerDocumentAnalyses,
    ]);

    const {
        data: payload,
        isSuccess: isPayloadSuccess,
        //refetch: tdAnalysisPayloadRefetch,
    } = useQuery({
        queryKey: ['TakerDocumentAnalysisPayload', matchingTakerDocumentAnalysis?.id],
        queryFn: async () => await fetchTakerDocumentAnalysisPayload(matchingTakerDocumentAnalysis?.id),
        enabled: !!matchingTakerDocumentAnalysis,
        refetchInterval: (
            matchingTakerDocumentAnalysis && takerDocumentAnalysesInProgress.includes(matchingTakerDocumentAnalysis?.id) ?
                5000 :
                false
        ),
    });

    const { data: latestTakerDocumentAnalysis } = useQuery({
        queryKey: ['TakerDocumentAnalysis', matchingTakerDocumentAnalysis?.id],
        queryFn: async () => await fetchTakerDocumentAnalysis(matchingTakerDocumentAnalysis?.id),
        initialData: matchingTakerDocumentAnalysis,
        refetchInterval: (
            matchingTakerDocumentAnalysis && takerDocumentAnalysesInProgress.includes(matchingTakerDocumentAnalysis?.id) ?
                5000 :
                false
        ),
        enabled: !!matchingTakerDocumentAnalysis,
    });

    useEffect(() => {
        if (latestTakerDocumentAnalysis?.type === "WORKFLOW_QUESTIONS") {
            if (latestTakerDocumentAnalysis.state === "PENDING_GENERATION") {
                setPendingGeneration(true);
                //tdAnalysisPayloadRefetch();
            } else if (latestTakerDocumentAnalysis.state === "PENDING_REVIEW") {
                setPendingGeneration(false);
                //tdAnalysisPayloadRefetch();
            } else if (latestTakerDocumentAnalysis.state === "PENDING_GENERATION_CANCELED") {
                setPendingGeneration(false);
                let msg = latestTakerDocumentAnalysis.data['job_failure'];
                if (!msg) {
                    msg = "The generation was canceled or something went wrong.";
                }
                setFailureMessage(msg);
            }
        }
    }, [latestTakerDocumentAnalysis]);

    useEffect(() => {
        if (!isPayloadSuccess) {
            return;
        }

        if ((latestTakerDocumentAnalysis?.type === "WORKFLOW_QUESTIONS") && payload) {
            console.log("Payload received: ", payload);
            if (payload["ui_state"] !== undefined) {
                setLocalTakerDocumentHolder(new TakerStateHolder(payload["ui_state"]));
            }
            if (payload["fulfillment_state"] !== undefined) {
                setLocalFulfillmentStateHolder(new FulfillmentStateHolder(payload["fulfillment_state"]));
            }
            if (payload["scoped_vars"] !== undefined) {
                setScopedVars(payload["scoped_vars"]);
            }
            if (payload["all_answered_ids"] !== undefined) {
                setAllAnsweredIds(payload["all_answered_ids"]);
            }
            if (payload["last_answered_ids"] !== undefined) {
                setLastAnsweredIds(payload["last_answered_ids"]);
            }
        }
    }, [
        latestTakerDocumentAnalysis,
        isPayloadSuccess,
        payload
    ]);

    return (
        <AiWorkflowGenerationContext.Provider
            value={{
                pendingGeneration,
                failureMessage,
                localTakerDocumentHolder,
                localFulfillmentStateHolder,
                scopedVars,
                workingAnalysis,
                allAnsweredIds,
                lastAnsweredIds,
                latestTakerDocumentAnalysis,
            }}
        >
            {children}
        </AiWorkflowGenerationContext.Provider>
    );
};

const MemoizedAiWorkflowGenerationProvider = memo(AiWorkflowGenerationProvider);

const useAiWorkflowGeneration = () => {
    const context = useContext(AiWorkflowGenerationContext);
    if (!context) {
        throw new Error('useAiWorkflowGeneration must be used within a AiWorkflowGenerationProvider');
    }
    return context;
};

export {
    MemoizedAiWorkflowGenerationProvider as AiWorkflowGenerationProvider,
    useAiWorkflowGeneration
}
