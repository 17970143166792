import React, { useEffect, useState, useCallback, useMemo, memo } from 'react';
import { useTakerState } from '../../../containers/TakerDocumentState/TakerDocumentState';
import { Button, Checkbox, ListItemText, Menu, MenuItem, MenuList, Box, Typography, FormHelperText, FormControl, IconButton } from '@mui/material';
import { Article, FilterList, Summarize } from '@mui/icons-material';
import { useCounter, useLocalStorage } from '@uidotdev/usehooks';
import { CommentType } from '../../../redux/models/dataModelTypes';

export const DOCUMENT_COMMENT_VALUE: CommentType = "DOCUMENT_HIGHLIGHT";
export const KEY_TERM_COMMENT_VALUE: CommentType = "KEY_TERM";
export const QUESTION_COMMENT_VALUE = "QUESTION";
export const REPORT_SECTION_COMMENT_VALUE: CommentType = "REPORT_SECTION";

export type Filter = {
    // Type + value will create a unique filter.
    // The type and value will also determine how the filter is applied.
    type: "COMMENT_TYPE" | "KEY_TERM_GROUP" | "DOCUMENT_KEY_TERM_GROUP" | "DOCUMENT" | "REPORT_SECTION" | "QUESTION";
    value: string;
    label: any;
    enabled: boolean;
    disabledForUse: boolean;
    hasDivider?: boolean;
};

export interface CommentFiltersProps {
    commentTypes: CommentType[];
    onChangeEnabledFilters: (filters: Filter[]) => void;
    updateAvailableFilters: (availableFilters: Filter[]) => void;
};

/**
 * CommentFilters is a component that renders a menu of filters for a comments drawer.
 * The filters are divided into three categories: document, key term group, and document.
 * The filters are applied to the comments drawer based on the user's selection.
 * The component also handles saving the user's filter selection to local storage.
 * 
 * @param {{ commentTypes: CommentType[]; onChangeEnabledFilters: (filters: Filter[]) => void; }} props
 * @prop {CommentType} [commentType] The type of comment to filter by.
 * @prop {(filters: Filter[]) => void} onChangeEnabledFilters A callback that will be called when the enabled filters change.
 * 
 * @returns {JSX.Element} The CommentFilters component.
 */
const CommentFilters = ({
    commentTypes,
    onChangeEnabledFilters,
    updateAvailableFilters
}: CommentFiltersProps) => {
    const {
        takerDocumentId,
        takerDocumentUploads
    } = useTakerState();
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [savedFilters, setSavedFilters] = useLocalStorage<Record<string, boolean>>(`${takerDocumentId}-commentDrawerFilters`, {});
    const [availableFilters, setAvailableFilters] = useState<Filter[]>([]);
    const [count, countMutator] = useCounter(0);
    const openMenu = Boolean(anchorEl);

    // Need to pull this from local storage because it's out of the scope of the key terms wrapper
    const [targetFileUploadItemIds, setTargetFileUploadItemIds] = useLocalStorage<string[]>("KeyTermsState-TargetFileUploadItemIds", []);

    const targetKeyTermGroupId = useMemo(() => {
        if (!targetFileUploadItemIds || !takerDocumentUploads) {
            return null;
        } else {
            return takerDocumentUploads.find(
                tdu => tdu.fileUpload.fileUploadItems.every(
                    fui => targetFileUploadItemIds.includes(fui.id)
                )
            )?.id;
        }
    }, [takerDocumentUploads, targetFileUploadItemIds]);
    
    const applySavedFilterOrDefault = useCallback((filter: Partial<Filter>, defaultEnabled: boolean) => {
        const key = `${filter.type}-${filter.value}`;
        filter.enabled = savedFilters[key] ?? defaultEnabled;
        return filter as Filter;
    }, [savedFilters]);

    const isFilterEnabled = (
        filterType: "COMMENT_TYPE" | "KEY_TERM_GROUP" | "DOCUMENT_KEY_TERM_GROUP" | "DOCUMENT", 
        filterValue: string, 
        filters: Filter[]
    ) => {
        for (const filter of filters) {
            if (filter.type === filterType && filter.value === filterValue) {
                return filter.enabled;
            }
        }
        return false;
    };

    // All available filters.
    useEffect(() => {
        const filters: Filter[] = [];

        // Build all of the filters here.
        // Use saved filter values to override defaults.

        // Checks for target file upload item IDs in case it's not in the local storage
        if(targetFileUploadItemIds) {
            filters.push(applySavedFilterOrDefault(
                {
                    type: "COMMENT_TYPE",
                    value: DOCUMENT_COMMENT_VALUE,
                    label: <Typography noWrap>Documents</Typography>,
                    disabledForUse: false
                }, 
                (!commentTypes.includes(DOCUMENT_COMMENT_VALUE))
            ));
        

            const documentFiltersEnabled = isFilterEnabled("COMMENT_TYPE", DOCUMENT_COMMENT_VALUE, filters);
            if (documentFiltersEnabled) {
                if (takerDocumentUploads && targetKeyTermGroupId && targetFileUploadItemIds !== null) {
                    for (const takerDocumentUpload of takerDocumentUploads) {
                        filters.push(applySavedFilterOrDefault(
                            {
                                type: "DOCUMENT_KEY_TERM_GROUP",
                                value: takerDocumentUpload.id,
                                label: (
                                    <Box display="contents" justifyItems="center">
                                        <Summarize fontSize="small"/>
                                        <Typography noWrap>&nbsp;{takerDocumentUpload.name}</Typography>
                                    </Box>
                                ),
                                disabledForUse: false
                            },
                            (!commentTypes.includes(DOCUMENT_COMMENT_VALUE) || targetKeyTermGroupId !== takerDocumentUpload.id)
                        ));

                        const tduFilterEnabled = isFilterEnabled("DOCUMENT_KEY_TERM_GROUP", takerDocumentUpload.id, filters);
                        if (documentFiltersEnabled && tduFilterEnabled) {
                            for (const fileItem of takerDocumentUpload.fileUpload.fileUploadItems) {
                                if (fileItem.state !== "ARCHIVED") {
                                    filters.push(applySavedFilterOrDefault(
                                        {
                                            type: "DOCUMENT",
                                            value: fileItem.id,
                                            label: (
                                                <Box display="contents" justifyItems="center">
                                                    <Article fontSize="small"/>
                                                    <Typography noWrap>&nbsp;{fileItem.label}</Typography>
                                                </Box>
                                            ),
                                            disabledForUse: false
                                        }, 
                                        (!commentTypes.includes(DOCUMENT_COMMENT_VALUE) || !targetFileUploadItemIds.includes(fileItem.id))
                                    ));
                                }
                            }
                        }
                    }
                }
            }
        }

        // Checks the length because of the above conditional filter add
        if(filters.length > 0) {
            filters[filters.length - 1].hasDivider = true;
        }
        filters.push(applySavedFilterOrDefault(
            {
                type: "COMMENT_TYPE",
                value: KEY_TERM_COMMENT_VALUE,
                label: <Typography noWrap>Key Terms</Typography>,
                disabledForUse: false
            },
            (!commentTypes.includes(KEY_TERM_COMMENT_VALUE))
        ));

        const keyTermFiltersEnabled = isFilterEnabled("COMMENT_TYPE", KEY_TERM_COMMENT_VALUE, filters);
        if (keyTermFiltersEnabled) {
            if (takerDocumentUploads) {
                for (const takerDocumentUpload of takerDocumentUploads) {
                    filters.push(applySavedFilterOrDefault(
                        {
                            type: "KEY_TERM_GROUP",
                            value: takerDocumentUpload.id,
                            label: (
                                <Box display="contents" justifyItems="center">
                                    <Summarize fontSize="small"/>
                                    <Typography noWrap>&nbsp;{takerDocumentUpload.name}</Typography>
                                </Box>
                            ),
                            disabledForUse: false
                        },
                        (!commentTypes.includes(KEY_TERM_COMMENT_VALUE) || targetKeyTermGroupId !== takerDocumentUpload.id)
                    ));
                }
            }
        }

        filters[filters.length - 1].hasDivider = true;
        filters.push(applySavedFilterOrDefault(
            {
                type: "COMMENT_TYPE",
                value: QUESTION_COMMENT_VALUE,
                label: <Typography noWrap>Questions</Typography>,
                enabled: true,
                disabledForUse: false
            },
            (!commentTypes.includes(QUESTION_COMMENT_VALUE))
        ));

        filters[filters.length - 1].hasDivider = true;
        filters.push(applySavedFilterOrDefault(
            {
                type: "COMMENT_TYPE",
                value: REPORT_SECTION_COMMENT_VALUE,
                label: <Typography noWrap>Report</Typography>,
                enabled: true,
                disabledForUse: false,
            },
            (!commentTypes.includes(REPORT_SECTION_COMMENT_VALUE))
        ));
        filters[filters.length - 1].hasDivider = true;
        setAvailableFilters(filters);
        updateAvailableFilters(filters);
    }, [
        commentTypes,
        targetFileUploadItemIds,
        targetKeyTermGroupId,
        takerDocumentUploads,
        count
    ]);

    // Relay enabled filters to parent
    useEffect(() => {
        onChangeEnabledFilters(availableFilters.filter(f => f.enabled));
    }, [availableFilters]);

    return (
        <>
            <IconButton
                data-testid="comments-drawer-filters-button"
                sx={{
                    margin: 1
                }}
                size="medium"
                onClick={(event: React.MouseEvent<HTMLElement>) => {
                    setAnchorEl(event.currentTarget);
                }}
            >
                <FilterList fontSize="inherit" />
            </IconButton>
            <Menu
                data-testid="comments-drawer-filters-menu"
                anchorEl={anchorEl}
                open={openMenu}
                onClose={() => setAnchorEl(null)}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                slotProps={{
                    paper: {
                        style: {
                            maxHeight: "350px",
                            width: "35ch",
                        },
                    },
                }}
                MenuListProps={{
                    dense: true,
                    disablePadding: true
                }}
            >
                <MenuItem disabled>
                    <ListItemText secondary="* filters are exclusionary"/> 
                </MenuItem>
                {availableFilters.map((f, filterIndex ) => (
                    <MenuItem 
                        data-testid={`comments-drawer-filters-menuitem-${filterIndex}`}
                        disabled={f.disabledForUse} 
                        key={f.label}
                        divider={f.hasDivider}
                    >
                        <Checkbox
                            data-testid={`comments-drawer-filters-checkbox-${filterIndex}`}
                            checked={f.enabled}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                let newSavedFilters = {...savedFilters};
                                newSavedFilters[`${f.type}-${f.value}`] = event.target.checked;
                                setSavedFilters(newSavedFilters);
                                countMutator.increment();
                            }}
                        />
                        {f.label}
                    </MenuItem>
                ))}
            </Menu>
        </>
    );
}

export default memo(CommentFilters);