import React from "react";
import { useParams } from "react-router-dom";
import SideNav from "../../../components/navigation/SideNav";
import { useGetBuilderDocumentQuery, useGetBuilderQuery } from "../../../redux/services/builder";
import GraphEditor from "./GraphEditor";
import { skipToken } from "@reduxjs/toolkit/query";
import BuilderDataContainer, { useBuilderData } from "./context";
import { BuilderDocumentProvider } from "../SpecBuilder/CustomCommentaryWidget/context";
import { Alert, AlertTitle, Box } from "@mui/material";

const BuilderGraphEditorComponent = () => {
    const { 
        fulfillmentErrors,
        guidanceErrors
    } = useBuilderData();

    return (
        <Box height="100%">
            {guidanceErrors.map((e) => (
                <Alert color="warning">{e}</Alert>
            ))}
            {fulfillmentErrors.length > 0 && (
                <Alert color="warning">
                    <AlertTitle>
                        There were errors with fulfillment
                    </AlertTitle>
                    <Box>
                        <ul>
                            {fulfillmentErrors.map(fe => (
                                <li>{fe}</li>
                            ))}
                        </ul>
                    </Box>
                </Alert>
            )}
            <GraphEditor />
        </Box>
    );
}

const BuilderGraphEditor = () => {
    const { id } = useParams<{ id: any }>();
    const { data: builder } = useGetBuilderQuery(id);

    const documentLoaded = !!builder && !!builder.builderDocuments[0];

    const { data: builderDocument } = useGetBuilderDocumentQuery(
        documentLoaded ? builder.builderDocuments[0].id : skipToken, 
        {
            skip: !documentLoaded
        }
    );

    return (
        <SideNav>
            {(documentLoaded && builderDocument) && (
                <BuilderDocumentProvider builderDocument={builderDocument}>                
                    <BuilderDataContainer>
                        <BuilderGraphEditorComponent />
                    </BuilderDataContainer>
                </BuilderDocumentProvider>
            )}
        </SideNav>
    );
};

export default BuilderGraphEditor;
