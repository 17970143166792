import React, { Suspense, useMemo } from "react";
import { Box, Grid, Stack, ToggleButton, ToggleButtonGroup, Toolbar, Tooltip, useTheme } from "@mui/material";
import TakerToolbar from "../../components/taker/TakerToolbar/TakerToolbar";
import { useTakerState } from "../../containers/TakerDocumentState/TakerDocumentState";
import { useResearchPanelData } from "../../containers/ResearchPanelData/ResearchPanelData";
import { useNavigate } from "react-router-dom";
import { AnalysisWrapper, useAnalysisWrapper } from "../../containers/WidgetWrapper/wrapper";
import CommentsDrawer from "../../components/taker/CommentsDrawer";
import { useSideNav } from "../../components/navigation/SideNav";
import RectangularLoading from "../../components/loading/RectangularLoading";
import { ResearchPanel } from "../../components/research/ResearchPanel";
import { AiWorkflowGenerationProvider } from "./Questionnaire/AiWorkflowGenerated/context";

import "./index.css"

const QuestionnaireLazy = React.lazy(() => import("./Questionnaire"));
const DiagramLazy = React.lazy(() => import("./Diagram/Diagram"));

const AnalysisToolbar = () => {
    const { state, mutateState } = useAnalysisWrapper();

    const { panelViewMode } = state;

    const selectedPanels = useMemo(() => {
        if (panelViewMode === 0) {
            return [0, 1];
        } else if (panelViewMode === 1) {
            return [0];
        } else if (panelViewMode === 2) {
            return [1];
        }
    }, [panelViewMode]);

    return (
        <Box sx={{ padding: 0.5 }}>
            <Toolbar
                variant="dense"
                disableGutters
                sx={{
                    width: "100%",
                    display: "inline-grid",
                    gridTemplateColumns: "1fr 1fr",
                }}
            >
                <span></span>
                <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                    <Tooltip title={"Toggle Panel View"}>
                        <ToggleButtonGroup
                            size="small"
                            color="primary"
                            value={selectedPanels}
                            data-testid="toolbar-toggle-group"
                            onChange={(_, newMode) => {
                                let hasQuestionnaire = newMode.includes(0);
                                let hasGps = newMode.includes(1);
                                if (hasQuestionnaire && hasGps) {
                                    mutateState({
                                        panelViewMode: 0
                                    });
                                } else if (hasQuestionnaire) {
                                    mutateState({
                                        panelViewMode: 1
                                    });
                                } else if (hasGps) {
                                    mutateState({
                                        panelViewMode: 2
                                    });
                                }
                            }}
                        >
                            <ToggleButton
                                value={0}
                            >
                                Q&A
                            </ToggleButton>
                            <ToggleButton
                                value={1}
                            >
                                GPS
                            </ToggleButton>
                        </ToggleButtonGroup>
                    </Tooltip>
                </Box>
            </Toolbar>
        </Box>
    );
};

const TakerWidgets = () => {
    const { state } = useAnalysisWrapper();

    const { panelViewMode } = state;

    let showQuestionnaire = panelViewMode === 0 || panelViewMode === 1;
    let showGps = panelViewMode === 0 || panelViewMode === 2;

    return (
        <Grid
            container
            height="100%"
            width="100%"
        >
            <AiWorkflowGenerationProvider>
                {showQuestionnaire && (
                    <Grid
                        item
                        xs={showGps ? 6 : 12}
                        sx={{
                            height: '100%',
                            width: "100%"
                        }}
                    >
                        <Suspense fallback={<RectangularLoading />}>
                            <QuestionnaireLazy />
                        </Suspense>
                    </Grid>
                )}
                {showGps && (
                    <Grid
                        item
                        xs={showQuestionnaire ? 6 : 12}
                        sx={{
                            height: '100%',
                            width: "100%"
                        }}
                        alignItems="center"
                        alignContent="center"
                    >
                        <Suspense fallback={<RectangularLoading />}>
                            <DiagramLazy />
                        </Suspense>
                    </Grid>
                )}
            </AiWorkflowGenerationProvider>
        </Grid>
    );
}

const TakerAnalysis = () => {
    const { isOpen: isSideNavOpen } = useSideNav();
    const navigate = useNavigate();
    const { taker } = useTakerState();
    const { researchPanelBasicState } = useResearchPanelData();
    const theme = useTheme();

    const contentStyle: React.CSSProperties = {
        height: '100%',
        display: 'flex',
        flexDirection: 'column'
    };
    if (isSideNavOpen) {
        contentStyle.width = 'calc(100vw - 200px)';
        contentStyle.transition = theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        });
    } else {
        contentStyle.width = '100vw';
        contentStyle.transition = theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        });
    }

    return (
        <Box sx={contentStyle}>
            <AnalysisWrapper>
                <Box
                    sx={{
                        width: "100%",
                        alignItems: "center",
                        display: "flex",
                        paddingLeft: 1.5,
                        paddingRight: 1.5,
                        borderRadius: 1,
                        backgroundColor: "#F0F4F8",
                        borderBottom: "1px solid rgb(228, 228, 228, 0.9)"
                    }}
                >
                    <Stack width="100%">
                        {researchPanelBasicState?.isOpen && (
                            <ResearchPanel />
                        )}
                        <TakerToolbar
                            onClickBack={() => {
                                if (taker) {
                                    navigate(`/mainTaker/${taker.id}/keyTerms`);
                                }
                            }}
                            onClickForward={() => {
                                if (taker) {
                                    navigate(`/mainTaker/${taker.id}/report`);
                                }
                            }}
                            workflowDisplay={"Analysis"}
                            workflowDescription={`Please review and approve each answer and analysis.`}
                        />
                        <AnalysisToolbar />
                    </Stack>
                </Box>
                <Box
                    sx={{
                        width: '100%',
                        overflow: 'hidden'
                    }}
                    flexGrow={1}
                >
                    <CommentsDrawer commentTypes={["QUESTION"]}>
                        <TakerWidgets />
                    </CommentsDrawer>
                </Box>
            </AnalysisWrapper>
        </Box>
    );
}

export default TakerAnalysis;
