import React, { useState, useCallback, useEffect, memo } from 'react';
import {
  EdgeProps,
  Edge,
  useReactFlow,
  useStore,
  EdgeLabelRenderer
} from '@xyflow/react';
import { IconButton, Typography } from '@mui/material';
import { useBuilderData } from './context';
import { Root } from '../../../types/builderv2.generated';
import { EditableEdgeType, PointData } from './common';
import { useDebounce } from '@uidotdev/usehooks';
import { u } from 'msw/lib/glossary-de6278a9';

/**
 * EditableEdge renders an edge as a polyline going from the source to the target
 * via one or more control points. Each control point is drawn as a draggable circle.
 */
const EditableEdge: React.FC<EdgeProps<EditableEdgeType>> = ({
  id,
  selected,
  source,
  sourceX,
  sourceY,
  target,
  targetX,
  targetY,
  markerEnd,
  label,
}) => {
  const { 
    fullConfig, 
    scheduleUpdate,
    startEditEdgeProperties
  } = useBuilderData();

  const shouldShowPoints = useStore((store) => {
    const sourceNode = store.nodeLookup.get(source)!;
    const targetNode = store.nodeLookup.get(target)!;
    return selected || sourceNode.selected || targetNode.selected;
  });

  const positioningMetadata = fullConfig.positioningMetadata;
  const edgePointsByEdgeId: Record<string, PointData[]> = positioningMetadata?.edgePointsByEdgeId || {};
  const savedControlPoints = edgePointsByEdgeId[id] || [];

  const edgeLabelStartAtPointByEdgeId: Record<string, number> = positioningMetadata?.edgeLabelStartAtPointByEdgeId || {};
  const savedLabelStartAtPoint = edgeLabelStartAtPointByEdgeId[id] || 0;

  // Hold the (possibly updated) control points in state.
  const [controlPoints, setControlPoints] = useState<PointData[]>(savedControlPoints);
  const controlPointsDebounced = useDebounce(controlPoints, 500);

  // Build an array of points: start with the source, then each control point, then the target.
  const points: PointData[] = [{ x: sourceX, y: sourceY }, ...controlPoints, { x: targetX, y: targetY }];

  // Generate a path string (a polyline) connecting the points.
  const d = points
    .map((point, i) => (i === 0 ? `M ${point.x},${point.y}` : `L ${point.x},${point.y}`))
    .join(' ');

  let startPoint: PointData = points[savedLabelStartAtPoint];
  let endPoint: PointData = points[savedLabelStartAtPoint + 1];

  const labelX = (startPoint.x + endPoint.x) / 2;
  const labelY = (startPoint.y + endPoint.y) / 2;

  useEffect(() => {
    // means we've added or removed control points and need to reset
    if (!savedControlPoints.every(
      (scp) => controlPoints.find(cp => cp.id === scp.id)
    )) {
      setControlPoints(savedControlPoints);
    }
  }, [savedControlPoints]);

  // Update the edge data when the control points change.
  useEffect(() => {
    const positioningMetadata = fullConfig?.positioningMetadata ?? {};

    const newEdgePointsByEdgeId: Record<string, PointData[]> = {
      ...(positioningMetadata?.edgePointsByEdgeId || {})
    };

    // only update the points, don't add new ones
    newEdgePointsByEdgeId[id] = savedControlPoints.map((point, i) => {
      // if the point doesn't have an id, add one
      if (!point.id) {
        return { 
          ...point,
          id: window.crypto.randomUUID()
        };
      }
      // find the matching point using the id
      let newPoint = controlPointsDebounced.find((p) => p.id === point.id);
      return newPoint ? { ...newPoint } : point;
    });
    
    const newConfig = JSON.parse(JSON.stringify(fullConfig)) as Root;
    if (!newConfig.positioningMetadata) {
      newConfig.positioningMetadata = {};
    }
    newConfig.positioningMetadata.edgePointsByEdgeId = newEdgePointsByEdgeId;

    scheduleUpdate(newConfig);
  }, [
    id, 
    controlPointsDebounced
  ]);

  // Handler for when a control point starts being dragged.
  const handleDrag = useCallback(
    (index: number, event: React.MouseEvent<SVGCircleElement, MouseEvent>) => {
      // Stop event propagation so that other elements (like the node drag) don’t interfere.
      event.stopPropagation();
      const svg = event.currentTarget.ownerSVGElement;
      if (!svg) return;

      // Define a mousemove handler to update the control point position.
      const onMouseMove = (moveEvent: MouseEvent) => {
        // Create an SVG point from the mouse position.
        const pt = svg.createSVGPoint();
        pt.x = moveEvent.clientX;
        pt.y = moveEvent.clientY;
        // Transform the mouse coordinates into the SVG coordinate space.
        const inverseMatrix = svg.getScreenCTM()?.inverse();
        if (!inverseMatrix) return;
        const svgPoint = pt.matrixTransform(inverseMatrix);

        // Update the position of the dragged control point.
        setControlPoints((prevPoints) => {
          const prevPointId = prevPoints[index].id;
          const newPoints = [...prevPoints];
          newPoints[index] = {
            id: prevPointId, 
            x: svgPoint.x, 
            y: svgPoint.y 
          };
          return newPoints;
        });
      };

      // Remove the event listeners when the drag ends.
      const onMouseUp = () => {
        document.removeEventListener('mousemove', onMouseMove);
        document.removeEventListener('mouseup', onMouseUp);
      };

      document.addEventListener('mousemove', onMouseMove);
      document.addEventListener('mouseup', onMouseUp);
    },
    []
  );

  const handleDoubleClick = useCallback(() => {
    startEditEdgeProperties(
      id, 
      savedControlPoints, 
      savedLabelStartAtPoint,
      {
        sourceX,
        sourceY,
        targetX,
        targetY,
      }
    );
  }, [
    id, 
    savedControlPoints, 
    savedLabelStartAtPoint,
    sourceX,
    sourceY,
    targetX,
    targetY,
    startEditEdgeProperties
  ]);

  return (
    <>
      {/* Render the edge path */}
      <path
        id={id}
        style={{
          stroke: (selected ? '#000' : '#ccc'),
          strokeWidth: 2,
          fill: 'none',
        }}
        className="react-flow__edge-path"
        d={d}
        markerEnd={markerEnd}
        onDoubleClick={handleDoubleClick}
      />

      {/* Render the edge label */}
      {label && (
        <EdgeLabelRenderer>
          <div
            style={{
              position: 'absolute',
              transform: `translate(-50%, -50%) translate(${labelX}px,${labelY}px)`,
              background: '#f0f0f0',
              padding: '4px 8px',
              borderRadius: 12,
              border: '1px solid #ccc',
              fontSize: 12,
              fontWeight: 500,
              color: '#333',
              display: 'flex',
              justifyContent: "space-between",
              alignItems: "center",
              opacity: 0.8,
              gap: '4px'
            }}
            className="nodrag nopan"
          >
            <Typography>
              {label}
            </Typography>
          </div>
        </EdgeLabelRenderer>
      )}
      {shouldShowPoints && (
        <>
          {/* Render each control point as a draggable circle */}
          {controlPoints.map((point, index) => (
            <circle
              key={index}
              cx={point.x}
              cy={point.y}
              r={2}
              stroke="#000000"
              strokeWidth={6}
              style={{ cursor: 'pointer' }}
              onMouseDown={(event) => handleDrag(index, event)}
            />
          ))}
        </>
      )}
    </>
  );
};

export default memo(EditableEdge);
