import React, { useContext, PropsWithChildren, useMemo, memo, useCallback, useState, useEffect, useRef } from 'react';
import { BuilderHolder } from '../../../types/builder';
import { useCounter, useDebounce } from '@uidotdev/usehooks';
import { RJSFSchema } from '@rjsf/utils';
import axios from 'axios';
import { Root } from '../../../types/builderv2.generated';
import { useSnackbar } from 'notistack';
import { useUpdateBuilderDocumentMutation } from '../../../redux/services/builder';
import InsertIntoModuleModal from './InsertIntoModuleModal';
import EditModuleDataModal from './EditModuleDataModal';
import EditNodeModal from './EditNodeModal';
import { useBuilderDocument } from '../SpecBuilder/CustomCommentaryWidget/context';
import { PointData, SourceAndTargetInfo } from './common';
import EditEdgeModal from './EditEdgeModal';
import EditModuleModal from './EditModuleModal';
import EditTableModuleModal from './EditTableModuleModal';

interface BuilderDataHookData {
  builderHolder: BuilderHolder;
  fullConfig: Root;
  schema: RJSFSchema | null;
  guidanceErrors: string[];
  fulfillmentErrors: string[];
  startInsertIntoModule: (moduleIds: string[]) => void;
  startEditDataForModule: (moduleIds: string[]) => void;
  startEditNode: (moduleIds: string[], questionId: string) => void;
  startEditModule: (moduleIds: string[]) => void;
  startEditTableModule: (moduleIds: string[]) => void;
  scheduleUpdate: (newFullConfig: Root) => void;
  startEditEdgeProperties: (
    edgeId: string,
    controlPoints: PointData[],
    edgeLabelStartAtPoint: number,
    sourceAndTargetInfo: SourceAndTargetInfo
  ) => void;
}

type BuilderDataHook = () => BuilderDataHookData;

const Context = React.createContext({});

export const useBuilderData: BuilderDataHook = () => {
  return useContext(Context) as BuilderDataHookData;
}

interface BuilderModalState {
  action?: (
    "INSERTING_INTO_MODULE" |
    "EDITING_MODULE_DATA" |
    "EDITING_NODE" |
    "EDITING_EDGE_PROPERTIES" |
    "EDITING_MODULE" |
    "EDITING_TABLE_MODULE"
  );
  moduleIds?: string[];
  nodeId?: string;
  edgeId?: string;
  controlPoints?: PointData[];
  edgeLabelStartAtPoint?: number;
  sourceAndTargetInfo?: SourceAndTargetInfo;
}

interface BuilderDataContainerProps { }

const BuilderDataContainer: React.FC<PropsWithChildren<BuilderDataContainerProps>> = ({
  children
}) => {
  const { builderDocument } = useBuilderDocument();
  const [builderModalState, setBuilderModalState] = useState<BuilderModalState>({});
  const [schema, setSchema] = useState<RJSFSchema | null>(null);
  const [guidanceErrors, setGuidanceErrors] = useState<string[]>([]);
  const [fulfillmentErrors, setFulfillmentErrors] = useState<string[]>([]);

  const { enqueueSnackbar } = useSnackbar();
  const [updateBuilderDocument, updateBuilderDocumentResult] = useUpdateBuilderDocumentMutation();

  const [fullConfig, setFullConfig] = useState<Root>(builderDocument.configuration as Root);
  const fullConfigDebounced = useDebounce(fullConfig, 2000);

  const builderHolder = useMemo(() => {
    if (fullConfig) {
      return new BuilderHolder(fullConfig);
    }
  }, [fullConfig]);

  // we are inserting something into a valid module
  const startInsertIntoModule = useCallback((moduleIds: string[]) => {
    setBuilderModalState({
      action: "INSERTING_INTO_MODULE",
      moduleIds: moduleIds
    });
  }, [setBuilderModalState]);

  const startEditDataForModule = useCallback((moduleIds: string[]) => {
    setBuilderModalState({
      action: "EDITING_MODULE_DATA",
      moduleIds: moduleIds
    });
  }, [setBuilderModalState]);

  const startEditNode = useCallback((moduleIds: string[], nodeId: string) => {
    setBuilderModalState({
      action: "EDITING_NODE",
      moduleIds: moduleIds,
      nodeId: nodeId
    });
  }, [setBuilderModalState]);

  const startEditEdgeProperties = useCallback(
    (
      edgeId: string,
      controlPoints: PointData[],
      edgeLabelStartAtPoint: number,
      sourceAndTargetInfo: SourceAndTargetInfo
    ) => {
      setBuilderModalState({
        action: "EDITING_EDGE_PROPERTIES",
        edgeId: edgeId,
        controlPoints: controlPoints,
        edgeLabelStartAtPoint: edgeLabelStartAtPoint,
        sourceAndTargetInfo: sourceAndTargetInfo
      });
    },
    [setBuilderModalState]
  );

  const startEditModule = useCallback((moduleIds: string[]) => {
    setBuilderModalState({
      action: "EDITING_MODULE",
      moduleIds: moduleIds
    });
  }, [setBuilderModalState]);

  const startEditTableModule = useCallback((moduleIds: string[]) => {
    setBuilderModalState({
      action: "EDITING_TABLE_MODULE",
      moduleIds: moduleIds
    });
  }, [setBuilderModalState]);

  const scheduleUpdate = useCallback((newFullConfig: Root) => {
    setFullConfig(newFullConfig);
  }, [setFullConfig]);

  useEffect(() => {
    updateBuilderDocument({
      id: builderDocument.id,
      configuration: fullConfigDebounced
    });
  }, [fullConfigDebounced]);

  useEffect(() => {
    axios.get(
      `${window.__RUNTIME_CONFIG__.API_ENDPOINT}/schemas/builderv2.schema.json`,
      { withCredentials: true }
    ).then((res) => {
      const resData = res.data;
      setSchema(resData as RJSFSchema);
    }).catch(() => { });
  }, []);

  useEffect(() => {
    if (updateBuilderDocumentResult.isSuccess) {
      enqueueSnackbar("Automation Saved", {
        key: "automation-saved",
        preventDuplicate: true,
        variant: "info",
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'center'
        }
      });
    } else if (updateBuilderDocumentResult.isError) {
      let errorMessages = (updateBuilderDocumentResult.error as any)['data'];
      let fErrors = errorMessages['fulfillment_errors'];
      let gErrors = errorMessages['guidance_errors'];
      setFulfillmentErrors(fErrors.map((e: any) => JSON.stringify(e)));
      setGuidanceErrors(gErrors);
      enqueueSnackbar("There were errors in the automation", {
        key: "automation-saved",
        preventDuplicate: true,
        variant: "error",
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'center'
        }
      });
    }
  }, [updateBuilderDocumentResult]);

  return (
    <Context.Provider
      value={{
        builderHolder: builderHolder,
        fullConfig: fullConfig,
        schema: schema,
        guidanceErrors: guidanceErrors,
        fulfillmentErrors: fulfillmentErrors,
        startInsertIntoModule: startInsertIntoModule,
        startEditDataForModule: startEditDataForModule,
        startEditNode: startEditNode,
        startEditEdgeProperties: startEditEdgeProperties,
        startEditModule: startEditModule,
        startEditTableModule: startEditTableModule,
        scheduleUpdate
      }}
    >
      {(fullConfig.topModule
        && schema
        && builderModalState.action === "INSERTING_INTO_MODULE") && (
          <InsertIntoModuleModal
            parentModule={
              (builderHolder && builderModalState.moduleIds) ?
                builderHolder?.getModule(builderModalState.moduleIds) :
                undefined
            }
            initialTopModule={fullConfig.topModule}
            onUpdate={(tm) => {
              const newConfig = JSON.parse(JSON.stringify(fullConfig)) as Root;
              newConfig.topModule = tm;
              scheduleUpdate(newConfig);
            }}
            onClose={() => setBuilderModalState({})}
          />
        )}
      {(fullConfig.topModule
        && schema
        && builderModalState.action === "EDITING_MODULE_DATA") && (
          <EditModuleDataModal
            fullSchema={schema}
            parentModule={
              (builderHolder && builderModalState.moduleIds) ?
                builderHolder?.getModule(builderModalState.moduleIds) :
                undefined
            }
            initialTopModule={fullConfig.topModule}
            onUpdate={(tm) => {
              const newConfig = JSON.parse(JSON.stringify(fullConfig)) as Root;
              newConfig.topModule = tm;
              scheduleUpdate(newConfig);
            }}
            onClose={() => setBuilderModalState({})}
          />
        )}
      {(fullConfig.topModule
        && schema
        && builderModalState.action === "EDITING_NODE"
        && builderModalState.nodeId) && (
          <EditNodeModal
            nodeId={builderModalState.nodeId}
            fullSchema={schema}
            parentModule={
              (builderHolder && builderModalState.moduleIds) ?
                builderHolder?.getModule(builderModalState.moduleIds) :
                undefined
            }
            initialTopModule={fullConfig.topModule}
            onUpdate={(tm) => {
              const newConfig = JSON.parse(JSON.stringify(fullConfig)) as Root;
              newConfig.topModule = tm;
              scheduleUpdate(newConfig);
            }}
            onClose={() => setBuilderModalState({})}
          />
        )}
      {(fullConfig.topModule
        && schema
        && builderModalState.action === "EDITING_EDGE_PROPERTIES"
        && builderModalState.edgeId
        && builderModalState.sourceAndTargetInfo
        && builderModalState.controlPoints
        && builderModalState.edgeLabelStartAtPoint !== undefined) && (
          <EditEdgeModal
            fullSchema={schema}
            parentModule={
              (builderHolder && builderModalState.moduleIds) ?
                builderHolder?.getModule(builderModalState.moduleIds) :
                undefined
            }
            initialTopModule={fullConfig.topModule}
            initialSourceAndTargetInfo={builderModalState.sourceAndTargetInfo}
            initialControlPoints={builderModalState.controlPoints}
            intitialEdgeLabelStartAtPoint={builderModalState.edgeLabelStartAtPoint}
            onUpdatePositioningData={(controlPoints, edgeLabelStartAtPoint) => {
              if (!builderModalState.edgeId) {
                return;
              }

              const newConfig = JSON.parse(JSON.stringify(fullConfig)) as Root;
              
              const positioningMetadata = fullConfig?.positioningMetadata ?? {};
              const newEdgePointsByEdgeId: Record<string, PointData[]> = {
                ...(positioningMetadata?.edgePointsByEdgeId || {})
              };
              const newEdgeLabelStartAtPointByEdgeId: Record<string, number> = {
                ...(positioningMetadata?.edgeLabelStartAtPointByEdgeId || {})
              };

              newEdgePointsByEdgeId[builderModalState.edgeId] = controlPoints;
              newEdgeLabelStartAtPointByEdgeId[builderModalState.edgeId] = edgeLabelStartAtPoint;

              if (!newConfig.positioningMetadata) {
                newConfig.positioningMetadata = {};
              }
              newConfig.positioningMetadata.edgePointsByEdgeId = newEdgePointsByEdgeId;
              newConfig.positioningMetadata.edgeLabelStartAtPointByEdgeId = newEdgeLabelStartAtPointByEdgeId;
              scheduleUpdate(newConfig);
            }}
            onClose={() => setBuilderModalState({})}
          />
       )}
       {(fullConfig.topModule
        && schema
        && builderModalState.action === "EDITING_MODULE") && (
          <EditModuleModal
            fullSchema={schema}
            parentModule={
              (builderHolder && builderModalState.moduleIds) ?
                builderHolder?.getModule(builderModalState.moduleIds) :
                undefined
            }
            initialTopModule={fullConfig.topModule}
            onUpdate={(tm) => {
              const newConfig = JSON.parse(JSON.stringify(fullConfig)) as Root;
              newConfig.topModule = tm;
              scheduleUpdate(newConfig);
            }}
            onClose={() => setBuilderModalState({})}
          />
        )}
        {(fullConfig.topModule
        && schema
        && builderModalState.action === "EDITING_TABLE_MODULE") && (
          <EditTableModuleModal
            fullSchema={schema}
            parentModule={
              (builderHolder && builderModalState.moduleIds) ?
                builderHolder?.getModule(builderModalState.moduleIds) :
                undefined
            }
            initialTopModule={fullConfig.topModule}
            onUpdate={(tm) => {
              const newConfig = JSON.parse(JSON.stringify(fullConfig)) as Root;
              newConfig.topModule = tm;
              scheduleUpdate(newConfig);
            }}
            onClose={() => setBuilderModalState({})}
          />
        )}
      {children}
    </Context.Provider>
  );
};

export default memo(BuilderDataContainer);
