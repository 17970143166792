import { Edge, Node, Position } from '@xyflow/react';
import dagre from 'dagre';

const dagreGraph = new dagre.graphlib.Graph();
dagreGraph.setDefaultEdgeLabel(() => ({}));

export const getLayoutedElements = (nodes: ResizableNodeType[], edges: EditableEdgeType[]) => {
    dagreGraph.setGraph({ rankdir: 'TB' });

    nodes.forEach((node) => {
        dagreGraph.setNode(node.id, { width: node.width, height: node.height });
    });

    edges.forEach((edge) => {
        dagreGraph.setEdge(edge.source, edge.target);
    });

    dagre.layout(dagreGraph);

    nodes.forEach((node) => {
        const nodeWithPosition = dagreGraph.node(node.id);
        if (!node.targetPosition && !node.sourcePosition) {
            node.targetPosition = Position.Top;
            node.sourcePosition = Position.Bottom;
        }

        // We are shifting the dagre node position (anchor=center center) to the top left
        // so it matches the React Flow node anchor point (top left).
        let nodeWidth = node.width;
        let nodeHeight = node.height;
        if (!!nodeWidth && !!nodeHeight && !node.position) {
            node.position = {
                x: nodeWithPosition.x - nodeWidth / 2,
                y: nodeWithPosition.y - nodeHeight / 2,
            };
        }
        return node;
    });

    return { nodes, edges };
};

export type ResizableNodeType = Node<
    {
        moduleIds: string[];
        nodeId?: string;
        nodeType?: "question" | "module" | "end";
    },
    'resizable'
>;

export type PointData = {
    id?: string;
    x: number;
    y: number
};
export type EditableEdgeType = Edge<{}, 'editable-edge'>;

export interface NodeDetails {
    xPos: number;
    yPos: number;
    height?: number | null;
    width?: number | null;
}

export type PositioningData = {
    exactPositionsAndSizesByNodeId?: Record<string, NodeDetails>;
    edgePointsByEdgeId?: Record<string, PointData[]>;
    edgeLabelStartAtPointByEdgeId?: Record<string, number>;
}

export interface SourceAndTargetInfo {
    sourceX: number;
    sourceY: number;
    targetX: number;
    targetY: number;
};