import React, { memo, useEffect } from "react";
import { useReadOnlyBuilderData } from "../ReadOnlyBuilderData/ReadOnlyBuilderData";
import { Bookmark, BookmarkGuidance } from "./common";
import { Module, Commentary } from "../../types/builderv2.generated";
import { useGuidanceData } from "../GuidanceData";

interface QuestionBookmarkDataLoaderProps {
    onLoaded: (bookmarks: Bookmark[]) => void;
}

const QuestionBookmarkDataLoader = ({
    onLoaded
}: QuestionBookmarkDataLoaderProps) => {
    const { aliasLookup } = useGuidanceData();
    const { builderHolder } = useReadOnlyBuilderData();

    useEffect(() => {
        const bookmarks: Bookmark[] = [];

        const collectGuidance = (c: Commentary): BookmarkGuidance[] => {
            const guidance: BookmarkGuidance[] = [];
            for (const container of c) {
                if (container.type === "custom-guidance-container" 
                    && container.nested 
                    && container.value
                ) {
                    const guidanceContentIds: string[] = [];
                    for (const item of container.nested) {
                        if (item.type === "custom-guidance-item" && item.value) {
                            if (aliasLookup[item.value]) {
                                guidanceContentIds.push(aliasLookup[item.value]);
                            } else {
                                guidanceContentIds.push(item.value);
                            }
                        } else {

                            throw Error(`unknown item, ${JSON.stringify(item)}`);
                        }
                    }
                    guidance.push({
                        guidanceId: container.value,
                        guidanceContentIds
                    });
                } else {
                    throw Error(`unknown container, ${JSON.stringify(container)}`);
                }
            }
            return guidance;
        };

        const addForModules = (m: Module, path: string[]) => {
            if (m.uiFulfillment?.fulfillmentType === "graph") {
                if (m.uiFulfillment.nodes === undefined) {
                    return;
                }

                for (const n of m.uiFulfillment.nodes) {
                    if (n.nodeType === "module") {
                        const praxiModule = builderHolder.getModule([...path, m.id, n.moduleId]);
                        if (!praxiModule) {
                            throw Error(`module with moduleId=${[...path, m.id, n.moduleId]} doesn't exist`)
                        }
                        addForModules(praxiModule, [...path, m.id]);
                    } else if (n.nodeType === "question" 
                        && n.questionLabel 
                        && (n.questionCommentary && n.questionCommentary.length > 0)
                    ) {
                        bookmarks.push({
                            type: "QUESTION",
                            label: n.questionLabel,
                            guidance: collectGuidance(n.questionCommentary)
                        });
                    }
                }
            } else if (m.uiFulfillment?.fulfillmentType === "table" 
                && m.uiFulfillment.tableLabel 
                && (m.uiFulfillment.commentary && m.uiFulfillment.commentary.length > 0)
            ) {
                bookmarks.push({
                    type: "QUESTION",
                    label: m.uiFulfillment.tableLabel,
                    guidance: collectGuidance(m.uiFulfillment.commentary)
                });
            }
        }

        if (builderHolder.builder.topModule) {            
            addForModules(builderHolder.builder.topModule, []);
        }
        onLoaded(bookmarks);
    }, [
        builderHolder,
        aliasLookup,
        onLoaded
    ]);

    return null;
};

export default memo(QuestionBookmarkDataLoader);