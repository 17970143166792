import React, { useEffect, useMemo, memo } from "react";
import { Typography } from "@mui/material/";
import { useTakerState } from "../../../../containers/TakerDocumentState/TakerDocumentState";
import { DocumentHighlight } from "../../../../types/taker/documentkeyterms.generated";
import { useHighlightsWrapper, useKeyTermsWrapper } from "../../../../containers/WidgetWrapper/wrapper";
import BoxWithHoverBg from "./BoxWithHoverBg";

interface DocumentHighlightMetaProps {
    commentMetadata: Record<string, any>;
    highlightOnInit: boolean;
    onClick: () => void;
}


function DocumentHighlightMeta({
    commentMetadata,
    onClick,
    highlightOnInit = false
}: DocumentHighlightMetaProps) {
    const {
        takerDocumentUploads
    } = useTakerState();
    const { mutateState } = useKeyTermsWrapper();
    const { mutateHighlightsState } = useHighlightsWrapper();

    const {
        highlightTextContent,
        lexicalDocumentIdentifier,
        pageIndex,
        documentHighlights
    } = commentMetadata;

    let targetGroupName = null;
    let targetDocumentName = null;
    if (takerDocumentUploads) {
        for (const { name: groupName, fileUpload } of takerDocumentUploads) {
            for (const { id, label: fileName } of fileUpload.fileUploadItems) {
                if (id === lexicalDocumentIdentifier) {
                    targetGroupName = groupName;
                    targetDocumentName = fileName;
                    break;
                }
            }
        }
    }

    const firstElementID = useMemo(() => {
        const typedHighlights = documentHighlights as DocumentHighlight[];
        if (typedHighlights && typedHighlights.length > 0) {
            return typedHighlights[0].elementId;
        }
    }, [documentHighlights]);

    useEffect(() => {
        if (highlightOnInit
            && pageIndex !== undefined
            && firstElementID !== undefined
            && documentHighlights !== undefined
            && lexicalDocumentIdentifier !== undefined) {
            if (firstElementID) {
                mutateState({
                    scrollToPage: { docId: lexicalDocumentIdentifier, page: pageIndex },
                    scrollToElementID: { docId: lexicalDocumentIdentifier, elementId: firstElementID },
                    targetFileUploadItemIds: [lexicalDocumentIdentifier]
                });
            } else {
                mutateState({
                    scrollToPage: { docId: lexicalDocumentIdentifier, page: pageIndex },
                    targetFileUploadItemIds: [lexicalDocumentIdentifier]
                });
            }
            mutateHighlightsState({ navigateHighlightElementIDs: documentHighlights })
        }
    }, [
        highlightOnInit,
        pageIndex,
        firstElementID,
        documentHighlights,
        lexicalDocumentIdentifier
    ]);

    const highlightGroupDocumentTitle = `${targetGroupName} - ${targetDocumentName} - Page ${pageIndex + 1}`;
    return (
        <BoxWithHoverBg
            data-testid="document-highlight-meta"
            width="100%"
            onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
                if (firstElementID) {
                    mutateState({
                        scrollToPage: { docId: lexicalDocumentIdentifier, page: pageIndex },
                        scrollToElementID: { docId: lexicalDocumentIdentifier, elementId: firstElementID },
                        targetFileUploadItemIds: [lexicalDocumentIdentifier]
                    });
                } else {
                    mutateState({
                        scrollToPage: { docId: lexicalDocumentIdentifier, page: pageIndex },
                        targetFileUploadItemIds: [lexicalDocumentIdentifier]
                    });
                }
                mutateHighlightsState({
                    navigateHighlightElementIDs: documentHighlights
                })
                onClick();
            }}
        >
            {(targetGroupName && targetDocumentName) && (
                <Typography
                    variant="body2"
                    color="text.primary"
                    noWrap
                    data-testid="comments-drawer-group-doc-title"
                >
                    <strong>{highlightGroupDocumentTitle}</strong>
                </Typography>
            )}
            <Typography
                variant="body2"
                color="text.secondary"
                noWrap
                data-testid="comments-drawer-highlight-text"
            >
                {highlightTextContent}
            </Typography>
        </BoxWithHoverBg>
    );
}

export default memo(DocumentHighlightMeta);
