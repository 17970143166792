import { useEffect } from "react";
import {
  Box,
  Typography
} from "@mui/material/";
import SideNav from "../../components/navigation/SideNav";
import { useSelector } from "react-redux";
import { RootReducerType } from "../../redux/models/reduxTypes";
import { useUpdateCurrentUserMutation } from "../../redux/services/currentUser";
import { useSnackbar } from "notistack";
import UserSettingsForm from "../../components/form/UserSettingsForm";


const UserSettings = () => {
  const { enqueueSnackbar } = useSnackbar();
  const [updateCurrentUserMutation, updateCurrentUserMutationRes] = useUpdateCurrentUserMutation();
  const { user } = useSelector((state: RootReducerType) => state.auth);

  useEffect(() => {
    if (updateCurrentUserMutationRes.isSuccess) {
      enqueueSnackbar("User Updated", {
        variant: 'info',
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'center'
        }
      });
      window.location.reload();
    }
  }, [updateCurrentUserMutationRes]);

  return (
    <SideNav>
      <Box
        sx={{
          padding: 2,
          width: "100%"
        }}
      >
        <Typography variant="h5" sx={{ fontWeight: "bold" }}>
          User Settings
        </Typography>
      </Box>
      <Box
        sx={{
          padding: 2,
          width: "100%",
        }}
      >
        <Box
          sx={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            paddingBottom: 2,
          }}
        >
          <Typography variant="h6">
            User
          </Typography>
        </Box>
        <Box
          sx={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          {user && (
            <UserSettingsForm 
              user={user} 
              isUpdatingUser={updateCurrentUserMutationRes.isLoading}
              onUpdateUser={(newUser) => {
                updateCurrentUserMutation({
                  id: user.id,
                  firstName: newUser.firstName ?? user.firstName,
                  lastName: newUser.lastName ?? user.lastName
                });
              }}
            />
          )}
        </Box>
      </Box>
    </SideNav>
  );
};

export default UserSettings;
