import { mergeRegister } from '@lexical/utils';
import {
  COMMAND_PRIORITY_LOW,
  LexicalEditor,
  SELECTION_CHANGE_COMMAND,
} from 'lexical';
import React, { useCallback, useEffect, useRef } from 'react';
import { Button, ButtonGroup } from '@mui/material';
import { Add } from '@mui/icons-material';
import { getDOMRangeRect, setFloatingElemPosition } from '../common';

import './index.css';

export default function AddPdfLinkFloatingToolbar({
  editor,
  anchorElem,
  onAddHeader,
  onAddContent,
  disabled,
  targetEntryContentTagIds,
  targetEntryHeaderTagIds
}: {
  editor: LexicalEditor;
  anchorElem: HTMLElement;
  onAddHeader: (tagIds: string[] | undefined) => void;
  onAddContent: (tagIds: string[] | undefined) => void;
  disabled: boolean,
  targetEntryContentTagIds: string[] | undefined,
  targetEntryHeaderTagIds: string[] | undefined
}): JSX.Element {
  const popupCharStylesEditorRef = useRef<HTMLDivElement | null>(null);
  
  const getSelectedTagIds = () => {
    const nativeSelection = window.getSelection();
    if (nativeSelection !== null && nativeSelection.rangeCount) {
      const allTagIds = [];
      const anchorNode = nativeSelection.anchorNode;
      if (anchorNode !== null) {
        const parentElement = anchorNode.parentElement;
        if (parentElement) {
          const grandparentElement = parentElement?.parentElement;
          if (grandparentElement && grandparentElement.parentElement) {
            const id = grandparentElement.parentElement.id;
            allTagIds.push(id);
          } 
        }
      }
      return allTagIds;
    }
  };

  function mouseMoveListener(e: MouseEvent) {
    if (
      popupCharStylesEditorRef?.current &&
      (e.buttons === 1 || e.buttons === 3)
    ) {
      if (popupCharStylesEditorRef.current.style.pointerEvents !== 'none') {
        const x = e.clientX;
        const y = e.clientY;
        const elementUnderMouse = document.elementFromPoint(x, y);

        if (!popupCharStylesEditorRef.current.contains(elementUnderMouse)) {
          // Mouse is not over the target element => not a normal click, but probably a drag
          popupCharStylesEditorRef.current.style.pointerEvents = 'none';
        }
      }
    }
  }

  function mouseUpListener(e: MouseEvent) {
    if (popupCharStylesEditorRef?.current) {
      if (popupCharStylesEditorRef.current.style.pointerEvents !== 'auto') {
        popupCharStylesEditorRef.current.style.pointerEvents = 'auto';
      }
    }
  }

  useEffect(() => {
    if (popupCharStylesEditorRef?.current) {
      document.addEventListener('mousemove', mouseMoveListener);
      document.addEventListener('mouseup', mouseUpListener);

      return () => {
        document.removeEventListener('mousemove', mouseMoveListener);
        document.removeEventListener('mouseup', mouseUpListener);
      };
    }
  }, [popupCharStylesEditorRef]);

  const updateTextFormatFloatingToolbar = useCallback(() => {
    const popupCharStylesEditorElem = popupCharStylesEditorRef.current;
    const nativeSelection = window.getSelection();

    if (popupCharStylesEditorElem === null) {
      return;
    }

    const rootElement = editor.getRootElement();
    if (
      nativeSelection !== null &&
      !nativeSelection.isCollapsed &&
      rootElement !== null &&
      rootElement.contains(nativeSelection.anchorNode)
    ) {
      const rangeRect = getDOMRangeRect(nativeSelection, rootElement);
      setFloatingElemPosition(
        rangeRect,
        popupCharStylesEditorElem,
        anchorElem
      );
    }
  }, [editor, anchorElem]);

  useEffect(() => {
    const scrollerElem = anchorElem.parentElement;

    const update = () => {
      editor.getEditorState().read(() => {
        updateTextFormatFloatingToolbar();
      });
    };

    window.addEventListener('resize', update);
    if (scrollerElem) {
      scrollerElem.addEventListener('scroll', update);
    }

    return () => {
      window.removeEventListener('resize', update);
      if (scrollerElem) {
        scrollerElem.removeEventListener('scroll', update);
      }
    };
  }, [editor, updateTextFormatFloatingToolbar, anchorElem]);

  useEffect(() => {
    editor.getEditorState().read(() => {
      updateTextFormatFloatingToolbar();
    });
    return mergeRegister(
      editor.registerUpdateListener(({ editorState }) => {
        editorState.read(() => {
          updateTextFormatFloatingToolbar();
        });
      }),
      editor.registerCommand(
        SELECTION_CHANGE_COMMAND,
        () => {
          updateTextFormatFloatingToolbar();
          return false;
        },
        COMMAND_PRIORITY_LOW,
      ),
    );
  }, [editor, updateTextFormatFloatingToolbar]);

  return (
    <div ref={popupCharStylesEditorRef} className="floating-text-format-popup">
      <ButtonGroup
        data-testid='pdf-link-add-button-group'
        disableElevation
        disabled={disabled}
        size="small"
        variant="contained"
        color="inherit"
      >
        <Button 
          data-testid='pdf-link-to-header-button' 
          endIcon={<Add />}
          onClick={() => {
            onAddHeader(getSelectedTagIds());
          }}
        >
          Header
        </Button>
        <Button 
          data-testid='pdf-link-to-content-button' 
          endIcon={<Add />}
          onClick={() => {
            onAddContent(getSelectedTagIds());
          }}
          disabled={targetEntryContentTagIds === undefined || targetEntryHeaderTagIds?.length === 0}
        >
          Content
        </Button>
      </ButtonGroup>
    </div>
  );
}