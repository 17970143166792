import React, { useState } from "react";
import Box from '@mui/material/Box';
import { SimpleModalWrapper } from "../../../components/dialog/wrappers/simpleModalWrapper";
import {  Module } from "../../../types/builderv2.generated";
import { Accordion, AccordionDetails, AccordionSummary, Button, Collapse, Grid, Tab, Table, TableBody, TableContainer, Tabs, TextField, Typography, } from "@mui/material";
import { RJSFSchema } from '@rjsf/utils';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import { ArrowDownward, ArrowRight, Edit } from "@mui/icons-material";
import NodesEditor from "../SpecBuilder/nodes";
import VariablesEditor from "../SpecBuilder/variables";
import EdgesEditor from "../SpecBuilder/edges";
import { CrudButtonGroup, buttonItem } from "../../../components/buttons/crudButtonGroup";

interface ModuleRowProps {
    praxiModule: Module;
    level: number;
}

const ModuleRow = ({
    praxiModule,
    level
}: ModuleRowProps) => {
    const [open, setOpen] = useState<boolean>(true);
    const buttons: buttonItem[] = [
        {
            disabled: (praxiModule.nestedModules?.length === 0),
            icon: (
                open ?
                    <ArrowDownward fontSize="small" /> :
                    <ArrowRight fontSize="small" />
            ),
            handleClick: () => {
                setOpen(!open);
            }
        }
    ];

    return (
        <React.Fragment>
            <TableRow
                sx={{
                    '& > *': { borderBottom: 'unset' },
                    "&:hover": {
                        bgcolor: "rgba(0, 0, 0, 0.04)",
                    }
                }}
            >
                <TableCell colSpan={level} />
                <TableCell component="th" scope="row">
                    {praxiModule.id}
                </TableCell>
                <TableCell component="th" scope="row">
                    {praxiModule.uiFulfillment?.fulfillmentType}
                </TableCell>
                <TableCell scope="row">
                    <CrudButtonGroup buttons={buttons} />
                </TableCell>
            </TableRow>
            <Collapse in={open}>
                {praxiModule.nestedModules?.map(pm => (
                    <TableRow>
                        <TableCell colSpan={3 + level}>
                            <ModuleRow
                                level={level + 1}
                                praxiModule={pm}
                            />
                        </TableCell>
                    </TableRow>
                ))}
            </Collapse>
        </React.Fragment>
    );
};

interface EditModuleModalProps {
    fullSchema: RJSFSchema;
    parentModule: Module | undefined;
    initialTopModule: Module;
    onUpdate: (b: Module) => void;
    onClose: () => void;
}

const EditModuleModal = ({
    fullSchema,
    parentModule,
    initialTopModule,
    onUpdate,
    onClose
}: EditModuleModalProps) => {

    const searchModuleHelper = (moduleId: string, module: Module): Module | null => {
        if (moduleId === module.id) {
            return module;
        }

        if (module.nestedModules) {
            for (const m of module.nestedModules) {
                let res = searchModuleHelper(moduleId, m);
                if (!!res) {
                    return res;
                }
            }
        }
        return null;
    };

    const addModuleHelper = (moduleId: string, moduleToAdd: Module, modules: Module[]) => {
        const newModules: Module[] = [];
        for (const m of modules) {
            const newModule: Module = JSON.parse(JSON.stringify(m));
            if (!newModule.nestedModules) {
                newModule.nestedModules = [];
            }
            newModule.nestedModules = addModuleHelper(moduleId, moduleToAdd, newModule.nestedModules);
            if (m.id === moduleId) {
                newModule.nestedModules.push(JSON.parse(JSON.stringify(moduleToAdd)));
            }
            newModules.push(newModule);
        }
        return newModules;
    };

    const searchParentModuleHelper = (moduleId: string, module: Module): Module | null => {
        if (module.nestedModules) {
            for (const m of module.nestedModules) {
                if (m.id === moduleId) {
                    return module;
                } else {
                    let res = searchParentModuleHelper(moduleId, m);
                    if (!!res) {
                        return res;
                    }
                }
            }
        }
        return null;
    };

    const updateModuleHelper = (moduleToUpdate: Module, modules: Module[]) => {
        const newModules: Module[] = [];
        for (const m of modules) {
            const newModule: Module = JSON.parse(JSON.stringify(m));
            if (m.id === moduleToUpdate.id) {
                newModules.push(JSON.parse(JSON.stringify(moduleToUpdate)));
            } else if (newModule.nestedModules) {
                newModule.nestedModules = updateModuleHelper(moduleToUpdate, newModule.nestedModules);
                newModules.push(newModule);
            } else {
                newModules.push(newModule);
            }
        }
        return newModules;
    };

    const updateModule = (m: Module) => {
        const newTopModule = updateModuleHelper(m, [initialTopModule])[0];
        onUpdate(newTopModule);
    };

    if (!parentModule) {
        return null;
    }

    return (
        <SimpleModalWrapper
            headerText={`In ${parentModule.displayName}`}
            open
            handleClose={onClose}
            maxWidth='md'
        >
            <Grid container rowGap={1}>
                <Grid item xs={12}>
                    <Accordion>
                        <AccordionSummary
                            aria-controls="modules-content"
                            id="modules-header"
                        >
                            Nested Modules
                        </AccordionSummary>
                        <AccordionDetails>
                            <TableContainer>
                                <Table>
                                    <TableBody>
                                        {parentModule.nestedModules?.map(pm => (
                                            <TableRow>
                                                <TableCell>
                                                    <ModuleRow
                                                        level={0}
                                                        praxiModule={pm}
                                                    />
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </AccordionDetails>
                    </Accordion>
                </Grid>
                <Grid item xs={12}>
                    <Accordion>
                        <AccordionSummary
                            aria-controls="variables-content"
                            id="variables-header"
                        >
                            Variables
                        </AccordionSummary>
                        <AccordionDetails>
                            <VariablesEditor
                                initialVariables={parentModule.variableDefinitions}
                                fullSchema={fullSchema}
                                onUpdate={(vars) => {
                                    let newMod: Module = JSON.parse(JSON.stringify(parentModule));
                                    newMod.variableDefinitions = vars;
                                    updateModule(newMod);
                                }}
                            />
                        </AccordionDetails>
                    </Accordion>
                </Grid>
                {(parentModule.uiFulfillment && parentModule.uiFulfillment.fulfillmentType === "graph") && (
                    <>
                        <Grid item xs={12}>
                            <Accordion>
                                <AccordionSummary
                                    aria-controls="nodes-content"
                                    id="nodes-header"
                                >
                                    Nodes
                                </AccordionSummary>
                                <AccordionDetails>
                                    <NodesEditor
                                        nodes={parentModule.uiFulfillment.nodes || []}
                                        fullSchema={fullSchema}
                                        onUpdate={(nodes) => {
                                            let newMod: Module = JSON.parse(JSON.stringify(parentModule));
                                            if (newMod.uiFulfillment && newMod.uiFulfillment.fulfillmentType === "graph") {
                                                newMod.uiFulfillment.nodes = nodes;
                                                updateModule(newMod);
                                            }
                                        }}
                                    />
                                </AccordionDetails>
                            </Accordion>
                        </Grid>
                        <Grid item xs={12}>
                            <Accordion>
                                <AccordionSummary
                                    aria-controls="edges-content"
                                    id="edges-header"
                                >
                                    Edges
                                </AccordionSummary>
                                <AccordionDetails>
                                    <EdgesEditor
                                        edges={parentModule.uiFulfillment.edges || []}
                                        fullSchema={fullSchema}
                                        onUpdate={(edges) => {
                                            let newMod: Module = JSON.parse(JSON.stringify(parentModule));
                                            if (newMod.uiFulfillment && newMod.uiFulfillment.fulfillmentType === "graph") {
                                                newMod.uiFulfillment.edges = edges;
                                                updateModule(newMod);
                                            }
                                        }}
                                    />
                                </AccordionDetails>
                            </Accordion>
                        </Grid>
                    </>
                )}
            </Grid>
        </SimpleModalWrapper>
    );
}

export default EditModuleModal;