import React, { useEffect } from "react";
import { useAnimate } from "motion/react";
import { stagger } from "motion";


/**
 * Props for the TypedText component.
 */
interface TypedTextProps {
    /**
     * The text to be animated.
     */
    text: string;
    /** 
     * How long each character’s fade-in should last, in seconds 
     * (actual “typing” speed). 
     * @default 0.1
     */
    durationPerChar?: number;
    /**
     * Delay between each character in the stagger, in seconds
     * (you can decouple this from durationPerChar or make them the same).
     * @default 0.05
     */
    staggerDelay?: number;
    /**
     * If true, the typing animation will loop indefinitely.
     * @default false
     */
    loop?: boolean;
    /**
     * Delay before the typing animation restarts when looping, in seconds.
     * @default 0
     */
    loopDelay?: number;
}

const TypedText: React.FC<TypedTextProps> = ({
    text,
    durationPerChar = 0.005,  // 50ms fade per character
    staggerDelay = 0.05,      // 50ms delay between characters
    loop = false,
    loopDelay = 0
}) => {
    const [scope, animate] = useAnimate();

    useEffect(() => {
        let isMounted = true;
        const animateText = () => {
            if (!isMounted) {
                return;
            }

            animate(
                scope.current.querySelectorAll(".char"),
                {
                    opacity: [0, 1] 
                },
                {
                    duration: durationPerChar,
                    delay: stagger(staggerDelay),
                    ease: "linear",
                }
            ).then(() => {
                if (!isMounted) {
                    return;
                }
                
                if (loop) {
                    let repeatDelay = (durationPerChar + staggerDelay) * (text.length) * 1000 + (loopDelay * 1000);
                    setTimeout(animateText, repeatDelay);
                }
            });
        };

        animateText();

        return () => {
            isMounted = false;
        };
    }, [text, durationPerChar, staggerDelay, loop]);

    return (
        <div
            ref={scope}
        >
            {/* Render each character as its own span for individual animation */}
            {text.split("").map((char, index) => (
                <span key={index} className="char" style={{ opacity: 0 }}>
                    {char}
                </span>
            ))}
        </div>
    );
};

export default TypedText;
