import React, { useEffect, useMemo, useRef, memo } from 'react';
import {
    SerializedLexicalNode,
    Spread,
} from 'lexical';
import { DocumentHighlight } from '../../../../types/taker/documentkeyterms.generated';
import styled from '@emotion/styled';

export interface RawHtmlPayload {
    rawHtml: string;
    page: number;
    pageWidth: number;
}
export type SerializedRawHtmlNode = Spread<
    {
        rawHtml: string;
        page: number;
        pageWidth: number;
    },
    SerializedLexicalNode
>;
const SELECTOR_QUERY = 'div.textbox, div.textline, span.word > span'
interface RawHtmlComponentProps {
    innerHtml: string;
    documentHighlights: DocumentHighlight[];
    aiDocumentHighlights: DocumentHighlight[];
    commentDocumentHighlights: DocumentHighlight[];
    navDocumentHighlights: DocumentHighlight[];
    pageScaleFactor: number;
}

interface RGBColor {
    red: number,
    green: number,
    blue: number
}

const TAG_COLOR_LIGHT = { red: 227, green: 255, blue: 0 };
const TAG_COLOR_DARK = { red: 151, green: 161, blue: 0 };
const TAG_AI_COLOR_LIGHT = { red: 187, green: 222, blue: 251 };
const TAG_AI_COLOR_DARK = { red: 144, green: 202, blue: 249 };
const COMMENT_COLOR_LIGHT = { red: 86, green: 255, blue: 0 };
const COMMENT_COLOR_DARK = { red: 42, green: 128, blue: 0 };
const NAV_COLOR_LIGHT = { red: 100, green: 150, blue: 255 };
const MAX_HIGHLIGHT_COUNT = 6.0

const interpolateValue = (x: number, y: number, a: number) => x + (y - x) * (1 - Math.pow(1 - a, 3));
const averageColors = (colors: RGBColor[]) => ({
    red: Math.sqrt(colors.map(c => Math.pow(c.red, 2)).reduce((a, b) => a + b) / colors.length),
    green: Math.sqrt(colors.map(c => Math.pow(c.green, 2)).reduce((a, b) => a + b) / colors.length),
    blue: Math.sqrt(colors.map(c => Math.pow(c.blue, 2)).reduce((a, b) => a + b) / colors.length),
});

function hexToRgb(hex: string) {
    hex = hex.replace("#", "");

    if (hex.length === 3) {
        hex = hex.split("").map(c => c + c).join("");
    }

    return { 
        red: parseInt(hex.substring(0, 2), 16),
        green: parseInt(hex.substring(2, 4), 16),
        blue: parseInt(hex.substring(4, 6), 16)
    } as RGBColor;
}


const getBackgroundForHighlights = (
    highlightsForElement: DocumentHighlight[],
    minColor: RGBColor,
    maxColor: RGBColor
) => {
    const sampleHighlight = highlightsForElement[0];
    if (sampleHighlight.color === "none") {
        return;
    }
    let lerpVal = undefined
    if (sampleHighlight.color) {
        const sampleColor = hexToRgb(sampleHighlight.color);
        minColor = sampleColor;
        maxColor = sampleColor;
        lerpVal = 0;
    } else {
        const numHighlights = highlightsForElement.length;
        lerpVal = (numHighlights - 1) / MAX_HIGHLIGHT_COUNT;
    }
    const red = interpolateValue(minColor.red, maxColor.red, lerpVal);
    const blue = interpolateValue(minColor.blue, maxColor.blue, lerpVal);
    const green = interpolateValue(minColor.green, maxColor.green, lerpVal);
    return {
        red,
        green,
        blue
    } as RGBColor;
};

const getGetAverageRGB = (
    colors: RGBColor[]
) => {
    const { red, green, blue } = averageColors(colors);
    return `rgba(${red},${green},${blue},0.5)`;
};

const RawHtmlComponentV2 = ({
    innerHtml,
    documentHighlights,
    aiDocumentHighlights,
    commentDocumentHighlights,
    navDocumentHighlights,
    pageScaleFactor
}: RawHtmlComponentProps) => {
    const pageRef = useRef<HTMLDivElement>();
    const docHighlightsByElementId = useMemo(() => {
        const map: Record<string, DocumentHighlight[]> = {};
        for (const highlight of documentHighlights) {
            if (!map[highlight.elementId]) {
                map[highlight.elementId] = []
            }
            map[highlight.elementId].push(highlight);
        }
        return map;
    }, [documentHighlights]);

    const aiDocHighlightsByElementId = useMemo(() => {
        const map: Record<string, DocumentHighlight[]> = {};
        for (const highlight of aiDocumentHighlights) {
            if (!map[highlight.elementId]) {
                map[highlight.elementId] = []
            }
            map[highlight.elementId].push(highlight);
        }
        return map;
    }, [aiDocumentHighlights]);

    const commentDocHighlightsByElementId = useMemo(() => {
        const map: Record<string, DocumentHighlight[]> = {};
        for (const highlight of commentDocumentHighlights) {
            if (!map[highlight.elementId]) {
                map[highlight.elementId] = []
            }
            map[highlight.elementId].push(highlight);
        }
        return map;
    }, [commentDocumentHighlights]);

    const navDocHighlightsByElementId = useMemo(() => {
        const map: Record<string, DocumentHighlight[]> = {};
        for (const highlight of navDocumentHighlights) {
            if (!map[highlight.elementId]) {
                map[highlight.elementId] = []
            }
            map[highlight.elementId].push(highlight);
        }
        return map;
    }, [navDocumentHighlights]);

    useEffect(() => {
        if (pageRef.current) {
            const elems = pageRef.current.querySelectorAll(SELECTOR_QUERY) as NodeListOf<HTMLElement>;
            for (const elem of elems) {
                const colors: RGBColor[] = [];
                if (navDocHighlightsByElementId[elem.id]) {
                    colors.push(NAV_COLOR_LIGHT);
                } else {
                    if (docHighlightsByElementId[elem.id]) {
                        const color = getBackgroundForHighlights(
                            docHighlightsByElementId[elem.id],
                            TAG_COLOR_LIGHT,
                            TAG_COLOR_DARK
                        )
                        if (color) {
                            colors.push(color);
                        }
                    }
                    if (aiDocHighlightsByElementId[elem.id]) {
                        const color = getBackgroundForHighlights(
                            aiDocHighlightsByElementId[elem.id],
                            TAG_AI_COLOR_LIGHT,
                            TAG_AI_COLOR_DARK
                        )
                        if (color) {
                            colors.push(color);
                        }
                    }
                    if (commentDocHighlightsByElementId[elem.id]) {
                        const color = getBackgroundForHighlights(
                            commentDocHighlightsByElementId[elem.id],
                            COMMENT_COLOR_LIGHT,
                            COMMENT_COLOR_DARK
                        )
                        if (color) {
                            colors.push(color);
                        }
                    }
                }
                if (colors.length === 0) {
                    elem.style.background = "";
                } else {
                    elem.style.background = getGetAverageRGB(colors);
                }
            }
            const wordContainers = pageRef.current.querySelectorAll("span.word") as NodeListOf<HTMLElement>;
            for (const wordContainer of wordContainers) {
                const innerSpans = wordContainer.querySelectorAll('span');
                // Sum up the widths of the inner spans
                let currentWidth = 0;
                innerSpans.forEach(span => {
                    currentWidth += span.offsetWidth;
                });
                // Desired width
                const desiredWidth = wordContainer.offsetWidth;
                // Calculate the total space to add
                const totalSpaceToAdd = desiredWidth - currentWidth;
                // Count the number of gaps (spaces between words)
                const numberOfGaps = innerSpans.length - 1;
                // Calculate word-spacing value
                const wordSpacing = totalSpaceToAdd / numberOfGaps;
                if (wordSpacing >= 0) {
                    // Apply word-spacing to each span
                    innerSpans.forEach(span => {
                        span.style.wordSpacing = `${wordSpacing}px`;
                    });
                }
                if (totalSpaceToAdd < 0) {
                    let scaleFactor = desiredWidth / currentWidth;
                    scaleFactor = scaleFactor / numberOfGaps
                    innerSpans.forEach(span => {
                        span.style.transform = `scaleX(calc(var(--scale-factor) * ${scaleFactor}))`;
                    });
                }
            }
        }
    }, [pageRef, documentHighlights]);
    const PageDiv = styled.div`
        .page {
            --scale-factor: ${pageScaleFactor};
            position: relative;
            font-family: serif;
            line-height: 1;
            overflow: hidden;
            color: rgba(0, 0, 0, 0);
            pointer-events: none;
        }
        .textbox {
            position: absolute;
            white-space: pre;
            pointer-events: none;
        }
        .textline {
            position: absolute;
            pointer-events: none;
        }
        .line {
            position: absolute;
            color: rgba(0, 0, 0, 0);
            background: transparent;
            pointer-events: none;
        }
        .rect {
            position: absolute;
            color: rgba(0, 0, 0, 0);
            background: transparent;
            border: none !important;
            pointer-events: none;
        }
        .figure {
            position: absolute;
            color: rgba(0, 0, 0, 0);
            pointer-events: none;
        }
        .figure > img{
            opacity: 0 !important;
            pointer-events: none;
        }
        .word {
            position: absolute;
            pointer-events: all;
        }
    `;
    return (
        <PageDiv
            ref={(r) => {
                if (r) {
                    pageRef.current = r;
                }
            }}
            dangerouslySetInnerHTML={{
                __html: innerHtml
            }}
        />
    );
};
export default memo(RawHtmlComponentV2);