import { Box, Typography, ToggleButtonGroup, ToggleButton, IconButton, Toolbar, Drawer, Stack } from "@mui/material";
import { APP_BAR_HEIGHT } from '../navigation/SideNav';
import { AutoStories, KeyboardTab } from "@mui/icons-material";
import { useResearchPanelData } from "../../containers/ResearchPanelData/ResearchPanelData";
import Guidance from "./GuidanceMain";
import AdvisorHeader from "./Advisor/AdvisorHeader";
import AdvisorFlow from "./Advisor/AdvisorFlow";
import AdvisorResponses from "./Advisor/AdvisorWorkflowResponses";
import KeyTerms from "./KeyTerms";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useTakerState } from "../../containers/TakerDocumentState/TakerDocumentState";

export const ResearchPanel = () => {
    const {
        displayContext,
        researchPanelBasicState,
        setResearchPanelBasicState,
        setGuidanceOutlineOpen,
        setSelectedGuidanceId,
        setSelectedGuidanceItemId,
        setFocusedKeyTermIdentifier
    } = useResearchPanelData();

    const { hash } = useLocation();
    const { questionnareDataService } = useTakerState();

    useEffect(() => {
        // parse _gcid parameter out of the hash
        if (hash.indexOf("_grefid") !== -1) {
            const [_, refId] = hash.split("=");
            const qr = questionnareDataService.getGuidanceReferenceById(refId)
            if (!qr || !qr.guidanceId || !qr.guidanceContentId) {
                return;
            }

            // may need to toggle automatically to the guidance tab            
            if (displayContext === "WORKFLOW"
                && researchPanelBasicState.isOpen
                && researchPanelBasicState.currentTab === 'GUIDANCE'
            ) {
                setSelectedGuidanceId(qr.guidanceId);
                setSelectedGuidanceItemId(qr.guidanceContentId);
            }
        } else if (hash.indexOf("_ktrefid") !== -1) {
            const [_, refId] = hash.split("=");
            const sr = questionnareDataService.getSourceReferenceById(refId)
            if (!sr) {
                return;
            }

            if (displayContext === "WORKFLOW"
                && researchPanelBasicState.isOpen
                && researchPanelBasicState.currentTab === 'KEY_TERMS'
            ) {
                setFocusedKeyTermIdentifier(sr.keyTermIdentifier);
            }
        }
    }, [
        displayContext,
        researchPanelBasicState.isOpen,
        researchPanelBasicState.currentTab,
        hash
    ]);

    return (
        <>
            <Drawer
                open={researchPanelBasicState.isOpen}
                variant="persistent"
                anchor="right"
                onClose={() => {
                    setResearchPanelBasicState({
                        ...researchPanelBasicState,
                        isOpen: false
                    })
                }}
                PaperProps={{
                    sx: {
                        backgroundColor: "#FBFCFE",
                        overflow: 'hidden',
                    },
                    elevation: 16
                }}
            >
                <Stack
                    marginTop={`${APP_BAR_HEIGHT}px`}
                    width="50vw"
                    height={`calc(100vh - ${APP_BAR_HEIGHT}px)`}
                    overflow="hidden"
                >
                    <Box borderBottom="1px solid #e0e0e0">
                        <Toolbar
                            variant="dense"
                            sx={{
                                width: "100%",
                                display: "inline-grid",
                                gridTemplateColumns: "1fr 1fr 1fr",
                                alignItems: "center",
                                height: "48px",
                            }}
                        >
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <AutoStories sx={{ mr: 1 }} color="primary" />
                                <Typography variant="h6" noWrap>
                                    Research Tool
                                </Typography>
                            </div>
                            <ToggleButtonGroup
                                size="small"
                                color="primary"
                                exclusive
                                value={researchPanelBasicState.currentTab}
                                onChange={(e, value) => {
                                    setResearchPanelBasicState({
                                        ...researchPanelBasicState,
                                        currentTab: value,
                                    });
                                    setGuidanceOutlineOpen(false);
                                }}
                            >
                                <ToggleButton value="ADVISOR">Advisor</ToggleButton>
                                <ToggleButton value="GUIDANCE">Guidance</ToggleButton>
                                <ToggleButton value="KEY_TERMS">Key Terms</ToggleButton>
                            </ToggleButtonGroup>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <IconButton sx={{ ml: 'auto' }}>
                                    <KeyboardTab onClick={() => {
                                        setResearchPanelBasicState({
                                            ...researchPanelBasicState,
                                            isOpen: !researchPanelBasicState.isOpen
                                        })
                                    }} />
                                </IconButton>
                            </div>
                        </Toolbar>
                    </Box>
                    <Box
                        padding={1}
                        height="calc(100% - 48px)"
                        display={researchPanelBasicState.currentTab === 'ADVISOR' ? 'flex' : 'none'}
                        flexDirection="column"
                    >
                        <AdvisorHeader />
                        <AdvisorFlow inWorkflow />
                        <AdvisorResponses
                            onLatestPending={() => { }}
                            onLatestComplete={() => { }}
                        />
                    </Box>
                    <Box display={researchPanelBasicState.currentTab === 'GUIDANCE' ? 'block' : 'none'}>
                        <Guidance />
                    </Box>
                    <Box
                        padding={1}
                        height="calc(100% - 48px)"
                        display={researchPanelBasicState.currentTab === 'KEY_TERMS' ? 'block' : 'none'}
                        paddingBottom={1}
                    >
                        <KeyTerms />
                    </Box>
                </Stack>
            </Drawer>
        </>
    );
};