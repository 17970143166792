import React, { useCallback, useEffect } from 'react';
import {
    ReactFlow,
    addEdge,
    MiniMap,
    Controls,
    useNodesState,
    useEdgesState,
    Node,
    Edge,
} from '@xyflow/react';
import { MarkerType, Position, ReactFlowProvider, useReactFlow } from '@xyflow/react';
import ELK, { ElkNode } from 'elkjs/lib/elk.bundled.js';

import '@xyflow/react/dist/style.css';
import { GraphFulfillment } from '../../../types/builderv2.generated';

const elk = new ELK();

// Elk has a *huge* amount of options to configure. To see everything you can
// tweak check out:
// - https://www.eclipse.org/elk/reference/algorithms.html
// - https://www.eclipse.org/elk/reference/options.html
const elkOptions = {
    'elk.algorithm': 'layered',
    'elk.layered.spacing.nodeNodeBetweenLayers': '150',
    'elk.spacing.nodeNode': '120',
};

interface GraphVisualProps {
    graph: GraphFulfillment
};

const ACTIVE_COLOR = '#FF0072';
const NODE_WIDTH = 250;
const NODE_HEIGHT = 120;
const initBgColor = '#1976d217';

const getLayoutedElements = (nodes: any[], edges: any[], options = {}) => {
    const graph = {
        id: 'root',
        layoutOptions: options,
        children: nodes.map((node) => ({
            ...node,
            targetPosition: Position.Top,
            sourcePosition: Position.Bottom,
            width: NODE_WIDTH,
            height: NODE_HEIGHT,
        })),
        edges: edges,
    };

    return elk
        .layout(graph as ElkNode)
        .then((layoutedGraph: any) => ({
            nodes: layoutedGraph.children.map((node: any) => ({
                ...node,
                position: { x: node.x, y: node.y },
            })),

            edges: layoutedGraph.edges,
        }))
        .catch(console.error);
};

function GraphVisual({
    graph
}: GraphVisualProps) {
    const [nodes, setNodes, onNodesChange] = useNodesState<Node>([]);
    const [edges, setEdges, onEdgesChange] = useEdgesState<Edge>([]);
    const { fitView } = useReactFlow();
    const onConnect = useCallback((params: any) => setEdges((eds) => addEdge(params, eds)), []);

    useEffect(() => {
        if (!graph) {
            return;
        }

        const newEdges: Edge[] = [];
        const newNodes: Node[] = [];

        if (graph.edges) {
            for (const e of graph.edges) {
                newEdges.push({
                    id: e.id,
                    source: e.source,
                    target: e.target,
                    markerEnd: {
                        type: MarkerType.Arrow,
                    },
                    label: e.label
                });
            }
        }

        if (graph.nodes) {
            for (const n of graph.nodes) {
                if (n.nodeType === "question") {
                    newNodes.push({
                        id: n.id,
                        position: { x: 0, y: 0 },
                        data: {
                            label: `${n.id}) ${n.questionLabel}: ${n.questionText}`
                        },
                    } as Node);
                } else if (n.nodeType === "module") {
                    newNodes.push({
                        id: n.id,
                        position: { x: 0, y: 0 },
                        data: {
                            label: `${n.id}) ${n.moduleId}`
                        },
                    } as Node);
                } else if (n.nodeType === "end") {
                    newNodes.push({
                        id: n.id,
                        position: { x: 0, y: 0 },
                        data: {
                            label: n.statement
                        },
                    } as Node);
                }
            }
        }

        const opts = { 'elk.direction': "DOWN", ...elkOptions };
        getLayoutedElements(newNodes, newEdges, opts)
            .then((res) => {
                if (res) {
                    const { nodes: layoutedNodes, edges: layoutedEdges } = res;
                    setNodes(layoutedNodes);
                    setEdges(layoutedEdges);
                    window.requestAnimationFrame(() => fitView());
                }
            });
    }, []);

    return (
        <ReactFlow
            nodes={nodes}
            edges={edges}
            onConnect={onConnect}
            onNodesChange={onNodesChange}
            onEdgesChange={onEdgesChange}
            fitView
            style={{ background: initBgColor }}
        >
            <MiniMap
                nodeColor={(n: Node) => ACTIVE_COLOR}
                zoomable
                pannable
            />
            <Controls showInteractive={false} />
        </ReactFlow>
    );
}

export default ({ graph }: GraphVisualProps) => (
    <ReactFlowProvider>
        <GraphVisual graph={graph} />
    </ReactFlowProvider>
);