import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { mergeRegister } from '@lexical/utils';
import React, { useEffect, useState } from 'react';
import { InnerReportSectionNode } from '../../nodes/InnerReportSectionNode';
import { useTakerState } from '../../../../containers/TakerDocumentState/TakerDocumentState';

interface SyncInnerReportSectionNodesPluginProps {
    outOfSync: boolean;
    overriden: boolean;
    editIdentifier: string;
}

export default function SyncInnerReportSectionNodesPlugin({
    outOfSync,
    overriden,
    editIdentifier
}: SyncInnerReportSectionNodesPluginProps) {
    const [editor] = useLexicalComposerContext();
    const { reviewComments } = useTakerState();
    const [hasComment, setHasComment] = useState(false);
    useEffect(() => {
        if(reviewComments){
            setHasComment(reviewComments.some((comment) => {
                if(comment.commentMetadata && comment.commentMetadata.editIdentifier) {
                    return comment.commentMetadata.editIdentifier === editIdentifier;
                }
                return false;
            }));
        }
    }, [reviewComments]);

    useEffect(() => {
        return mergeRegister(
            editor.registerNodeTransform(InnerReportSectionNode, (innerReportSectionNode) => {
                // Synchronize this node's metadata to the outside state.
                innerReportSectionNode.__outOfSync = outOfSync;
                innerReportSectionNode.__overriden = overriden;
                innerReportSectionNode.__hasComment = hasComment;
            })
        );
    }, [
        editor,
        outOfSync,
        overriden,
        hasComment
    ]);

    return null;
}