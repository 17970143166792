import React, { memo, useCallback, useEffect, useMemo, useState, ReactNode } from 'react';
import {
    ReactFlow,
    addEdge,
    MiniMap,
    Controls,
    useNodesState,
    useEdgesState,
    Node,
    ConnectionMode,
    Background,
} from '@xyflow/react';
import { ReactFlowProvider, useReactFlow } from '@xyflow/react';
import { Module, Root } from '../../../types/builderv2.generated';
import { Box, Tab, Tabs } from '@mui/material';
import { a11yProps } from '../../../components/navigation/TabPanel';
import EditableEdge from './EditableEdge';
import ResizableNode from './ResizableNode';
import { useBuilderData } from './context';
import { 
    EditableEdgeType, 
    NodeDetails, 
    PositioningData, 
    ResizableNodeType, 
    getLayoutedElements 
} from './common';

import '@xyflow/react/dist/style.css';
import './GraphEditor.css';
import GraphEditorToolbar from './GraphEditorToolbar';

interface GraphEditorComponentProps {
    existingNodes: ResizableNodeType[];
    existingEdges: EditableEdgeType[];
};

const ACTIVE_COLOR = '#FF0072';
const initBgColor = '#1976d217';

export const edgeTypes = {
    'editable-edge': EditableEdge,
};

export const nodeTypes = {
    'resizable': ResizableNode
}

function GraphEditorComponent({
    existingNodes,
    existingEdges
}: GraphEditorComponentProps) {
    const {
        fullConfig,
        scheduleUpdate
    } = useBuilderData();

    const [nodes, setNodes, onNodesChange] = useNodesState<ResizableNodeType>([]);
    const [edges, setEdges, onEdgesChange] = useEdgesState<EditableEdgeType>([]);

    const { fitView } = useReactFlow();

    const onConnect = useCallback((params: any) => setEdges((eds) => addEdge(params, eds)), []);

    useEffect(() => {
        if ((existingNodes.length === 0 && existingEdges.length === 0) ||
            (nodes.length === existingNodes.length && edges.length === existingEdges.length)) {
            return;
        }

        // Layout the rest of the positions 
        const layouted = getLayoutedElements(existingNodes, existingEdges);
        setNodes([...layouted.nodes]);
        setEdges([...layouted.edges]);

        window.requestAnimationFrame(() => fitView());
    }, [
        existingNodes,
        existingEdges
    ]);

    const onNodePositionOrSizeChange = useCallback(() => {
        const positioningMetadata = fullConfig?.positioningMetadata ?? {};
        const exactPositionsAndSizesByNodeId: Record<string, NodeDetails> = {
            ...(positioningMetadata?.exactPositionsAndSizesByNodeId || {})
        };

        for (const n of nodes) {
            const nodeDetails: NodeDetails = {
                xPos: n.position.x,
                yPos: n.position.y,
                height: n.height,
                width: n.width
            };
            exactPositionsAndSizesByNodeId[n.id] = nodeDetails;
        }

        const newConfig = JSON.parse(JSON.stringify(fullConfig)) as Root;
        if (!newConfig.positioningMetadata) {
            newConfig.positioningMetadata = {};
        }
        newConfig.positioningMetadata.exactPositionsAndSizesByNodeId = exactPositionsAndSizesByNodeId;
        
        scheduleUpdate(newConfig);
    }, [nodes]);

    useEffect(() => {
        if (nodes.length === 0) {
            return;
        }
        
        onNodePositionOrSizeChange();
    }, [nodes]);

    return (
        <ReactFlow
            nodes={nodes}
            edges={edges}
            onConnect={onConnect}
            onNodesChange={onNodesChange}
            onEdgesChange={onEdgesChange}
            onNodeDragStop={(event, node, updatedNodes) => {
                // update node positioning here
                onNodePositionOrSizeChange();
            }}
            fitView
            fitViewOptions={{ padding: 0.4 }}
            minZoom={0.025}
			maxZoom={5}
            style={{ background: initBgColor }}
            nodeTypes={nodeTypes}
            edgeTypes={edgeTypes}
            connectionMode={ConnectionMode.Loose}
            snapToGrid
        >
            <MiniMap
                nodeColor={(n: Node) => ACTIVE_COLOR}
                zoomable
                pannable
            />
            <Controls showInteractive={false} />
            <Background />
        </ReactFlow>
    );
}

const GraphEditor = () => {
    const { 
        builderHolder,
        fullConfig,
    } = useBuilderData();
    const [tabValue, setTabValue] = useState(0);

    const positioningMetadata = fullConfig.positioningMetadata as PositioningData;

    const countNodesForModule: (m: Module) => number = (m: Module) => {
        let total = 0;
        if (m.uiFulfillment?.fulfillmentType === "table"
            || (m.uiFulfillment?.fulfillmentType === "graph" && m.dataSpec?.dataSpecType === "basic-table")) {
            total += 1;
        } else if (m.uiFulfillment?.fulfillmentType === "graph") {
            if (m.uiFulfillment.nodes) {
                total += m.uiFulfillment.nodes.length;
            }
        }

        if (m.nestedModules) {
            for (const nm of m.nestedModules) {
                total += countNodesForModule(nm);
            }
        }
        return total;
    }

    const positionedModuleList = useMemo(() => {
        if (!builderHolder.builder.topModule) {
            return [];
        }

        const moduleList: { path: string[]; module: Module; }[] = [{
            path: [],
            module: builderHolder.builder.topModule
        }];

        const addModules = (m: Module, path: string[]) => {
            if (m.uiFulfillment?.fulfillmentType === "graph" && m.dataSpec?.dataSpecType === "basic") {
                if (m.uiFulfillment.nodes) {
                    for (const n of m.uiFulfillment.nodes) {
                        if (n.nodeType === "module") {
                            const praxiModule = builderHolder.getModule([...path, m.id, n.moduleId]);
                            if (!praxiModule) {
                                throw Error(`module with moduleId=${[...path, m.id, n.moduleId]} doesn't exist`)
                            } else if ((praxiModule.uiFulfillment?.fulfillmentType === "graph" && praxiModule.dataSpec?.dataSpecType === "basic-table")) {
                                moduleList.push({
                                    path: [...path, m.id],
                                    module: praxiModule
                                });
                            }
                        }
                    }
                }
            }

            if (m.nestedModules) {
                for (const nm of m.nestedModules) {
                    addModules(nm, [...path, m.id]);
                }
            }
        }

        addModules(builderHolder.builder.topModule, []);

        return moduleList;
    }, [builderHolder]);

    const [existingNodes, existingEdges] = useMemo(() => {
        const newEdges: EditableEdgeType[] = [];
        const newNodes: ResizableNodeType[] = [];

        const target = positionedModuleList[tabValue];
        const exactPositionsAndSizesByNodeId = positioningMetadata?.exactPositionsAndSizesByNodeId || {};

        const addNodesForModule = (m: Module, path: string[]) => {
            let allModuleIds = [...path, m.id];
            let pathId = allModuleIds.join('-')
            if (m.uiFulfillment?.fulfillmentType === "graph") {
                if (m.uiFulfillment.nodes) {
                    for (const n of m.uiFulfillment.nodes) {
                        if (n.nodeType === "question") {
                            newNodes.push({
                                id: `${pathId}-${n.id}`,
                                type: "resizable",
                                data: {
                                    moduleIds: allModuleIds,
                                    nodeId: n.id,
                                    nodeType: n.nodeType
                                },
                                position: { x: 0, y: 0 },
                                width: 275,
                                height: 150,
                                extent: (target.module.id === m.id) ? undefined : 'parent',
                                parentId: (target.module.id === m.id) ? undefined : pathId
                            });
                        } else if (n.nodeType === "module") {
                            // need to either push a group or a plain node
                            // module == graph (but not group) -> push group
                            // module == other -> push node
                            const praxiModule = builderHolder.getModule([...path, m.id, n.moduleId]);
                            if (!praxiModule) {
                                throw Error(`module with moduleId=${[...path, m.id, n.moduleId]} doesn't exist`)
                            } else if (praxiModule.uiFulfillment?.fulfillmentType === "table"
                                || (praxiModule.uiFulfillment?.fulfillmentType === "graph" && praxiModule.dataSpec?.dataSpecType === "basic-table")) {
                                newNodes.push({
                                    id: `${pathId}-${n.id}`,
                                    type: "resizable",
                                    data: { 
                                        moduleIds: allModuleIds,
                                        nodeId: n.id,
                                        nodeType: n.nodeType
                                    },
                                    className: "background-node",
                                    position: { x: 0, y: 0 },
                                    width: 275,
                                    height: 150,
                                    extent: (target.module.id === m.id) ? undefined :  'parent',
                                    parentId: (target.module.id === m.id) ? undefined : pathId
                                });
                            } else if (praxiModule.uiFulfillment?.fulfillmentType === "graph") {
                                let nNodes = countNodesForModule(praxiModule);
                                newNodes.push({
                                    id: `${pathId}-${n.id}`,
                                    type: "resizable",
                                    data: { 
                                        moduleIds: allModuleIds,
                                        nodeId: n.id,
                                        nodeType: n.nodeType
                                    },
                                    className: 'background-node',
                                    style: {
                                        backgroundColor: 'rgba(255, 0, 0, 0.2)',
                                    },
                                    position: { x: 0, y: 0 },
                                    width: 200 * nNodes,
                                    height: 200 * nNodes,
                                    extent: (target.module.id === m.id) ? undefined : 'parent',
                                    parentId: (target.module.id === m.id) ? undefined : pathId
                                });
                            }
                        } else if (n.nodeType === "end") {
                            newNodes.push({
                                id: `${pathId}-${n.id}`,
                                type: "resizable",
                                data: {
                                    moduleIds: allModuleIds,
                                    nodeId: n.id,
                                    nodeType: n.nodeType
                                },
                                position: { x: 0, y: 0 },
                                width: 50,
                                height: 50,
                                extent: (target.module.id === m.id) ? undefined : 'parent',
                                parentId: (target.module.id === m.id) ? undefined : pathId
                            });
                        }
                    }
                }
            }

            if (m.nestedModules) {
                for (const nm of m.nestedModules) {
                    if (nm.uiFulfillment?.fulfillmentType === "graph" && nm.dataSpec?.dataSpecType === "basic") {
                        addNodesForModule(nm, [...path, m.id]);
                    }
                }
            }
        }

        // Prep all of the nodes
        addNodesForModule(target.module, target.path);

        // Assign positions and create lookup
        const nodeById: Record<string, Node> = {};
        for (const n of newNodes) {
            nodeById[n.id] = n;

            let pos = exactPositionsAndSizesByNodeId[n.id];
            if (pos) {
                n.position = {
                    x: pos.xPos,
                    y: pos.yPos
                };

                if (pos.width && pos.height) {
                    n.width = pos.width;
                    n.height = pos.height;
                }
            }
        }

        const addEdgesForModule = (m: Module, path: string[]) => {
            let pathId = [...path, m.id].join('-')
            if (m.uiFulfillment?.fulfillmentType === "graph") {
                if (m.uiFulfillment.edges) {
                    for (const e of m.uiFulfillment.edges) {
                        const newEdge = {
                            id: `${pathId}-${e.id}`,
                            type: 'editable-edge',
                            source: `${pathId}-${e.source}`,
                            target: `${pathId}-${e.target}`,
                            label: e.label
                        } as EditableEdgeType;
                        newEdges.push(newEdge);
                    }
                }
            }

            if (m.nestedModules) {
                for (const nm of m.nestedModules) {
                    if (nm.uiFulfillment?.fulfillmentType === "graph" && nm.dataSpec?.dataSpecType === "basic") {
                        addEdgesForModule(nm, [...path, m.id]);
                    }
                }
            }
        }

        // now do edges
        addEdgesForModule(target.module, target.path);

        return [newNodes, newEdges];
    }, [
        tabValue,
        positionedModuleList,
    ]);

    return (
        <Box
            display="flex"
            flexDirection="column"
            height="100%"
            width="100%"
        >
            <Box width="100%">
                <Tabs
                    value={tabValue}
                    variant="scrollable"
                    scrollButtons="auto"
                    onChange={(event: React.SyntheticEvent, newValue: number) => setTabValue(newValue)}
                >
                    {positionedModuleList.map(m => (
                        <Tab label={m.module.displayName} {...a11yProps(0)} />
                    ))}
                </Tabs>
            </Box>
            <Box
                width="100%"
                overflow="auto"
                position="relative"
                flexGrow={1}
                zIndex={1}
            >
                <ReactFlowProvider>
                    <GraphEditorToolbar 
                        targetModule={positionedModuleList[tabValue].module}
                        targetPath={positionedModuleList[tabValue].path}    
                    />
                    <GraphEditorComponent
                        existingNodes={existingNodes}
                        existingEdges={existingEdges}
                    />
                </ReactFlowProvider>
            </Box>
        </Box>
    )
};

export default memo(GraphEditor);
