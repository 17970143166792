import { createApi } from '@reduxjs/toolkit/query/react';
import { Assistant, AssistantAlgorithmParam, AssistantCapabilities, DeleteAssistantAlgorithmParamPayload, DeleteLanguageModelPromptPayload, LanguageModelPrompt } from "../models/dataModelTypes";
import { axiosBaseQuery } from '../reduxUtils/baseQuery';
import { PaginatedResponse } from '../models/commonTypes';

export const assistantApi = createApi({
    reducerPath: 'assistantApi',
    baseQuery: axiosBaseQuery(),
    tagTypes: ['Assistants'],
    endpoints: (build) => ({
        listAssistants: build.query<PaginatedResponse<Assistant>, { page: number, limit: number, languageModelId: string }>({
            query: ({page = 1, limit = 10, languageModelId}) => ({
                url: `assistants?page=${page}&limit=${limit}&language_model_id=${languageModelId}`,
                method: "GET"
            }),
            providesTags: (result) =>
                result
                ?
                    [
                        ...result.data.map(({ id }) => ({ type: 'Assistants', id } as const)),
                        { type: 'Assistants', id: 'LIST' },
                    ]
                :
                    [{ type: 'Assistants', id: 'LIST' }],
        }),
        searchAssistantsByName: build.query<PaginatedResponse<Assistant>, { page: number | void, limit: number | void, name: string | void }>({
            query: ({page = 1, limit = 10, name = ''}) => ({
                url: `assistants/name_search?page=${page}&limit=${limit}&name=${name}`,
                method: "GET"
            }),
            providesTags: (result) =>
                result
                ?
                    [
                        ...result.data.map(({ id }) => ({ type: 'Assistants', id } as const)),
                        { type: 'Assistants', id: 'SEARCH' },
                    ]
                :
                    [{ type: 'Assistants', id: 'SEARCH' }],
        }),
        addAssistant: build.mutation<Assistant, Partial<Assistant>>({
            query(body) {
                const { name, description, languageModelId } = body;
                return {
                    url: `assistants`,
                    method: 'POST',
                    data: {
                        name, 
                        description,
                        language_model_id: languageModelId
                    },
                }
            },
            invalidatesTags: [{ type: 'Assistants', id: 'LIST' }],
        }),
        getAssistant: build.query<Assistant, string>({
            query: (id) => ({
                url: `assistants/${id}`,
                method: "GET"
            }),
            providesTags: (result, error, id) => [{ type: 'Assistants', id }],
        }),
        updateAssistant: build.mutation<Assistant, Partial<Assistant>>({
            query(data) {
                const { id, ...body } = data
                return {
                    url: `assistants/${id}`,
                    method: 'PUT',
                    data: body
                }
            },
            invalidatesTags: (result, error, { id }) => [{ type: 'Assistants', id }],
        }),
        deleteAssistant: build.mutation<boolean, string>({
            query(id) {
                return {
                    url: `assistants/${id}`,
                    method: 'DELETE',
                }
            },
            invalidatesTags: (result, error, id) => [{ type: 'Assistants', id }],
        }),
        addAlgoParam: build.mutation<AssistantAlgorithmParam, Partial<AssistantAlgorithmParam>>({
            query(body) {
                return {
                    url: `assistants/${body.assistantId}/algorithm_param`,
                    method: 'POST',
                    data: {
                        key: body.key, 
                        value: body.value 
                    },
                }
            },
            invalidatesTags: (result, error, { assistantId }) => [{ type: 'Assistants', id: assistantId }],
        }),
        deleteAlgoParam: build.mutation<AssistantAlgorithmParam, Partial<DeleteAssistantAlgorithmParamPayload>>({
            query(body) {
                return {
                    url: `assistants/${body.assistantId}/algorithm_param/${body.id}`,
                    method: 'DELETE'
                }
            },
            invalidatesTags: (result, error, { assistantId }) => [{ type: 'Assistants', id: assistantId }],
        }),
        addPrompt: build.mutation<LanguageModelPrompt, Partial<LanguageModelPrompt>>({
            query(body) {
                return {
                    url: `assistants/${body.assistantId}/language_model_prompt`,
                    method: 'POST',
                    data: {
                        key: body.key, 
                        value: body.template 
                    },
                }
            },
            invalidatesTags: (result, error, { assistantId }) => [{ type: 'Assistants', id: assistantId }],
        }),
        deletePrompt: build.mutation<LanguageModelPrompt, Partial<DeleteLanguageModelPromptPayload>>({
            query(body) {
                return {
                    url: `assistants/${body.assistantId}/language_model_prompt/${body.id}`,
                    method: 'DELETE'
                }
            },
            invalidatesTags: (result, error, { assistantId }) => [{ type: 'Assistants', id: assistantId }],
        }),
        updateAssistantCapabilities: build.mutation<AssistantCapabilities, Partial<AssistantCapabilities>>({
            query(data) {
                const { 
                    id, 
                    assistantId, 
                    canSummarize,
                    canQa,
                    canRewriteReport,
                    canAnnotateDocuments,
                    canSimulateFulfillment,
                    canAdvisorPrimary,
                    canAdvisorSecondary,
                    canDoDocumentEmbedding
                } = data;
                return {
                    url: `assistants/${assistantId}/capabilities/${id}`,
                    method: 'PUT',
                    data: {
                        can_summarize: canSummarize, 
                        can_qa: canQa, 
                        can_annotate_documents: canAnnotateDocuments,
                        can_rewrite_report: canRewriteReport,
                        can_simulate_fulfillment: canSimulateFulfillment,
                        can_advisor_primary: canAdvisorPrimary,
                        can_advisor_secondary: canAdvisorSecondary,
                        can_do_document_embedding: canDoDocumentEmbedding,
                    }
                }
            },
            invalidatesTags: (result, error, { assistantId }) => [{ type: 'Assistants', id: assistantId }],
        })
    }),
})

export const {
    useListAssistantsQuery,
    useSearchAssistantsByNameQuery,
    useAddAssistantMutation,
    useGetAssistantQuery,
    useUpdateAssistantMutation,
    useDeleteAssistantMutation,
    useAddAlgoParamMutation,
    useDeleteAlgoParamMutation,
    useAddPromptMutation,
    useDeletePromptMutation,
    useUpdateAssistantCapabilitiesMutation
} = assistantApi;