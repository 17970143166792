import React, { useState, useContext, useEffect, PropsWithChildren, useRef, useMemo, useCallback, memo } from 'react';
import {
  Taker,
  TakerDocument,
  TakerDocumentUpload,
  TakerDocumentUploadAnalysis,
  TakerDocumentAnalysis,
  TakerAccessType,
  TakerDocumentSettingsPropertyType,
  TakerDocumentData,
  TakerDocumentReviewComment,
  CommentType
} from "../../redux/models/dataModelTypes";
import { FulfillmentStateHolder, TakerStateHolder } from "../../types/taker";
import { dummyTakerStateData } from './dummyTakerStateData';
import {
  useUpdateTakerMutation,
  useGetTakerDocumentQuery,
  useAddTakerDocumentReviewCommentMutation,
  useUpdateTakerDocumentReviewCommentMutation,
} from '../../redux/services/taker';
import {
  useGetTakerDocumentStateV2Query,
  useGetLatestTakerDocumentDataQuery,
  useUpdateTakerDocumentQuestionnaireMutation
} from '../../redux/services/takerData';
import { VariableReference } from '../../types/builderv2.generated';
import { useReadOnlyBuilderData } from '../ReadOnlyBuilderData/ReadOnlyBuilderData';
import { GuidanceReference, KeyTermSource, MultipleWorkflowQuestionAnalysis, SingleWorkflowQuestionAnalysis } from '../../types/taker/uidatastate.generated';
import { useUserScopedAppData } from '../UserScopedAppData/UserScopedAppData';
import { useLocalStorage } from '@uidotdev/usehooks';

const SAVE_DELAY = 1500;
const METADATA_SAVE_DELAY = 2500;
const DURATION_MINUTES_MS = 15 * 60 * 1000;

const dummyTakerState = new TakerStateHolder(dummyTakerStateData);

export const getVariableValue = (
  currentModuleIds: string[],
  varRef: VariableReference,
  scopedVars: any
) => {
  if (!scopedVars) {
    return;
  }

  let scopedVarsPtr = scopedVars;

  // This refers to a local variable, return from currentModuleIds scope.
  if (!varRef.modulePath) {
    const midsCopy = [...currentModuleIds];
    let firstMid = midsCopy.splice(0, 1)[0];
    if (firstMid !== scopedVarsPtr["module_id"]) {
      throw Error(`${firstMid} was not the first module ID in the path`)
    }

    while (midsCopy.length > 0) {
      let firstMid = midsCopy.splice(0, 1)[0];
      let found = false;
      for (const sv of scopedVarsPtr["nested_scoped_vars"]) {
        if (sv["module_id"] === firstMid) {
          scopedVarsPtr = sv;
          found = true;
          break;
        }
      }
      if (!found) {
        throw Error(`${firstMid} was not found in the available path`);
      }
    }
    return scopedVarsPtr["internal_variables"][varRef.variableName];
  }

  const midsCopy = [...varRef.modulePath];
  let firstMid = midsCopy.splice(0, 1)[0];
  if (firstMid !== scopedVarsPtr["module_id"]) {
    throw Error(`${firstMid} was not the first module ID in the path`)
  }

  while (midsCopy.length > 0) {
    let firstMid = midsCopy.splice(0, 1)[0];
    let found = false;
    for (const sv of scopedVarsPtr["nested_scoped_vars"]) {
      if (sv["module_id"] === firstMid) {
        scopedVarsPtr = sv;
        found = true;
        break;
      }
    }
    if (!found) {
      throw Error(`${firstMid} was not found in the available path`);
    }
  }
  return scopedVarsPtr["exported_variables"][varRef.variableName];
};

type TakerNameGetter = () => string | null;

interface TakerPermissionState {
  allGrants: TakerAccessType[];
  isUnknown: boolean;
  isForbidden: boolean;
  isRead: boolean;
  isReadWrite: boolean;
  isAdmin: boolean;
};

export interface QuestionnaireDataService {
  updateAnswer: (moduleIds: string[], q: string, value: any, iteration: number | null) => void;
  updateAnswers: (moduleIds: string[], nameValuePairs: { name: string; value: any; }[], iteration: number | null) => void;
  updateAnalysis: (moduleIds: string[], q: string, value: string, iteration: number | null) => void;
  updateAnalysisFull: (
    moduleIds: string[], 
    q: string, 
    value: string, 
    refs: GuidanceReference[],
    sources: KeyTermSource[],
    iteration: number | null
  ) => void;
  updateReference: (moduleIds: string[], q: string, value: GuidanceReference[], iteration: number | null) => void;
  updateSource: (moduleIds: string[], q: string, value: KeyTermSource[], iteration: number | null) => void;
  getAnswer: (moduleIds: string[], q: string, iteration: number | null) => any;
  getAnalysis: (moduleIds: string[], q: string, iteration: number | null) => string | undefined;
  getReference: (moduleIds: string[], q: string, iteration: number | null) => GuidanceReference[];
  getSource: (moduleIds: string[], q: string, iteration: number | null) => KeyTermSource[];
  getMockAnswer: (moduleIds: string[], q: string, iteration: number | null) => any;
  getMockAnalysis: (moduleIds: string[], q: string, iteration: number | null) => string | undefined;
  getMockReference: (moduleIds: string[], q: string, iteration: number | null) => GuidanceReference[];
  getMockSource: (moduleIds: string[], q: string, iteration: number | null) => KeyTermSource[];
  addIterations: (moduleIds: string[], n: number) => void;
  removeIteration: (moduleIds: string[], iteration: number) => void;
  count: (moduleIds: string[]) => number;

  getGuidanceReferenceById: (id: string) => GuidanceReference | undefined;
  getSourceReferenceById: (id: string) => KeyTermSource | undefined;

  addWorkflowQuestionsAnalysis: (analysisId: string) => void;
  addSingleWorkflowQuestionAnalysis: (analysisId: string, moduleIds: string[], nodeId?: string, iteration?: number) => void;
  addSingleWorkflowQuestionAnalysisForAnswerOnly: (analysisId: string, moduleIds: string[], nodeId?: string, iteration?: number) => void;
  addSingleWorkflowQuestionAnalysisForAnalysisOnly: (analysisId: string, moduleIds: string[], nodeId?: string, iteration?: number) => void;
  removeSingleWorkflowQuestionAnalysis: (analysisId: string) => void;
  removeWorkflowQuestionsAnalysis: (analysisId: string) => void;
  addressAnswerForAnalysis: (analysisId: string, questionId?: string) => MultipleWorkflowQuestionAnalysis | SingleWorkflowQuestionAnalysis | undefined;
  addressAnalysisForAnalysis: (analysisId: string, questionId?: string) => MultipleWorkflowQuestionAnalysis | SingleWorkflowQuestionAnalysis | undefined;
};

interface TakerStateHookData {
  isSaving: boolean;
  initTakerStateCount: number;
  isTakerDocumentUninitialized: boolean;
  isQuestionnaireUninitialized: boolean;
  isTakerDocumentLoading: boolean;
  taker: Taker | undefined;
  lastSavedTimestamp: number | undefined;
  takerDocumentId: string;
  latestQuestionnaireDataId?: string;
  latestQuestionnaireData?: TakerDocumentData | undefined;
  takerDocumentUploads: Array<TakerDocumentUpload> | undefined;
  activeTakerDocument: TakerDocument | null;
  refetchTakerDocument: () => void;
  takerDocumentAnalysesInProgress: string[];
  takerDocumentUploadAnalysesInProgress: string[];
  takerPermissionState: TakerPermissionState;
  takerName: string;
  updateTakerName: (n: string) => void;
  activeTargetTakerState: TakerStateHolder;
  isTakerStateDirty: boolean;
  questionnareDataService: QuestionnaireDataService;
  takerOutput: any;
  fulfillmentStateHolder: FulfillmentStateHolder | undefined,
  loadingTakerOutput: boolean;
  getVariableValue: (mids: string[], varRef: VariableReference) => any;
  getActiveSetting: (type: TakerDocumentSettingsPropertyType) => any;

  // comments
  commentsOpen: boolean;
  setCommentsOpen: (o: boolean) => void;
  reviewComments: Array<TakerDocumentReviewComment>;
  addReviewComment: (
    lexical: any,
    text: string,
    commentType: CommentType,
    commentMetadata: Record<string, any>
  ) => void;
  updateReviewComment: (
    commentId: string,
    lexical: any,
    text: string
  ) => void;
}

type TakerStateHook = () => TakerStateHookData;

const Context = React.createContext({});

export const useTakerState: TakerStateHook = () => {
  return useContext(Context) as TakerStateHookData;
}

interface TakerDocumentContainerProps {
  originalTaker: Taker;
  takerDocumentId: string;
  userId: string;
}

const TakerDocumentContainer: React.FC<PropsWithChildren<TakerDocumentContainerProps>> = ({
  originalTaker,
  takerDocumentId,
  userId,
  children
}) => {
  const [updatingJob, setUpdatingJob] = useState<any>(null);
  const [updatingMetadataJob, setUpdatingMetadataJob] = useState<any>(null);
  const [initTakerStateCount, setInitTakerStateCount] = useState<number>(0);
  const [takerName, setTakerName] = useState<string | undefined>(undefined);
  const [isStateDirty, setIsStateDirty] = useState<boolean>(true);
  const [lastMarkedClean, setLastMarkedClean] = useState<number>();
  const [commentsOpen, setCommentsOpen] = useLocalStorage<boolean>(`${takerDocumentId}-commentsOpen`, false);

  const preventUpdateOutput = useRef(false);

  const [updateTaker, updateTakerResult] = useUpdateTakerMutation();
  const { defaultTakerDocumentSettings } = useReadOnlyBuilderData();
  const [
    addTakerDocumentReviewComment,
    addTakerDocumentReviewCommentRes
  ] = useAddTakerDocumentReviewCommentMutation();
  const [
    updateTakerDocumentReviewComment,
    updateTakerDocumentReviewCommentRes
  ] = useUpdateTakerDocumentReviewCommentMutation();

  const [
    updateTakerDocumentQuestionnaire,
    updateTakerDocumentQuestionnaireRes
  ] = useUpdateTakerDocumentQuestionnaireMutation();

  const {
    data: latestQuestionnaireData,
    isUninitialized: isQuestionnaireDataUninitialized,
    isError: isQuestionnaireDataError,
    isSuccess: isQuestionnaireDataSuccess
  } = useGetLatestTakerDocumentDataQuery({ takerDocumentId, contentType: "QUESIONNAIRE" });

  const { flagProvider } = useUserScopedAppData();

  const enableQuestionnaireCaching = useMemo(() => {
    let flags = flagProvider.populateFlagValues([
      "FEATURE_QUESTIONNAIRE__enable_fulfillment_caching"
    ])
    if (flags.FEATURE_QUESTIONNAIRE__enable_fulfillment_caching === "TRUE") {
      return true;
    } else {
      return false
    }
  }, [flagProvider])

  const {
    data: activeTakerDocument,
    isFetching: isTakerDocumentLoading,
    isUninitialized: isTakerDocumentUninitialized,
    refetch: refetchTakerDocument
  } = useGetTakerDocumentQuery(takerDocumentId, {
    refetchOnReconnect: true,
    pollingInterval: 10000
  });

  const {
    data: takerDocumentStateV2,
    isSuccess: isTakerDocumentStateV2Success,
    isFetching: isTakerDocumentStateV2IsFetching
  } = useGetTakerDocumentStateV2Query({ id: takerDocumentId, enableCaching: enableQuestionnaireCaching ? "TRUE" : "FALSE" })

  useEffect(() => {
    if (isQuestionnaireDataSuccess || updateTakerDocumentQuestionnaireRes.isSuccess) {
      setIsStateDirty(false);
      if (updateTakerDocumentQuestionnaireRes.isSuccess) {
        setLastMarkedClean(updateTakerDocumentQuestionnaireRes.data.updatedAt);
      } else if (isQuestionnaireDataSuccess) {
        setLastMarkedClean(latestQuestionnaireData.updatedAt);
      }
    }
  }, [
    isQuestionnaireDataSuccess,
    latestQuestionnaireData,
    updateTakerDocumentQuestionnaireRes
  ]);

  const [takerDocumentUploadsState, setTakerDocumentUploads] = useState<Array<TakerDocumentUpload> | undefined>(activeTakerDocument?.takerDocumentUploads);

  const takerDocumentAnalysesInProgress = useMemo(() => {
    if (!activeTakerDocument) {
      return [];
    }

    const now = new Date().getTime();
    const inProgressList: string[] = [];

    const checkForPendingJobs = (analyses?: TakerDocumentAnalysis[]) => {
      if (!analyses) {
        return;
      }

      for (const a of analyses) {
        // don't consider old jobs
        if ((now - a.createdAt) > DURATION_MINUTES_MS) {
          continue;
        }

        if (a.state === "PENDING_GENERATION" || a.state === "CREATED") {
          inProgressList.push(a.id);
        }
      }
    }

    checkForPendingJobs(activeTakerDocument?.executiveSummaryTakerDocumentAnalyses)
    checkForPendingJobs(activeTakerDocument?.contractQATakerDocumentAnalyses)
    checkForPendingJobs(activeTakerDocument?.memoGenerationTakerDocumentAnalyses);
    checkForPendingJobs(activeTakerDocument?.topQuestionTakerDocumentAnalyses);
    checkForPendingJobs(activeTakerDocument?.topWorkflowQuestionTakerDocumentAnalyses);
    checkForPendingJobs(activeTakerDocument?.topPartialQuestionTransformationTakerDocumentAnalyses);

    return inProgressList;
  }, [
    activeTakerDocument?.executiveSummaryTakerDocumentAnalyses,
    activeTakerDocument?.contractQATakerDocumentAnalyses,
    activeTakerDocument?.memoGenerationTakerDocumentAnalyses,
    activeTakerDocument?.topQuestionTakerDocumentAnalyses,
    activeTakerDocument?.topWorkflowQuestionTakerDocumentAnalyses,
    activeTakerDocument?.topPartialQuestionTransformationTakerDocumentAnalyses
  ]);

  const takerDocumentUploadAnalysesInProgress = useMemo(() => {
    if (!activeTakerDocument) {
      return [];
    }

    const now = new Date().getTime();
    const inProgressList: string[] = [];

    const checkForPendingGroupJobs = (uploadAnalyses?: TakerDocumentUploadAnalysis[]) => {
      if (!uploadAnalyses) {
        return;
      }

      for (const a of uploadAnalyses) {
        // don't consider old jobs
        if ((now - a.createdAt) > DURATION_MINUTES_MS) {
          continue;
        }

        if (a.state === "PENDING_GENERATION") {
          inProgressList.push(a.id);
        }
      }
    }

    for (const upload of activeTakerDocument?.takerDocumentUploads || []) {
      checkForPendingGroupJobs(upload.taggingAndSummarizationTakerDocumentUploadAnalyses);
      checkForPendingGroupJobs(upload.contentFilteringTakerDocumentUploadAnalyses);
      checkForPendingGroupJobs(upload.singleKeyTermsTakerDocumentUploadAnalyses);
    }

    return inProgressList;
  }, [activeTakerDocument?.takerDocumentUploads]);

  useEffect(() => {
    setTakerDocumentUploads(activeTakerDocument?.takerDocumentUploads);
  }, [activeTakerDocument]);

  const takerPermissionState: TakerPermissionState = useMemo(() => {
    if (!activeTakerDocument) {
      return {
        allGrants: [],
        isUnknown: true,
        isForbidden: false,
        isRead: false,
        isReadWrite: false,
        isAdmin: false
      };
    }

    if (!activeTakerDocument.state) {
      return {
        allGrants: [],
        isUnknown: true,
        isForbidden: false,
        isRead: false,
        isReadWrite: false,
        isAdmin: false
      };
    }

    const allGrants: TakerAccessType[] = [];
    if (originalTaker.ownerUserId === userId) {
      allGrants.push("OWNER");
    }

    for (const takerDocumentAccessGrant of activeTakerDocument.takerDocumentAccessGrants) {
      if (takerDocumentAccessGrant.userId === userId) {
        allGrants.push(takerDocumentAccessGrant.type);
      }
    }

    if (allGrants.length === 0) {
      return {
        allGrants,
        isUnknown: false,
        isForbidden: true,
        isRead: false,
        isReadWrite: false,
        isAdmin: false
      };
    }

    let hasRead = allGrants.includes("READ");
    let hasOwner = allGrants.includes("OWNER");
    let hasReview = allGrants.includes("REVIEW");

    let inCompleted = activeTakerDocument.state.reviewState === "COMPLETED";
    if (inCompleted && (allGrants.length > 0)) {
      return {
        allGrants,
        isUnknown: false,
        isForbidden: false,
        isRead: true,
        isReadWrite: false,
        isAdmin: hasOwner
      };
    }

    let inReview = activeTakerDocument.state.reviewState === "PENDING";
    return {
      allGrants,
      isUnknown: false,
      isForbidden: false,
      isRead: (hasRead || hasOwner || hasReview),
      isReadWrite: (!inReview && hasOwner) || (inReview && hasReview),
      isAdmin: hasOwner
    };
  }, [activeTakerDocument, originalTaker, userId]);

  // Decides between the mutated state and the original state.
  const activeTargetTakerState = useRef<TakerStateHolder | undefined>(
    latestQuestionnaireData && new TakerStateHolder(latestQuestionnaireData.content)
  );

  useEffect(() => {
    if (!latestQuestionnaireData) {
      return;
    }

    activeTargetTakerState.current = new TakerStateHolder(latestQuestionnaireData.content);
    setInitTakerStateCount(initTakerStateCount + 1);
  }, [latestQuestionnaireData?.id]);

  const reviewComments = useMemo(() => {
    if (!activeTakerDocument || !activeTakerDocument.state) {
      return [];
    }

    let sortedReviewComments = [...activeTakerDocument.state.reviewComments];
    sortedReviewComments.sort((a, b) => a.createdAt < b.createdAt ? -1 : 1);
    return sortedReviewComments;
  }, [
    activeTakerDocument,
    addTakerDocumentReviewCommentRes,
    updateTakerDocumentReviewCommentRes
  ]);

  const addReviewComment = useCallback(
    (
      lexical: any,
      text: string,
      commentType: "TOP_LEVEL" | "DOCUMENT_HIGHLIGHT",
      commentMetadata: Record<string, any>
    ) => {
      addTakerDocumentReviewComment({
        takerDocumentId,
        commentType,
        commentLexical: lexical,
        commentText: text,
        commentMetadata
      });
    },
    [
      takerDocumentId
    ]
  );

  const updateReviewComment = useCallback(
    (
      commentId: string,
      lexical: any,
      text: string
    ) => {
      updateTakerDocumentReviewComment({
        id: commentId,
        takerDocumentId,
        commentLexical: lexical,
        commentText: text,
      });
    },
    [
      takerDocumentId
    ]
  );

  // Decides between the mutated state and the original state.
  const getTakerName: TakerNameGetter = () => {
    if (!originalTaker) {
      return null;
    }
    return takerName ? takerName : originalTaker.name;
  };

  const getActiveSetting = (type: TakerDocumentSettingsPropertyType) => {
    if (activeTakerDocument?.settings) {
      for (const property of activeTakerDocument?.settings.properties) {
        if (property.type === type) {
          return property.value;
        }
      }
    }

    if (defaultTakerDocumentSettings) {
      for (const property of defaultTakerDocumentSettings.properties) {
        if (property.type === type) {
          return property.value;
        }
      }
    }
  }

  // Do the taker output computation when the state changes. Save with a delay as to not overwhelm the server.
  const doTakerStateUpdateFunc = (newTakerState: TakerStateHolder, doImmediateUpdate: boolean) => {
    // no updates for non write roles
    if (!takerPermissionState.isReadWrite) {
      return
    }

    activeTargetTakerState.current = newTakerState;

    if (updatingJob) {
      clearTimeout(updatingJob)
    }

    function doUpdate() {
      if (latestQuestionnaireData && activeTargetTakerState.current) {
        updateTakerDocumentQuestionnaire({
          takerId: originalTaker.id,
          takerDocumentId,
          id: latestQuestionnaireData.id,
          content: activeTargetTakerState.current.uiDataSchema,
          enableCaching: enableQuestionnaireCaching ? "TRUE": "FALSE"
        });
        preventUpdateOutput.current = false;
      }
    }

    setIsStateDirty(true);
    if (doImmediateUpdate) {
      doUpdate();
    } else {
      let newUpdatingJob = setTimeout(doUpdate, SAVE_DELAY);
      setUpdatingJob(newUpdatingJob);
    }
  };

  const doTakerStateUpdate = useRef(doTakerStateUpdateFunc);
  useEffect(() => {
    doTakerStateUpdate.current = doTakerStateUpdateFunc;
  });

  // Save our metadata with some delay to not overwhelm the server with updates
  useEffect(() => {
    // no updates for non read/roles
    if (!takerPermissionState.isReadWrite) {
      return
    }

    if (updatingMetadataJob) {
      clearTimeout(updatingMetadataJob)
    }
    let updateJob = setTimeout(() => {
      if (takerName) {
        updateTaker({
          id: originalTaker.id,
          name: takerName,
          description: originalTaker.description
        });
      }
    }, METADATA_SAVE_DELAY);
    setUpdatingMetadataJob(updateJob);
    return () => clearTimeout(updateJob);
  }, [takerName]);

  const lastSavedTimestamp = useMemo(
    () => !isQuestionnaireDataError && (lastMarkedClean || latestQuestionnaireData?.updatedAt),
    [
      isQuestionnaireDataError,
      lastMarkedClean,
      latestQuestionnaireData
    ]
  );

  return (
    <Context.Provider
      value={{
        // raw data for consumer
        taker: originalTaker,
        activeTargetTakerState: activeTargetTakerState.current,
        takerDocumentUploads: takerDocumentUploadsState,
        refetchTakerDocument,

        takerDocumentAnalysesInProgress,
        takerDocumentUploadAnalysesInProgress,

        // Data state, for loading and initializing page
        isSaving: updateTakerResult.isLoading,
        initTakerStateCount: initTakerStateCount,
        isTakerDocumentUninitialized: isTakerDocumentUninitialized,
        isQuestionnaireUninitialized: isQuestionnaireDataUninitialized || isTakerDocumentUninitialized,
        isTakerDocumentLoading,

        lastSavedTimestamp,
        takerDocumentId,
        latestQuestionnaireDataId: latestQuestionnaireData?.id,
        latestQuestionnaireData,

        // TODO: this may not always update
        activeTakerDocument,
        takerPermissionState,
        takerName: getTakerName(),

        updateTakerName: (n: string) => setTakerName(n),

        questionnareDataService: {
          updateAnswer(moduleIds, q, value, iteration) {
            if (activeTargetTakerState.current) {
              const newTargetState = new TakerStateHolder(activeTargetTakerState.current.uiDataSchema);
              newTargetState.updateAnswer(moduleIds, q, value, iteration);
              doTakerStateUpdate.current(newTargetState, false);
            }
          },
          updateAnswers(moduleIds, nameValuePairs, iteration) {
            if (activeTargetTakerState.current) {
              const newTargetState = new TakerStateHolder(activeTargetTakerState.current.uiDataSchema);
              for (let i = 0; i < nameValuePairs.length; i++) {
                newTargetState.updateAnswer(moduleIds, nameValuePairs[i].name, nameValuePairs[i].value, iteration);
              }
              doTakerStateUpdate.current(newTargetState, false);
            }
          },
          updateAnalysis(moduleIds, q, value, iteration) {
            if (activeTargetTakerState.current) {
              const newTargetState = new TakerStateHolder(activeTargetTakerState.current.uiDataSchema);
              newTargetState.updateAnalysis(moduleIds, q, value, iteration);
              doTakerStateUpdate.current(newTargetState, false);
            }
          },
          updateAnalysisFull(moduleIds, q, value, refs, sources, iteration) {
            if (activeTargetTakerState.current) {
              const newTargetState = new TakerStateHolder(activeTargetTakerState.current.uiDataSchema);
              newTargetState.updateAnalysis(moduleIds, q, value, iteration);
              newTargetState.updateReference(moduleIds, q, refs, iteration);
              newTargetState.updateSource(moduleIds, q, sources, iteration);
              doTakerStateUpdate.current(newTargetState, false);
            }
          },
          updateReference(moduleIds, q, value, iteration) {
            if (activeTargetTakerState.current) {
              const newTargetState = new TakerStateHolder(activeTargetTakerState.current.uiDataSchema);
              newTargetState.updateReference(moduleIds, q, value, iteration);
              doTakerStateUpdate.current(newTargetState, false);
            }
          },
          updateSource(moduleIds, q, value, iteration) {
            if (activeTargetTakerState.current) {
              const newTargetState = new TakerStateHolder(activeTargetTakerState.current.uiDataSchema);
              newTargetState.updateSource(moduleIds, q, value, iteration);
              doTakerStateUpdate.current(newTargetState, false);
            }
          },
          getAnswer: (moduleIds, q, iteration) =>
            activeTargetTakerState.current && activeTargetTakerState.current.getAnswer(moduleIds, q, iteration),
          getAnalysis: (moduleIds, q, iteration) =>
            activeTargetTakerState.current && activeTargetTakerState.current.getAnalysis(moduleIds, q, iteration),
          getReference: (moduleIds, q, iteration) =>
            activeTargetTakerState.current && activeTargetTakerState.current.getReference(moduleIds, q, iteration),
          getSource: (moduleIds, q, iteration) =>
            activeTargetTakerState.current && activeTargetTakerState.current.getSource(moduleIds, q, iteration),
          getMockAnswer: (moduleIds, q, iteration) =>
            dummyTakerState.getAnswer(moduleIds, q, iteration),
          getMockAnalysis: (moduleIds, q, iteration) =>
            dummyTakerState.getAnalysis(moduleIds, q, iteration),
          getMockReference: (moduleIds, q, iteration) =>
            dummyTakerState.getReference(moduleIds, q, iteration),
          getMockSource: (moduleIds, q, iteration) =>
            dummyTakerState.getSource(moduleIds, q, iteration),
          addIterations(moduleIds, n) {
            if (activeTargetTakerState.current) {
              const newTargetState = new TakerStateHolder(activeTargetTakerState.current.uiDataSchema);
              for (let i = 0; i < n; i++) {
                newTargetState.addIteration(moduleIds);
              }
              doTakerStateUpdate.current(newTargetState, true);
            }
          },
          removeIteration(moduleIds, iteration) {
            if (activeTargetTakerState.current) {
              const newTargetState = new TakerStateHolder(activeTargetTakerState.current.uiDataSchema);
              newTargetState.removeIteration(moduleIds, iteration);
              doTakerStateUpdate.current(newTargetState, true);
            }
          },
          count: (moduleIds) =>
            activeTargetTakerState.current && activeTargetTakerState.current.count(moduleIds),
        
          getGuidanceReferenceById: (id) => 
            activeTargetTakerState.current && activeTargetTakerState.current.getGuidanceReferenceById(id),

          getSourceReferenceById: (id) => 
            activeTargetTakerState.current && activeTargetTakerState.current.getSourceReferenceById(id),

          addWorkflowQuestionsAnalysis(analysisId) {
            if (activeTargetTakerState.current) {
              const newTargetState = new TakerStateHolder(activeTargetTakerState.current.uiDataSchema);
              newTargetState.addWorkflowQuestionsAnalysis(analysisId);
              doTakerStateUpdate.current(newTargetState, true);
            }
          },
          addSingleWorkflowQuestionAnalysis(analysisId, moduleIds, nodeId, iteration) {
            if (activeTargetTakerState.current) {
              const newTargetState = new TakerStateHolder(activeTargetTakerState.current.uiDataSchema);
              newTargetState.addSingleWorkflowQuestionAnalysis(analysisId, moduleIds, nodeId, iteration);
              doTakerStateUpdate.current(newTargetState, true);
            }
          },
          addSingleWorkflowQuestionAnalysisForAnswerOnly(analysisId, moduleIds, nodeId, iteration) {
            if (activeTargetTakerState.current) {
              const newTargetState = new TakerStateHolder(activeTargetTakerState.current.uiDataSchema);
              newTargetState.addSingleWorkflowQuestionAnalysisForAnswerOnly(analysisId, moduleIds, nodeId, iteration);
              doTakerStateUpdate.current(newTargetState, true);
            }
          },
          addSingleWorkflowQuestionAnalysisForAnalysisOnly(analysisId, moduleIds, nodeId, iteration) {
            if (activeTargetTakerState.current) {
              const newTargetState = new TakerStateHolder(activeTargetTakerState.current.uiDataSchema);
              newTargetState.addSingleWorkflowQuestionAnalysisForAnalysisOnly(analysisId, moduleIds, nodeId, iteration);
              doTakerStateUpdate.current(newTargetState, true);
            }
          },
          removeSingleWorkflowQuestionAnalysis(analysisId) {
            if (activeTargetTakerState.current) {
              const newTargetState = new TakerStateHolder(activeTargetTakerState.current.uiDataSchema);
              newTargetState.removeSingleWorkflowQuestionAnalysis(analysisId);
              doTakerStateUpdate.current(newTargetState, true);
            }
          },
          removeWorkflowQuestionsAnalysis(analysisId) {
            if (activeTargetTakerState.current) {
              const newTargetState = new TakerStateHolder(activeTargetTakerState.current.uiDataSchema);
              newTargetState.removeWorkflowQuestionsAnalysis(analysisId);
              doTakerStateUpdate.current(newTargetState, true);
            }
          },
          addressAnswerForAnalysis(analysisId, questionId) {
            console.log("addressAnswerForAnalysis", analysisId);
            if (activeTargetTakerState.current) {
              const newTargetState = new TakerStateHolder(activeTargetTakerState.current.uiDataSchema);
              let targetAnalysis = newTargetState.addressAnswerForAnalysis(analysisId, questionId);
              doTakerStateUpdate.current(newTargetState, true);
              return targetAnalysis
            }
          },
          addressAnalysisForAnalysis(analysisId, questionId) {
            console.log("addressAnalysisForAnalysis", analysisId);
            if (activeTargetTakerState.current) {
              const newTargetState = new TakerStateHolder(activeTargetTakerState.current.uiDataSchema);
              let targetAnalysis = newTargetState.addressAnalysisForAnalysis(analysisId, questionId);
              doTakerStateUpdate.current(newTargetState, true);
              return targetAnalysis;
            }
          }
        } as QuestionnaireDataService,

        takerOutput: takerDocumentStateV2,

        // fulfillment state should be undefined until the API returns a response
        fulfillmentStateHolder: isTakerDocumentStateV2Success &&
          takerDocumentStateV2['fulfillment_state'] &&
          new FulfillmentStateHolder(takerDocumentStateV2['fulfillment_state']),

        isTakerStateDirty: isStateDirty,

        getVariableValue: (mids: string[], varRef: VariableReference) =>
          getVariableValue(mids, varRef, takerDocumentStateV2["scoped_variables"]),

        getActiveSetting,

        commentsOpen,
        setCommentsOpen,
        reviewComments,
        addReviewComment,
        updateReviewComment
      }}>
      {children}
    </Context.Provider>
  );
};

export default memo(TakerDocumentContainer);