import React, { useEffect, useMemo, useState, useCallback, memo } from 'react';
import { TreeItem } from '@mui/x-tree-view/TreeItem';
import { SimpleTreeView } from '@mui/x-tree-view/SimpleTreeView';
import { useResearchPanelData } from '../../../containers/ResearchPanelData/ResearchPanelData';

interface GuidanceOutlineItemProps {
    guidanceId: string;
    labeledRefs: any[];
    contentIds: string[] | undefined;
}

const GuidanceOutlineItem = ({
    guidanceId,
    labeledRefs,
    contentIds
}: GuidanceOutlineItemProps) => {
    const {
        guidanceOutlineOpen,
        researchPanelBasicState,
        selectedGuidanceId,
        selectedGuidanceItemId,
        setSelectedGuidanceItemId
    } = useResearchPanelData();

    const [expandedItems, setExpandedItems] = useState<string[]>([]);

    useEffect(() => {
        // check if this particular guidance is currently selected and visible
        if (researchPanelBasicState.isOpen 
            && researchPanelBasicState.currentTab === "GUIDANCE"
            && guidanceOutlineOpen 
            && guidanceId === selectedGuidanceId
        ) {
            const itemsToExpand: string[] = [];
            const iterate = (ids: string[], ref: any) => {
                const isSelected = selectedGuidanceItemId === ref['id'];
                const isInFilter = contentIds?.includes(ref['id']);
                if (isSelected || isInFilter) {
                    itemsToExpand.push(...ids);
                }
                for (let i = 0; i < ref['nested'].length; i++) {
                    iterate([...ids, ref['id']], ref['nested'][i]);
                }
            }
    
            for (let i = 0; i < labeledRefs.length; i++) {
                iterate([], labeledRefs[i]);
            }
            setExpandedItems(itemsToExpand);
        }
    }, [
        guidanceId,
        guidanceOutlineOpen,
        researchPanelBasicState.currentTab,
        researchPanelBasicState.isOpen,
        selectedGuidanceId,
        contentIds,
        labeledRefs,
        selectedGuidanceItemId // Added missing dependency
    ]);

    // TODO: filteredlabeledRefs
    const filteredLabeledRefs = useMemo(() => {
        if (!contentIds) {
            return labeledRefs;
        }

        const filterNested = (refs: any[]): any[] => {
            const filteredRefs = [];
            for (const ref of refs) {
                if (contentIds.includes(ref['id'])) {
                    filteredRefs.push(ref);
                } else {
                    const nestedFiltered = filterNested(ref['nested']);
                    if (nestedFiltered.length > 0) {
                        filteredRefs.push({
                            ...ref,
                            nested: nestedFiltered
                        });
                    }
                }
            }
            return filteredRefs;
        }
        return filterNested(labeledRefs);
    }, [
        contentIds, 
        labeledRefs
    ]);

    const renderSubTrees = useCallback((
        depth: number,
        indexEntries: any[]
    ) => {
        const treeItems = []
        for (let i = 0; i < indexEntries.length; i++) {
            const indexEntry = indexEntries[i];
            if (indexEntry['nested'] && indexEntry['nested'].length > 0) {
                treeItems.push(
                    <TreeItem
                        itemId={indexEntry['id']}
                        label={indexEntry['label']}
                    >
                        <>
                            {renderSubTrees(depth + 1, indexEntry['nested'])}
                        </>
                    </TreeItem>
                );
            } else {
                treeItems.push(
                    <TreeItem
                        itemId={indexEntry['id']}
                        label={indexEntry['label']}
                    />
                );
            }
        }
        return treeItems;
    }, []);

    const handleExpandedItemsChange = useCallback((e: any, itemIds: string[]) => {
        setExpandedItems(itemIds);
    }, []);

    const handleItemSelectionToggle = useCallback((e: any, itemId: string) => {
        const headerElem = document.getElementById(itemId);
        if (headerElem) {
            headerElem.scrollIntoView({
                block: "center",
                inline: "center",
                behavior: "smooth"
            });
        }
        setSelectedGuidanceItemId(itemId);
    }, [setSelectedGuidanceItemId]);

    return (
        <SimpleTreeView
            expandedItems={expandedItems}
            onExpandedItemsChange={handleExpandedItemsChange}
            selectedItems={selectedGuidanceItemId}
            onItemSelectionToggle={handleItemSelectionToggle}
            itemChildrenIndentation={0}
        >
            {renderSubTrees(0, filteredLabeledRefs)}
        </SimpleTreeView>
    );
};

export default memo(GuidanceOutlineItem);