import React, { useEffect, useState } from "react";
import { Button, Typography, CircularProgress, Box, List, ListItem, ListItemText, Modal, Table, TableBody, TableCell, TableHead, TableRow, Checkbox, IconButton, Select, MenuItem, SelectChangeEvent, TextField } from "@mui/material";
import { ExpandMore, ExpandLess, Delete } from "@mui/icons-material";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useBuilderDocument } from "../SpecBuilder/CustomCommentaryWidget/context";
import { SimpleModalWrapper } from "../../../components/dialog/wrappers/simpleModalWrapper";

interface JobResponse {
    id: string;
    [key: string]: any;
}

// Trigger the job by calling the API endpoint
async function createQuestionPrompt(builderDocumentId: string): Promise<JobResponse> {
    const baseUrl = !!window.__RUNTIME_CONFIG__ ? window.__RUNTIME_CONFIG__.API_ENDPOINT : "localhost";
    const response = await fetch(`${baseUrl}/create_builder_question_prompt_job`, {
        method: "POST",
        credentials: "include",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ builder_document_id: builderDocumentId })
    });
    return await response.json();
}

const BuilderQuestionGeneratedPrompts = () => {
    const [selectedPrompts, setSelectedPrompts] = useState<any[]>([]);
    const [expandedRows, setExpandedRows] = useState<{ [key: string]: boolean }>({});
    const [expandedPromptIds, setExpandedPromptIds] = useState<{ [key: string]: boolean }>({});
    const [payload, setPayload] = useState<any>();

    const { builderDocument } = useBuilderDocument();
    const queryClient = useQueryClient();

    const createJobMutation = useMutation({
        mutationFn: (builderDocumentId: string) => createQuestionPrompt(builderDocumentId),
        onSuccess: () => {
            // the new job will appear as part of builderDocument via automatic re-fetching/updating
        }
    });

    const createPromptMutation = useMutation({
        mutationFn: (newPrompt: any) => {
            const baseUrl = !!window.__RUNTIME_CONFIG__ ? window.__RUNTIME_CONFIG__.API_ENDPOINT : "localhost";
            return fetch(`${baseUrl}/builder_documents/${builderDocument.id}/generated_prompts`, {
                method: "POST",
                credentials: "include",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify(newPrompt)
            }).then(res => res.json());
        },
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ["BuilderDocument", builderDocument.id] });
        }
    });

    const deletePromptMutation = useMutation({
        mutationFn: (promptId: string) => {
            const baseUrl = !!window.__RUNTIME_CONFIG__ ? window.__RUNTIME_CONFIG__.API_ENDPOINT : "localhost";
            return fetch(`${baseUrl}/builder_documents/${builderDocument.id}/generated_prompts/${promptId}`, {
                method: "DELETE",
                credentials: "include",
                headers: { "Content-Type": "application/json" }
            });
        },
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ["BuilderDocument", builderDocument.id] });
        }
    });

    // sort jobs - assume each job may have a createdAt property, fallback to id comparison
    const jobs = builderDocument?.builderQuestionPromptGenerationJobs || [];
    const sortedJobs = [...jobs].sort((a, b) =>
        (b.createdAt || b.id).toString().localeCompare((a.createdAt || a.id).toString())
    );

    const handleCheckboxChange = (prompt: any) => {
        setSelectedPrompts((prevSelected) => {
            if (prevSelected.includes(prompt)) {
                return prevSelected.filter((p) => p !== prompt);
            } else {
                return [...prevSelected, prompt];
            }
        });
    };

    const handleAddSelectedPrompts = () => {
        selectedPrompts.forEach((prompt) => {
            createPromptMutation.mutate({
                question_id: prompt.id,
                question_hash: prompt.question_hash,
                expanded_prompt_markdown: prompt.expanded_solution
            });
        });
        setSelectedPrompts([]);
        setPayload(undefined);
    };

    const handleSelectAll = () => {
        if (selectedPrompts.length === payload?.cot_prompts?.length) {
            setSelectedPrompts([]);
        } else {
            setSelectedPrompts(payload?.cot_prompts || []);
        }
    };

    const handleCopySelectedPrompts = () => {
        navigator.clipboard.writeText(JSON.stringify(payload));
    };

    const toggleRowExpansion = (promptId: string) => {
        setExpandedRows((prevExpandedRows) => ({
            ...prevExpandedRows,
            [promptId]: !prevExpandedRows[promptId]
        }));
    };

    const togglePromptExpansion = (promptId: string) => {
        setExpandedPromptIds((prevExpandedPromptIds) => ({
            ...prevExpandedPromptIds,
            [promptId]: !prevExpandedPromptIds[promptId]
        }));
    };

    return (
        <>
            <Box p={2}>
                {builderDocument ? (
                    <Box
                        display="flex"
                        alignItems="center"
                        justifyContent="start"
                        gap={2}
                    >
                        <Button
                            variant="outlined"
                            onClick={() => createJobMutation.mutate(builderDocument.id)}
                            disabled={createJobMutation.isPending}
                        >
                            {createJobMutation.isPending ? "Starting..." : "Generate Prompts"}
                        </Button>
                        <Select
                            size="small"
                            value={""}
                            onChange={(event: SelectChangeEvent<string>) => {
                                async function fetchJobPayload(): Promise<any> {
                                    const jobId = event.target.value as string;
                                    const baseUrl = !!window.__RUNTIME_CONFIG__ ? window.__RUNTIME_CONFIG__.API_ENDPOINT : "localhost";
                                    const response = await fetch(`${baseUrl}/builder_document_jobs/${jobId}/payload`, {
                                        method: "GET",
                                        credentials: "include",
                                        headers: { "Content-Type": "application/json" },
                                    });
                                    return await response.json();
                                }
                                fetchJobPayload().then((payload) => {
                                    setPayload(payload);
                                });
                            }}
                            displayEmpty
                        >
                            <MenuItem value="" disabled>
                                Select a job
                            </MenuItem>
                            {sortedJobs.map((job) => (
                                <MenuItem key={job.id} value={job.id}>
                                    {`Job created at ${new Date(job.createdAt).toLocaleString()} - Status: ${job.state}`}
                                </MenuItem>
                            ))}
                        </Select>
                        <TextField
                            placeholder="Paste JSON"
                            variant="outlined"
                            size="small"
                            onChange={(event) => {
                                try {
                                    const parsedPayload = JSON.parse(event.target.value);
                                    setPayload(parsedPayload);
                                } catch (error) {
                                    console.error("Invalid JSON:", error);
                                }
                            }}
                        />
                    </Box>
                ) : (
                    <CircularProgress />
                )}
                <Box mt={2}>
                    <List>
                        {builderDocument.builderDocumentGeneratedPrompts.map((prompt) => (
                            <ListItem key={prompt.id} divider>
                                <ListItemText
                                    primary={prompt.questionId}
                                    secondary={
                                        <>
                                            {expandedPromptIds[prompt.id]
                                                ? <span style={{ whiteSpace: 'pre-wrap' }}>{prompt.expandedPromptMarkdown}</span>
                                                : `${prompt.expandedPromptMarkdown.substring(0, 100)}...`}
                                            <Button
                                                size="small"
                                                onClick={() => togglePromptExpansion(prompt.id)}
                                            >
                                                {expandedPromptIds[prompt.id] ? "Show Less" : "Show More"}
                                            </Button>
                                        </>
                                    }
                                />
                                <IconButton
                                    size="small"
                                    color="error"
                                    onClick={() => deletePromptMutation.mutate(prompt.id)}
                                >
                                    <Delete />
                                </IconButton>
                            </ListItem>
                        ))}
                    </List>
                </Box>
            </Box>
            <SimpleModalWrapper
                headerText="Job Payload"
                maxWidth="lg"
                open={!!payload}
                handleClose={() => {
                    setPayload(undefined);
                    setSelectedPrompts([]);
                    setExpandedRows({});
                }}
            >
                <Box p={1} gap={1}>
                    <Button
                        variant="contained"
                        size="small"
                        onClick={handleSelectAll}
                    >
                        {selectedPrompts.length === payload?.cot_prompts?.length ?
                            "Deselect All" :
                            "Select All"
                        }
                    </Button>
                    <Button
                        variant="contained"
                        size="small"
                        onClick={handleCopySelectedPrompts}
                        disabled={!payload}
                    >
                        Copy Selected
                    </Button>
                </Box>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Select</TableCell>
                            <TableCell>Prompt ID</TableCell>
                            <TableCell>Expanded Solution</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {payload?.cot_prompts?.map((prompt: any) => (
                            <TableRow key={prompt.id}>
                                <TableCell>
                                    <Checkbox
                                        checked={selectedPrompts.includes(prompt)}
                                        onChange={() => handleCheckboxChange(prompt)}
                                    />
                                </TableCell>
                                <TableCell>{prompt.id}</TableCell>
                                <TableCell>
                                    {expandedRows[prompt.id] ? prompt.expanded_solution : `${prompt.expanded_solution.substring(0, 100)}...`}
                                    <IconButton size="small" onClick={() => toggleRowExpansion(prompt.id)}>
                                        {expandedRows[prompt.id] ? <ExpandLess /> : <ExpandMore />}
                                    </IconButton>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
                <Button
                    variant="contained"
                    size="small"
                    onClick={handleAddSelectedPrompts}
                    disabled={selectedPrompts.length === 0}
                >
                    Add Selected Prompts
                </Button>


            </SimpleModalWrapper>
        </>
    );
};

export default BuilderQuestionGeneratedPrompts;
