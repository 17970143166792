import React, { useContext, PropsWithChildren, useEffect, useState, memo, useRef } from 'react';
import { BuilderHolder } from "../../types/builder";
import JSZip from "jszip";
import axios from 'axios';
import { GuidanceDisplayMode, IndexedGuidance } from '../../redux/models/dataModelTypes';

interface GuidanceDataHookData {
    builderHolder: BuilderHolder;
    getContentFromGuidance: (guidanceId: string, guidanceContentId: string) => any;
    getRefFromGuidance: (guidanceId: string) => any;
    guidanceIdLookup: Record<string, { id: string, packageTag: string, label: string }>;
    aliasLookup: Record<string, string>;
    guidanceReferences: GuidanceReference[];
    isLoadingGuidance: boolean;
}

const baseUrl = !!window.__RUNTIME_CONFIG__ ? window.__RUNTIME_CONFIG__.API_ENDPOINT : "localhost";

const _guidanceCache = new Map<string, JSZip>();

const getZippedGuidance = async (indexedGuidanceId: string, version: string) => {
    if (_guidanceCache.has(indexedGuidanceId)) {
        return _guidanceCache.get(indexedGuidanceId);
    }

    if (_guidanceCache.size > 2) {
        // remove all other references
        for (const k of _guidanceCache.keys()) {
            if (k !== indexedGuidanceId) {
                _guidanceCache.delete(k);
            }
        }
    }

    const zipped = await new Promise<JSZip>((resolve) => {
        axios({
            url: `${baseUrl}/indexed_guidance/${indexedGuidanceId}/download_package`,
            method: "POST",
            headers: {
                'Cache-control': 'max-age=86400'
            },
            data: {
                version
            },
            responseType: "blob",
            withCredentials: true
        }).then(d => {
            const zip = new JSZip();
            zip.loadAsync(d.data).then(async (zipped) => {
                if (zipped) {
                    resolve(zipped);
                }
            });
        });
    });

    _guidanceCache.set(indexedGuidanceId, zipped);
    return zipped;
};

type GuidanceDataHook = () => GuidanceDataHookData;

const Context = React.createContext({});

export interface GuidanceReference {
    indexedGuidance: IndexedGuidance | null;
    displayMode: GuidanceDisplayMode;
    indexedGuidanceVersion: string;
    canCite: Boolean;
}

export const useGuidanceData: GuidanceDataHook = () => {
    return useContext(Context) as GuidanceDataHookData;
}

interface GuidanceDataProps {
    guidanceReferences: GuidanceReference[];
}

const GuidanceData: React.FC<PropsWithChildren<GuidanceDataProps>> = ({
    guidanceReferences,
    children
}) => {
    const [guidanceIdLookup, setGuidanceIdLookup] = useState<Record<string, { id: string, packageTag: string }>>({});
    const [aliasLookup, setAliasLookup] = useState<Record<string, string>>({});
    const [indexedGuidanceById, setIndexedGuidanceById] = useState<Record<string, IndexedGuidance>>({});
    const [versionById, setVersionById] = useState<Record<string, string>>({});
    const [isLoadingGuidance, setIsLoadingGuidance] = useState(false);
    const guidancePackagePromiseMapRef = useRef<Map<string, Promise<JSZip | undefined>>>(new Map());

    useEffect(() => {
        if (!guidanceReferences) {
            return;
        }

        const idLookup: Record<string,{ id: string, packageTag: string, label: string }> = {};
        const aliasToIdLookup: Record<string, string> = {};
        const newIndexedGuidanceById: Record<string, IndexedGuidance> = {};
        const newVersionById: Record<string, string> = {};
        for (const bdg of guidanceReferences) {
            if (!bdg.indexedGuidance) {
                continue;
            }

            newIndexedGuidanceById[bdg.indexedGuidance.guidanceId] = bdg.indexedGuidance;
            newVersionById[bdg.indexedGuidance.guidanceId] = bdg.indexedGuidanceVersion;


            const collectAllHeaders = (labeledRefs: any[]) => {
                for (const labeledRef of labeledRefs) {
                    const id = labeledRef["id"];
                    const hasContent = labeledRef["hasContent"];
                    if (hasContent && bdg.indexedGuidance) {
                        idLookup[id] = {
                            id: bdg.indexedGuidance.guidanceId,
                            packageTag: "latest",
                            label: bdg.indexedGuidance.name
                        };
                        if (labeledRef["alt_ids"]) {
                            for (const alts of labeledRef["alt_ids"]) {
                                aliasToIdLookup[alts] = id;
                            }
                        }
                    } else {
                        collectAllHeaders(labeledRef["nested"]);
                    }
                }
            };

            const refs = bdg.indexedGuidance.referenceMap
            collectAllHeaders(refs.labeledRefs);
        }

        setAliasLookup(aliasToIdLookup);
        setGuidanceIdLookup(idLookup);
        setIndexedGuidanceById(newIndexedGuidanceById);
        setVersionById(newVersionById);
    }, [guidanceReferences]);

    const getContentFromGuidance = async (guidanceId: string, guidanceContentId: string) => {
        const indexedGuidance = indexedGuidanceById[guidanceId];
        const version = versionById[guidanceId];
        if (!indexedGuidance || !version) {
            return;
        }

        // check if we already have a promise. If not create one.
        let guidancePackagePromise = undefined;
        if (guidancePackagePromiseMapRef.current.has(guidanceId)) {
            guidancePackagePromise = guidancePackagePromiseMapRef.current.get(guidanceId);
        } else {
            guidancePackagePromise = getZippedGuidance(indexedGuidance.id, version);
            guidancePackagePromiseMapRef.current.set(guidanceId, guidancePackagePromise);
        }

        // wait for the promise to resolve and then remove it fro the map
        setIsLoadingGuidance(true);
        const guidancePackage = await guidancePackagePromise;
        setIsLoadingGuidance(false);
        guidancePackagePromiseMapRef.current.delete(guidanceId);

        if (guidancePackage === undefined) {
            return;
        }

        let jsonFile = guidancePackage.file(`${guidanceContentId}.json`);
        if (jsonFile) {
            return JSON.parse(await jsonFile.async('string'));
        }
    };

    const getRefFromGuidance = async (guidanceId: string) => {
        const indexedGuidance = indexedGuidanceById[guidanceId];
        if (!indexedGuidance) {
            return;
        }
        return indexedGuidance.referenceMap.labeledRefs;
    };

    return (
        <Context.Provider
            value={{
                getContentFromGuidance,
                getRefFromGuidance,
                guidanceIdLookup,
                aliasLookup,
                guidanceReferences,
                isLoadingGuidance
            }}
        >
            {children}
        </Context.Provider>
    );
};

export default memo(GuidanceData);
