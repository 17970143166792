import {
    Button,
    Menu, 
    MenuItem,
} from "@mui/material/";
import { useGetIndexedGuidancePackageVersionsQuery, useLazyGetIndexedGuidancePackageVersionsQuery } from "../../redux/services/indexedGuidance";
import { useEffect, useState } from "react";
import {
    useAddIndexedGuidanceIndexingJobMutation
} from "../../redux/services/indexedGuidance";
import { useUserScopedAppData } from "../../containers/UserScopedAppData/UserScopedAppData";

interface ExportGuidancePackageButtonProps {
    indexedGuidanceId: string;
}

const IndexGuidancePackageButton = ({ indexedGuidanceId }: ExportGuidancePackageButtonProps) => {
    const [trigger] = useLazyGetIndexedGuidancePackageVersionsQuery();
    const { selectedOrgId } = useUserScopedAppData();

    const [indexGuidanceJob, indexedGuidanceJobRes] = useAddIndexedGuidanceIndexingJobMutation();

    const [availableVersions, setAvalailableVersions] = useState<string[]>([]);

    useEffect(() => {
        trigger(indexedGuidanceId).then((res) => {
            if (res.isSuccess) {
                setAvalailableVersions(res.data.map(
                    s => s.substring(0, s.lastIndexOf("."))
                ));
            }
        }).catch((error) => {
            console.error(error);
        });
    }, [indexedGuidanceId]);

    const [anchorEl, setAnchorEl] = useState<Element>();
    const menuOpen = Boolean(anchorEl);

    return (
        <>
            <Button
                size="small"
                variant="contained"
                sx={{ marginLeft: "15px" }}
                onClick={(e) => {
                    setAnchorEl(e.currentTarget);
                    e.stopPropagation();
                }}
            >
                start indexing job
            </Button>
            <Menu
                elevation={1}
                anchorEl={anchorEl}
                open={menuOpen}
                onClose={() => setAnchorEl(undefined)}
            >
                {availableVersions.map(v => (
                    <MenuItem
                        disableRipple
                        onClick={(e: any) => {
                            indexGuidanceJob({
                                indexedGuidanceId,
                                organizationId: selectedOrgId,
                                guidanceVersion: v
                            });
                            setAnchorEl(undefined);
                        }}
                    >
                        {v}
                    </MenuItem>
                ))}
            </Menu>
        </>
    );
}

export default IndexGuidancePackageButton;

