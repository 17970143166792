import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
    Box,
    Grid,
    Typography,
    Skeleton,
    IconButton,
    Popper,
    Paper,
    Fade,
} from "@mui/material/";
import Markdown from 'react-markdown';
import { blue, grey } from "@mui/material/colors";
import { UserAnalysis } from "../../../redux/models/dataModelTypes";
import { Info, ThumbUp } from "@mui/icons-material";
import remarkGfm from 'remark-gfm';
import {
    useQueryClient,
    useQuery,
    useMutation
} from '@tanstack/react-query';
import { Answer, AnswerMetadata } from "./types";
import TypedText from '../../animatedComponents/TypedText';

import '../../lexical/wrappers/RichTextEditor/index.css';

const DURATION_MINUTES_MS = 2 * 60 * 1000;

async function fetchUserAnalysis(userAnalysisId: string): Promise<UserAnalysis> {
    let baseUrl = !!window.__RUNTIME_CONFIG__ ? window.__RUNTIME_CONFIG__.API_ENDPOINT : "localhost";
    let response = await fetch(
        `${baseUrl}/user_analyses/${userAnalysisId}`,
        {
            credentials: 'include',
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        }
    )
    return await response.json();
}

async function fetchUserAnalysisPayload(userAnalysisId: string): Promise<any> {
    let baseUrl = !!window.__RUNTIME_CONFIG__ ? window.__RUNTIME_CONFIG__.API_ENDPOINT : "localhost";
    let response = await fetch(
        `${baseUrl}/user_analyses/${userAnalysisId}/payload`,
        {
            credentials: 'include',
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        }
    )
    return await response.json();
}

async function updateUserAnalysis({
    id,
    data
}: {
    id: string;
    data: any;
}): Promise<UserAnalysis> {
    let baseUrl = !!window.__RUNTIME_CONFIG__ ? window.__RUNTIME_CONFIG__.API_ENDPOINT : "localhost";
    let response = await fetch(
        `${baseUrl}/user_analyses/${id}`,
        {
            credentials: 'include',
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                data: data
            })
        }
    )
    return await response.json();
}

interface AdvisorUserResponseProps {
    userAnalysis: UserAnalysis;
    isFirstIndex: boolean;
    onUpdateMetadata: (metadata: AnswerMetadata) => void;
}

const AdvisorUserResponse = ({
    userAnalysis,
    isFirstIndex,
    onUpdateMetadata
}: AdvisorUserResponseProps) => {
    const userAnalysisId = userAnalysis.id;
    const [refetchInterval, setRefetchInterval] = useState<number | undefined>(undefined);

    const queryClient = useQueryClient();

    const {
        data: latestUserAnalysis,
    } = useQuery({
        queryKey: ['UserAnalysis', userAnalysisId],
        queryFn: async () => await fetchUserAnalysis(userAnalysisId),
        initialData: userAnalysis,
        refetchInterval: refetchInterval
    });

    const {
        isSuccess: isSuccessAnalysisPayload,
        data: analysisPayload,
    } = useQuery({
        queryKey: ['UserAnalysisPayload', userAnalysisId],
        queryFn: async () => await fetchUserAnalysisPayload(userAnalysisId),
        refetchInterval: refetchInterval,
        enabled: (latestUserAnalysis.state === "PENDING_REVIEW" || latestUserAnalysis.state === "PENDING_GENERATION")
    });

    const userAnalysisMutation = useMutation<UserAnalysis, void, { id: string, data: any }>({
        mutationFn: updateUserAnalysis,
        onSuccess: (data) => {
            // update the cache with the updated result
            queryClient.setQueryData(['UserAnalysis', data.id], data);
        },
    })

    const [pendingGeneration, setPendingGeneration] = useState<boolean | undefined>(undefined);
    const [failureMessage, setFailureMessage] = useState<string | undefined>(undefined);
    const [localAnswers, setLocalAnswers] = useState<Answer[]>([]);

    const handleThumbUp = useCallback(() => {
        if (latestUserAnalysis.state !== "PENDING_REVIEW") {
            return;
        }

        const newData = { ...latestUserAnalysis.data };
        newData["thumbs_up"] = !newData["thumbs_up"];

        userAnalysisMutation.mutate({
            id: userAnalysisId,
            data: newData
        });
    }, [
        latestUserAnalysis,
        userAnalysisId,
        userAnalysisMutation
    ]);

    useEffect(() => {
        if (!isFirstIndex) {
            setRefetchInterval(undefined);
            return;
        }

        const now = new Date().getTime();
        if ((now - latestUserAnalysis.createdAt) > DURATION_MINUTES_MS) {
            setRefetchInterval(undefined);
        } else if (latestUserAnalysis.state === "PENDING_GENERATION") {
            setRefetchInterval(1000);
        } else {
            setRefetchInterval(undefined);
        }
    }, [
        isFirstIndex,
        latestUserAnalysis,
    ]);

    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
    const [open, setOpen] = useState(false);

    const handleInfoClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
        setOpen((prev) => !prev);
    };

    useEffect(() => {
        // If the analysis is still pending generation
        if (latestUserAnalysis.state === "PENDING_GENERATION") {
            setPendingGeneration(true);
        } else if (latestUserAnalysis.state === "PENDING_REVIEW") {
            setPendingGeneration(false);
        } else if (latestUserAnalysis.state === "PENDING_GENERATION_CANCELED") {
            let msg = userAnalysis.data['job_failure'];
            if (!msg) {
                msg = "The generation was canceled or something went wrong.";
            }
            setFailureMessage(msg);
        }
    }, [latestUserAnalysis.state]);

    useEffect(() => {
        if (!isSuccessAnalysisPayload) {
            return;
        }

        if (analysisPayload && analysisPayload.answers) {
            setLocalAnswers(analysisPayload.answers);
            for (const answer of analysisPayload.answers) {
                if (answer.metadata) {
                    onUpdateMetadata(answer.metadata);
                }
            }
        }
    }, [
        isSuccessAnalysisPayload,
        analysisPayload,
    ]);

    const taskDisplayText = useMemo(
        () => userAnalysis.data['custom_query'],
        [userAnalysis]
    );

    const popupDisplay = useMemo(() => {
        const firstAnswer = localAnswers[0]
        if (!firstAnswer?.metadata?.used_guidance_list) {
            return null;
        }

        return (
            <Typography sx={{ p: 1 }}>
                {firstAnswer.metadata && (
                    <>
                        <Typography variant="body1">
                            Sources:
                        </Typography>
                        <ul>
                            {firstAnswer.metadata.used_guidance_list.map((n) => <li>{n.content_alias}</li>)}
                        </ul>
                        <Typography variant="body1">
                            Internal Monolog:
                        </Typography>
                        <Typography
                            variant="body1"
                            sx={{ whiteSpace: "pre-wrap" }}
                        >
                            {firstAnswer.metadata.expanded_task_solution}
                        </Typography>
                    </>
                )}
            </Typography>
        );
    }, [
        localAnswers
    ]);

    const hasResponse = pendingGeneration === false
        && localAnswers.length > 0
        && failureMessage === undefined;

    const hasPartialResponse = localAnswers.length > 0
        && failureMessage === undefined;

    return (
        <Grid
            height="100%"
            width="100%"
            container
            borderBottom="1px solid #f0f0f0"
        >
            <Grid
                item
                xs={12}
                display="flex"
                justifyContent="start"
            >
                <Typography
                    display="flex"
                    gap={1}
                    alignItems="center"
                    justifyItems="start"
                >
                    <i style={{ color: blue[500] }}>
                        {`${new Date(userAnalysis.createdAt).toLocaleString()}: `}
                        {taskDisplayText}
                    </i>
                    {(hasResponse || hasPartialResponse) ? (
                        <>
                            {popupDisplay && (
                                <span>
                                    <Popper
                                        open={open}
                                        anchorEl={anchorEl}
                                        placement="right"
                                        transition
                                        sx={{
                                            zIndex: 12000,
                                            maxWidth: 500,
                                            maxHeight: 300,
                                            overflow: "auto"
                                        }}
                                    >
                                        {({ TransitionProps }) => (
                                            <Fade {...TransitionProps} timeout={350}>
                                                <Paper
                                                    sx={{
                                                        backgroundColor: grey[600],
                                                        color: "white"
                                                    }}
                                                >
                                                    {popupDisplay}
                                                </Paper>
                                            </Fade>
                                        )}
                                    </Popper>
                                    <IconButton onClick={handleInfoClick}>
                                        <Info />
                                    </IconButton>
                                </span>
                            )}
                            <IconButton
                                onClick={handleThumbUp}
                                disabled={pendingGeneration === true || userAnalysisMutation.isPending}
                                color={(Boolean(latestUserAnalysis.data["thumbs_up"])) ? "primary" : "default"}
                            >
                                <ThumbUp />
                            </IconButton>
                        </>
                    ) : (
                        <Typography
                            variant="subtitle1"
                            textAlign="center"
                            color="textSecondary"
                        >
                            <strong>
                                <TypedText
                                    text={"Thinking..."}
                                    staggerDelay={0.35}
                                    loop
                                    loopDelay={0.25}
                                />
                            </strong>
                        </Typography>
                    )}
                </Typography>
            </Grid>
            <Grid
                item
                xs={12}
                paddingBottom={1}
            >
                {(pendingGeneration === true && !hasPartialResponse) && (
                    <Skeleton
                        animation="wave"
                        variant="rectangular"
                        width="100%"
                        height="35px"
                        sx={{ marginTop: 1 }}
                    />
                )}
                {(hasResponse || hasPartialResponse) && (
                    <>
                        {localAnswers.map((answer, i) => (
                            <Box
                                key={i}
                                width="100%"
                            >
                                {answer.title && (
                                    <Typography
                                        sx={{
                                            marginTop: 1,
                                            marginBottom: 1
                                        }}
                                        variant="h6"
                                    >
                                        <u>{answer.title}</u>
                                    </Typography>
                                )}
                                <Markdown
                                    remarkPlugins={[remarkGfm]}
                                    urlTransform={(url) => {
                                        if (url.startsWith("#")) {
                                            // get ID after the hash symbol
                                            const linkAnchor = url.substring(1);
                                            return `#_gcid=${linkAnchor}`;
                                        }
                                        return;
                                    }}
                                >
                                    {answer.answer}
                                </Markdown>
                            </Box>
                        ))}
                    </>
                )}
                {(pendingGeneration === false && failureMessage !== undefined) && (
                    <Box
                        paddingLeft={1}
                        paddingRight={1}
                        width="100%"
                    >
                        <Typography>
                            <i>{failureMessage}</i>
                        </Typography>
                    </Box>
                )}
            </Grid>
        </Grid>
    );
};

export default AdvisorUserResponse;
