import { Box, IconButton, Toolbar } from "@mui/material";
import { Module } from "../../../types/builderv2.generated";
import { Add, Dataset, Edit } from "@mui/icons-material";
import { useBuilderData } from "./context";

interface GraphEditorToolbarProps {
    targetModule: Module;
    targetPath: string[];
}

const GraphEditorToolbar = ({
    targetModule,
    targetPath,
}: GraphEditorToolbarProps) => {
    const {
        startEditDataForModule,
        startInsertIntoModule,
        startEditModule
    } = useBuilderData();

    return (
        <Toolbar
            variant="dense"
            disableGutters
            sx={{
                width: "100%",
                display: "flex",
                justifyContent: "flex-end",
                position: "absolute",
                top: 0,
                left: 0
            }}>
            <Box zIndex={2}>
                <IconButton
                    onClick={() => {
                        startInsertIntoModule([...targetPath, targetModule.id])
                    }}
                >
                    <Add />
                </IconButton>
                <IconButton
                    onClick={() => {
                        startEditDataForModule([...targetPath, targetModule.id]);
                    }}
                >
                    <Dataset />
                </IconButton>
                <IconButton
                    onClick={() => {
                        startEditModule([...targetPath, targetModule.id]);
                    }}
                >
                    <Edit />
                </IconButton>
            </Box>
        </Toolbar>
    );
};

export default GraphEditorToolbar;