import React, { useEffect, useState, memo } from 'react';
import { Box, Button, Divider, Stack, TextField } from "@mui/material";
import { useResearchPanelData } from '../../../../containers/ResearchPanelData/ResearchPanelData';
import { useAddSourceMaterialsTransformAnalysisMutation } from '../../../../redux/services/taker';
import { useTakerState } from '../../../../containers/TakerDocumentState/TakerDocumentState';
import { Send } from '@mui/icons-material';
import { useDebounce } from '@uidotdev/usehooks';
import CustomFlowFilter from './CustomFlowFilter';

const CustomFlow = () => {
    const {
        taker,
        takerDocumentId
    } = useTakerState();
    const {
        researchPanelAdvisorState,
        setResearchPanelAdvisorState,
        applyLiteratureFilter,
        activeGuidanceIds
    } = useResearchPanelData();
    const [
        addSMTransform,
        addSMTransformResult
    ] = useAddSourceMaterialsTransformAnalysisMutation();

    const [localQuery, setLocalQuery] = useState(researchPanelAdvisorState.customState?.query);
    const debouncedLocalQuery = useDebounce(localQuery, 1000);

    useEffect(() => {
        if (!taker || researchPanelAdvisorState.customState === undefined) {
            return;
        }

        setResearchPanelAdvisorState(prev => ({
            ...researchPanelAdvisorState,
            customState: {
                ...researchPanelAdvisorState.customState,
                query: debouncedLocalQuery
            }
        }));
    }, [
        debouncedLocalQuery
    ]);

    return (
        <Stack
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            border="1px solid lightgray"
            borderRadius={1}
            marginBottom={0.5}
            divider={
                <Divider 
                    sx={{ 
                        marginLeft: 1, 
                        marginRight: 1
                    }} 
                    orientation="horizontal" 
                    flexItem 
                />
            }
        >
            <TextField
                value={localQuery}
                onChange={(e) => {
                    setLocalQuery(e.target.value);
                }}
                placeholder="Type request here..."
                variant="standard"
                size="small"
                fullWidth
                multiline
                maxRows={4}
                inputProps={{
                    sx: { padding: 1 }
                }}
                InputProps={{
                    disableUnderline: true,
                    autoComplete: 'off'
                }}
            />
            <Box 
                width="100%"
                display="flex"
                justifyContent="space-between"   
                alignItems="center" 
                padding={1}
            >
                <CustomFlowFilter />
                <Button
                    color="primary"
                    size="small"
                    variant="contained"
                    disabled={addSMTransformResult.isLoading || !taker || researchPanelAdvisorState.customState === undefined || localQuery === ""} 
                    sx={{ margin: 0 }}
                    onClick={() => {
                        if (!taker || researchPanelAdvisorState.customState === undefined) {
                            return;
                        }

                        addSMTransform({
                            takerId: taker.id,
                            takerDocumentId: takerDocumentId,
                            transformType: "CUSTOM",
                            customQuery: localQuery,
                            guidanceFilter: (applyLiteratureFilter ? activeGuidanceIds : [])
                        });
                    }}
                    endIcon={<Send />}
                >
                    Send
                </Button>
            </Box>
        </Stack>
    );
};

export default memo(CustomFlow);

