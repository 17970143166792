import React, { useState } from "react";
import Box from '@mui/material/Box';
import { SimpleModalWrapper } from "../../../components/dialog/wrappers/simpleModalWrapper";
import { Module } from "../../../types/builderv2.generated";
import { Button, List, ListItem, ListItemText, Tab, Tabs, TextField } from "@mui/material";
import TabPanel, { a11yProps } from "../../../components/navigation/TabPanel";
import { PointData, SourceAndTargetInfo } from "./common";
import { RJSFSchema } from "@rjsf/utils";
import { getPoints } from "../../../components/reactflow/utils";

interface EditEdgeModalProps {
    fullSchema: RJSFSchema;
    parentModule: Module | undefined;
    initialTopModule: Module;
    initialSourceAndTargetInfo: SourceAndTargetInfo;
    initialControlPoints: PointData[];
    intitialEdgeLabelStartAtPoint: number;
    onUpdatePositioningData: (
        controlPoints: PointData[],
        edgeLabelStartAtPoint: number
    ) => void;
    onClose: () => void;
}

const EditEdgeModal = ({
    fullSchema,
    parentModule,
    initialTopModule,
    initialSourceAndTargetInfo,
    initialControlPoints,
    intitialEdgeLabelStartAtPoint,
    onUpdatePositioningData,
    onClose
}: EditEdgeModalProps) => {
    const [tabValue, setTabValue] = useState(0);
    const [controlPointsLength, setControlPointsLength] = useState<number>(initialControlPoints.length);
    const [controlPoints, setControlPoints] = useState<PointData[]>(initialControlPoints);
    const [edgeLabelStartAtPoint, setEdgeLabelStartAtPoint] = useState<number>(intitialEdgeLabelStartAtPoint);

    return (
        <SimpleModalWrapper
            headerText={`Editing Edge Properties`}
            open
            handleClose={onClose}
            maxWidth='md'
        >
            <Box width="100%">
                <Tabs
                    value={tabValue}
                    variant="scrollable"
                    scrollButtons="auto"
                    onChange={(event: React.SyntheticEvent, newValue: number) => setTabValue(newValue)}
                >
                    <Tab
                        label="Visual Properties"
                        {...a11yProps(0)}
                    />
                    <Tab
                        label="Other Properties"
                        disabled
                        {...a11yProps(1)}
                    />
                </Tabs>
            </Box>
            <Box
                width="100%"
                overflow="auto"
                flexGrow={1}
                zIndex={1}
            >
                <TabPanel value={tabValue} index={0}>
                    <Box padding={1}>
                        {/* Edit the edge label start point */}
                        <TextField
                            sx={{ m: 1 }}
                            label="Edge Label Start Point"
                            size="small"
                            type="number"
                            value={edgeLabelStartAtPoint}
                            onChange={(e) => {
                                setEdgeLabelStartAtPoint(parseInt(e.target.value));
                            }}
                        />

                        {/* Remove existing or add a new control point by just adjusting the # of points */}
                        <TextField
                            sx={{ m: 1 }}
                            label="Number of Control Points"
                            size="small"
                            type="number"
                            value={controlPointsLength}
                            onChange={(e) => {
                                const newPointLength = parseInt(e.target.value);

                                // draw n=newPointLength points between the source and target
                                // such that the space between the points creates n + 1 identical 
                                // length segments
                                const sourceX = initialSourceAndTargetInfo.sourceX;
                                const sourceY = initialSourceAndTargetInfo.sourceY;
                                const targetX = initialSourceAndTargetInfo.targetX;
                                const targetY = initialSourceAndTargetInfo.targetY;
                                const deltaX = targetX - sourceX;
                                const deltaY = targetY - sourceY;
                                const segmentLength = Math.hypot(deltaX, deltaY) / (newPointLength + 1);
                                let newControlPoints: PointData[] = [];
                                for (let i = 0; i < newPointLength; i++) {
                                    const x = sourceX + (i + 1) * segmentLength * deltaX / Math.hypot(deltaX, deltaY);
                                    const y = sourceY + (i + 1) * segmentLength * deltaY / Math.hypot(deltaX, deltaY);
                                    newControlPoints.push({ 
                                        id: window.crypto.randomUUID(),
                                        x, 
                                        y 
                                    });
                                }

                                setControlPoints(newControlPoints);
                                setControlPointsLength(newPointLength);
                            }}
                        />
                        <Button
                            sx={{ m: 1 }}
                            variant="contained"
                            onClick={() => {
                                const sourceX = initialSourceAndTargetInfo.sourceX;
                                const sourceY = initialSourceAndTargetInfo.sourceY;
                                const targetX = initialSourceAndTargetInfo.targetX;
                                const targetY = initialSourceAndTargetInfo.targetY;
                                
                                let newPoints = getPoints({
                                    source: {
                                        x: sourceX, 
                                        y: sourceY
                                    }, 
                                    target: {
                                        x: targetX, 
                                        y: targetY
                                    }, 
                                    offset: 0
                                });
                                
                                let newControlPoints = newPoints.map(p => ({
                                    id: window.crypto.randomUUID(),
                                    x: p.x,
                                    y: p.y
                                }));
                                setControlPoints(newControlPoints);
                                setControlPointsLength(newControlPoints.length);
                            }}
                        >
                            Draw Path
                        </Button>
                    </Box>
                    <Box
                        padding={1}
                        width="100%"
                        display="flex"
                    >
                        {/* display all control points in List */}
                        <List sx={{ width: '100%' }}>
                            {controlPoints.map((point, index) => (
                                <ListItem key={point.id}>
                                    <ListItemText
                                        primary={`Point ${index + 1}`}
                                        secondary={`(${point.x}, ${point.y})`}
                                    />
                                </ListItem>
                            ))}
                        </List>
                    </Box>
                    <Box
                        padding={1}
                        width="100%"
                        display="flex"
                        justifyContent="flex-end"
                        alignItems="center"
                    >
                        <Button
                            sx={{ m: 1 }}
                            variant="contained"
                            onClick={() => {
                                // update positioning data
                                onUpdatePositioningData(
                                    controlPoints,
                                    edgeLabelStartAtPoint
                                );
                                onClose();
                            }}
                        >
                            Save
                        </Button>
                    </Box>
                </TabPanel>
                <TabPanel value={tabValue} index={1}>

                </TabPanel>
            </Box>
        </SimpleModalWrapper>
    );
}

export default EditEdgeModal;