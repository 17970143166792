import React, { useState } from "react";
import Box from '@mui/material/Box';
import { SimpleModalWrapper } from "../../../components/dialog/wrappers/simpleModalWrapper";
import { EmbedAModuleInsideOfAGraph, EndGraphNode, Module, UseAQuestionToFulfillData } from "../../../types/builderv2.generated";
import { RJSFSchema } from '@rjsf/utils';
import validator from '@rjsf/validator-ajv8';
import { Form } from "../../../components/jsonschema/theme";
import CustomCommentaryWidget from "../SpecBuilder/CustomCommentaryWidget";

type Node = UseAQuestionToFulfillData | EmbedAModuleInsideOfAGraph | EndGraphNode;

interface EditQuestionModalProps {
    nodeId: string;
    fullSchema: RJSFSchema;
    parentModule: Module | undefined;
    initialTopModule: Module;
    onUpdate: (b: Module) => void;
    onClose: () => void;
}

const EditQuestionModal = ({
    nodeId,
    fullSchema,
    parentModule,
    initialTopModule,
    onUpdate,
    onClose
}: EditQuestionModalProps) => {

    // set the target node we are editing
    let editNode: Node | undefined;
    if (parentModule?.uiFulfillment?.fulfillmentType === "graph") {
        editNode = parentModule.uiFulfillment.nodes?.find(node => node.id === nodeId);
    }

    const searchModuleHelper = (moduleId: string, module: Module): Module | null => {
        if (moduleId === module.id) {
            return module;
        }

        if (module.nestedModules) {
            for (const m of module.nestedModules) {
                let res = searchModuleHelper(moduleId, m);
                if (!!res) {
                    return res;
                }
            }
        }
        return null;
    };

    const addModuleHelper = (moduleId: string, moduleToAdd: Module, modules: Module[]) => {
        const newModules: Module[] = [];
        for (const m of modules) {
            const newModule: Module = JSON.parse(JSON.stringify(m));
            if (!newModule.nestedModules) {
                newModule.nestedModules = [];
            }
            newModule.nestedModules = addModuleHelper(moduleId, moduleToAdd, newModule.nestedModules);
            if (m.id === moduleId) {
                newModule.nestedModules.push(JSON.parse(JSON.stringify(moduleToAdd)));
            }
            newModules.push(newModule);
        }
        return newModules;
    };

    const searchParentModuleHelper = (moduleId: string, module: Module): Module | null => {
        if (module.nestedModules) {
            for (const m of module.nestedModules) {
                if (m.id === moduleId) {
                    return module;
                } else {
                    let res = searchParentModuleHelper(moduleId, m);
                    if (!!res) {
                        return res;
                    }
                }
            }
        }
        return null;
    };

    const updateModuleHelper = (moduleToUpdate: Module, modules: Module[]) => {
        const newModules: Module[] = [];
        for (const m of modules) {
            const newModule: Module = JSON.parse(JSON.stringify(m));
            if (m.id === moduleToUpdate.id) {
                newModules.push(JSON.parse(JSON.stringify(moduleToUpdate)));
            } else if (newModule.nestedModules) {
                newModule.nestedModules = updateModuleHelper(moduleToUpdate, newModule.nestedModules);
                newModules.push(newModule);
            } else {
                newModules.push(newModule);
            }
        }
        return newModules;
    };

    const updateModule = (m: Module) => {
        const newTopModule = updateModuleHelper(m, [initialTopModule])[0];
        onUpdate(newTopModule);
    };

    const onDeleteHelper = (moduleId: string, modules: Module[]) => {
        const newModules: Module[] = [];
        for (const m of modules) {
            if (m.id !== moduleId) {
                const newModule: Module = JSON.parse(JSON.stringify(m));
                if (m.nestedModules) {
                    newModule.nestedModules = onDeleteHelper(moduleId, m.nestedModules);
                }
                newModules.push(newModule);
            }
        }
        return newModules;
    };

    const updateNode = (node: Node) => {
        if (parentModule?.uiFulfillment?.fulfillmentType !== "graph") {
            return;
        }

        const newNodes = JSON.parse(JSON.stringify(parentModule.uiFulfillment.nodes));
        for (let i = 0; i < newNodes.length; i++) {
            if (node.id === newNodes[i].id) {
                newNodes[i] = node;
            }
        }

        let newMod: Module = JSON.parse(JSON.stringify(parentModule));
        if (newMod.uiFulfillment && newMod.uiFulfillment.fulfillmentType === "graph") {
            newMod.uiFulfillment.nodes = newNodes;
            updateModule(newMod);
        }
    };

    return (
        <SimpleModalWrapper
            headerText="Edit Node"
            open={!!editNode}
            handleClose={onClose}
            maxWidth='md'
        >
            <Box
                sx={{
                    width: '100%',
                    paddingTop: '3%',
                    alignContent: "center"
                }}
            >
                {editNode?.nodeType === "question" && (
                    <Form
                        formData={editNode}
                        onSubmit={(data, event) => {
                            updateNode(data.formData);
                            onClose()
                        }}
                        uiSchema={{
                            questionCommentary: {
                                'ui:widget': 'CustomCommentaryWidget'
                            }
                        }}
                        widgets={{
                            CustomCommentaryWidget
                        }}
                        schema={({
                            $ref: "#/$defs/graphFulfillmentQuestionNode",
                            $defs: fullSchema.$defs
                        } as RJSFSchema)}
                        validator={validator}
                    />
                )}
                {editNode?.nodeType === "module" && (
                    <Form
                        formData={editNode}
                        onSubmit={(data, event) => {
                            updateNode(data.formData);
                            onClose();
                        }}
                        schema={({
                            $ref: "#/$defs/graphFulfillmentModuleNode",
                            $defs: fullSchema.$defs
                        } as RJSFSchema)}
                        validator={validator}
                    />
                )}
                {editNode?.nodeType === "end" && (
                    <Form
                        formData={editNode}
                        onSubmit={(data, event) => {
                            updateNode(data.formData);
                            onClose();
                        }}
                        schema={({
                            $ref: "#/$defs/graphFulfillmentEndNode",
                            $defs: fullSchema.$defs
                        } as RJSFSchema)}
                        validator={validator}
                    />
                )}
            </Box>
        </SimpleModalWrapper>
    );
}


export default EditQuestionModal;
