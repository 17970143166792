import React, { memo, useEffect, useState } from 'react';
import { Box, Stack, Typography } from '@mui/material';
import { LexicalComposer } from "@lexical/react/LexicalComposer";
import { RichTextPlugin } from "@lexical/react/LexicalRichTextPlugin";
import { ContentEditable } from "@lexical/react/LexicalContentEditable";
import { TablePlugin } from '@lexical/react/LexicalTablePlugin';
import LexicalErrorBoundary from "@lexical/react/LexicalErrorBoundary";
import { HeadingNode } from "@lexical/rich-text";
import { TableCellNode, TableNode, TableRowNode } from "@lexical/table";
import { ListItemNode, ListNode } from "@lexical/list";
import { ListPlugin } from "@lexical/react/LexicalListPlugin";
import EditorThemeClasses from "../../../components/lexical/wrappers/RichTextEditor/theme";
import { HistoryPlugin } from "@lexical/react/LexicalHistoryPlugin";
import { TabIndentationPlugin } from "@lexical/react/LexicalTabIndentationPlugin";
import { HorizontalRulePlugin } from "@lexical/react/LexicalHorizontalRulePlugin";
import { ClearEditorPlugin } from "@lexical/react/LexicalClearEditorPlugin";
import { AutoFocusPlugin } from "@lexical/react/LexicalAutoFocusPlugin";
import TableCellResizerPlugin from "../../../components/lexical/wrappers/RichTextEditor/TableCellResizer";
import SyncSummaryEditorPlugin from "../../../components/form/SyncSummaryEditorPlugin";
import { useLazyGetLatestKeyTermGroupDataQuery } from '../../../redux/services/takerData';
import { DocumentKeyTermsHolder } from '../../../types/documentKeyTerms';
import { KeyTermNode } from '../../../components/lexical/nodes/KeyTermNode';

import '../../../components/lexical/wrappers/RichTextEditor/index.css';

interface KeyTermGroupProps {
    takerDocumentUploadId: string;
}

const KeyTermGroup = ({ takerDocumentUploadId }: KeyTermGroupProps) => {
    const [latestKeyTermsHolder, setLatestKeyTermsHolder] = useState<DocumentKeyTermsHolder>();
    const [isFetching, setIsFetching] = useState(false);
    const [isError, setIsError] = useState(false);

    const [trigger] = useLazyGetLatestKeyTermGroupDataQuery();


    useEffect(() => {
        setIsFetching(true);
        trigger(takerDocumentUploadId)
            .unwrap()
            .then((data) => {
                setLatestKeyTermsHolder(new DocumentKeyTermsHolder(data.content));
                setIsError(false);
            })
            .catch(() => {
                setIsError(true);
            })
            .finally(() => {
                setIsFetching(false);
            });
    }, [takerDocumentUploadId]);        

    if (isError) {
        return (
            <Box paddingTop={1}>
                <Typography
                    variant="subtitle2"
                    gutterBottom
                >
                    No key terms
                </Typography>
            </Box>
        );
    }

    if (isFetching) {
        return (
            <Box paddingTop={1}>
                <Typography
                    variant="subtitle2"
                    gutterBottom
                >
                    Loading...
                </Typography>
            </Box>
        );
    }

    return (
        <Stack 
            spacing={1} 
            paddingTop={1}
        >
            {(latestKeyTermsHolder !== undefined) ? (
                latestKeyTermsHolder.documentKeyTerms.keyTerms.map(kt => (
                    <Box
                        sx={{
                            width: '100%',
                            borderRadius: 1,
                            border: "1px solid rgb(195, 195, 195)",
                            backgroundColor: 'white',
                        }}
                    >
                        <Typography
                            variant="subtitle2"
                            gutterBottom
                            sx={{ 
                                paddingTop: 0.5,
                                paddingLeft: 1 
                            }}
                        >
                            <u>{kt.termName}</u>
                        </Typography>
                        <Box paddingBottom={1}>
                            <LexicalComposer
                                initialConfig={{
                                    editable: false,
                                    namespace: `view-summary-${kt.termName}`,
                                    theme: EditorThemeClasses,
                                    onError(error: any) {
                                        console.error(error);
                                    },
                                    nodes: [
                                        HeadingNode,
                                        ListNode,
                                        ListItemNode,
                                        TableNode,
                                        TableCellNode,
                                        TableRowNode,
                                        KeyTermNode
                                    ],
                                }}
                            >
                                <SyncSummaryEditorPlugin defaultValue={kt.summary} />
                                <RichTextPlugin
                                    contentEditable={
                                        <div className="editor-scroller">
                                            <div className="editor">
                                                <ContentEditable
                                                    className="editor-input"
                                                    style={{
                                                        paddingTop: '0px',
                                                        paddingBottom: '0px',
                                                        minHeight: '25px'
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    }
                                    placeholder={<div className="editor-placeholder"></div>}
                                    ErrorBoundary={LexicalErrorBoundary}
                                />
                                <ListPlugin />
                                <HistoryPlugin />
                                <AutoFocusPlugin />
                                <TabIndentationPlugin />
                                <HorizontalRulePlugin />
                                <ClearEditorPlugin />
                                <TablePlugin hasCellMerge />
                                <TableCellResizerPlugin />
                            </LexicalComposer>
                        </Box>
                    </Box>
                ))
            ) : (
                <Typography
                    variant="subtitle2"
                    gutterBottom
                >
                    No key terms
                </Typography>
            )}
        </Stack>
    );
};

export default memo(KeyTermGroup);