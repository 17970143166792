import React from 'react';
import { Box, IconButton, Tooltip } from "@mui/material";
import MiniToolbarWithShadow from "../../../components/navigation/Toolbars/MiniToolbarWithShadow";
import { ZoomIn, ZoomOut, ZoomOutMap, Menu } from "@mui/icons-material";
import { useResearchPanelData } from '../../../containers/ResearchPanelData/ResearchPanelData';
import GuidanceSearch from './GuidanceSearch';

const MIN_ZOOM_LEVEL = 0.97;
const MAX_ZOOM_LEVEL = 2.97;

interface GuidanceToolbarProps {
    zoomLevel: number;
    setZoomLevel: (level: number) => void
}

const GuidanceToolbar = ({
    zoomLevel,
    setZoomLevel
} : GuidanceToolbarProps) => {
    const {
        setGuidanceOutlineOpen,
        targetDisplayMode
    } = useResearchPanelData();

    return (
        <MiniToolbarWithShadow
            variant="dense"
            disableGutters
            data-testid="guidance-toolbar"
            sx={{
                paddingLeft: 1,
                paddingRight: 1,
                zIndex: 10001,
                height: "48px",
                width: "100%",
                display: "inline-grid",
                gridTemplateColumns: "1fr 2fr 1fr",
            }}
        >
            <Box padding={1} gap={1}>
                <Tooltip title="Outline">
                    <IconButton
                        onClick={() => setGuidanceOutlineOpen(prev => !prev)}
                    >
                        <Menu />
                    </IconButton>
                </Tooltip>
            </Box>
            <GuidanceSearch />
            <Box
                display="flex"
                alignItems="center"
                justifyContent="flex-end"
            >
                <Tooltip title="Zoom in">
                    <IconButton
                        data-testid="zoom-in-button"
                        disabled={targetDisplayMode !== "PDF"}
                        onClick={() => setZoomLevel(Math.min((zoomLevel ?? MIN_ZOOM_LEVEL) + 0.25, MAX_ZOOM_LEVEL))}
                    >
                        <ZoomIn />
                    </IconButton>
                </Tooltip>
                <Tooltip title="Zoom out">
                    <IconButton
                        data-testid="zoom-out-button"
                        disabled={targetDisplayMode !== "PDF"}
                        onClick={() => setZoomLevel(Math.max((zoomLevel ?? MIN_ZOOM_LEVEL) - 0.25, MIN_ZOOM_LEVEL))}
                        sx={{ marginLeft: "5px" }}
                    >
                        <ZoomOut />
                    </IconButton>
                </Tooltip>
                <Tooltip title="Reset zoom">
                    <IconButton
                        data-testid="zoom-out-map"
                        disabled={targetDisplayMode !== "PDF"}
                        onClick={() => { setZoomLevel(MIN_ZOOM_LEVEL) }}
                        sx={{ marginLeft: "5px" }}
                    >
                        <ZoomOutMap />
                    </IconButton>
                </Tooltip>
            </Box>
        </MiniToolbarWithShadow>
    );
};

export default GuidanceToolbar;
