import React, { useState, memo } from 'react';
import { ListItemText, MenuItem, IconButton, ListItemIcon, Switch, ToggleButtonGroup, ToggleButton } from '@mui/material';
import { FilterList } from '@mui/icons-material';
import StyledMenu from '../../menu';
import { useResearchPanelData } from '../../../containers/ResearchPanelData/ResearchPanelData';
import { useGuidanceData } from '../../../containers/GuidanceData';

const GuidanceOutlineFilter = () => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const {
        activeGuidanceIds,
        setActiveGuidanceIds,
        viewTypeByGuidanceId,
        setViewTypeByGuidanceId
    } = useResearchPanelData();
    const { guidanceReferences } = useGuidanceData();

    const bdgToDisplay = guidanceReferences.filter(bdg => bdg.displayMode !== "NONE");

    return (
        <>
            <IconButton
                data-testid="guidance-outline-filter-button"
                sx={{
                    margin: 1
                }}
                size="medium"
                onClick={(event: React.MouseEvent<HTMLElement>) => {
                    setAnchorEl(event.currentTarget);
                }}
            >
                <FilterList fontSize="inherit" />
            </IconButton>
            <StyledMenu
                data-testid="guidance-outline-filter-menu"
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={() => setAnchorEl(null)}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                slotProps={{
                    paper: {
                        style: {
                            maxHeight: "350px",
                            width: "35ch",
                        },
                    },
                }}
            >
                <MenuItem>
                    <ListItemText primary="Enabled Literature" />
                </MenuItem>
                {bdgToDisplay.map((bdg) => {
                    if (!bdg.indexedGuidance) {
                        return null;
                    }

                    const thisDisplayMode = bdg.displayMode;
                    const thisGuidanceId = bdg.indexedGuidance.guidanceId;
                    const isActive = activeGuidanceIds.includes(thisGuidanceId);

                    let currentViewType = viewTypeByGuidanceId[thisGuidanceId];
                    if (!currentViewType) {
                        currentViewType = thisDisplayMode === "PDF" ? "PDF" : "NATIVE";
                    }
                    return (
                        <MenuItem>
                            <ListItemIcon>
                                <Switch
                                    checked={isActive}
                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                        if (event.target.checked) {
                                            setActiveGuidanceIds([...activeGuidanceIds, thisGuidanceId]);
                                        } else {
                                            setActiveGuidanceIds(activeGuidanceIds.filter(id => id !== thisGuidanceId));
                                        }
                                    }}
                                />
                            </ListItemIcon>
                            <ListItemText
                                primary={bdg.indexedGuidance.name}
                                primaryTypographyProps={{
                                    noWrap: true
                                }}
                            />
                            <ToggleButtonGroup
                                size="small"
                                disabled={!isActive}
                                exclusive
                                value={currentViewType}
                                onChange={(e, value) => {
                                    setViewTypeByGuidanceId(prev => ({
                                        ...prev,
                                        [thisGuidanceId]: value
                                    }));
                                }}
                            >
                                {thisDisplayMode === "PDF" && (
                                    <ToggleButton value="PDF">PDF</ToggleButton>
                                )}
                                <ToggleButton value="NATIVE">Native</ToggleButton>
                            </ToggleButtonGroup>
                        </MenuItem>
                    );
                })}
            </StyledMenu>
        </>
    );
}

export default memo(GuidanceOutlineFilter);