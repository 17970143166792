import React, { useState, useEffect } from "react";
import FullConfig, { ConfigEntry } from "../../components/form/EntryBuilder";
import axios from "axios";
import { Assistant } from "../../redux/models/dataModelTypes";

interface Props {
  builderDocumentId: string;
}

export function ModelSettings({ builderDocumentId }: Props) {
  const [assistants, setAssistants] = useState<Array<Assistant>>([]);
  const [assistantAssignments, setAssistantAssignments] = useState<Array<any>>([]);

  const fetchAssistantAssignments = () => {
    axios.get(
      `${window.__RUNTIME_CONFIG__.API_ENDPOINT}/assistant_assignments?page=0&limit=1000&builder_document_id=${builderDocumentId}`,
      { withCredentials: true }
    ).then((data) => {
      let res = data.data;
      setAssistantAssignments(res.data);
    }).catch((e) => {
      console.error(e);
    });
  };

  const deleteAssistantAssignment = async (id: string) => {
    const res = await axios.delete(
      `${window.__RUNTIME_CONFIG__.API_ENDPOINT}/assistant_assignments/${id}`,
      { withCredentials: true }
    );
    setAssistantAssignments(assistantAssignments.filter((aa) => aa.id !== id));
  }

  const createAssistantAssignment = async (assistantId: string) => {
    const res = await axios.post(
      `${window.__RUNTIME_CONFIG__.API_ENDPOINT}/assistant_assignments`,
      {
        builder_document_id: builderDocumentId,
        assistant_id: assistantId
      },
      { withCredentials: true }
    );
    fetchAssistantAssignments();
  };

  useEffect(() => {
    fetchAssistantAssignments();
  }, []);

  useEffect(() => {
    axios.get(
      `${window.__RUNTIME_CONFIG__.API_ENDPOINT}/assistants?page=0&limit=1000`,
      { withCredentials: true }
    ).then((data) => {
      let res = data.data;
      setAssistants(res.data);
    }).catch((e) => {
      console.error(e);
    });
  }, []);

  return (
    <FullConfig
      options={(assistants || []).map((a) => ({
        id: a.id,
        display: a.name
      }))}
      additionalFields={[]}
      entries={assistantAssignments.map((aa) => ({
        id: aa.id,
        key: aa.assistant.id
      } as ConfigEntry))}
      onDeleteEntry={(ce: ConfigEntry) => {
        deleteAssistantAssignment(ce.id);
      }}
      keyOptionsGetter={(k: string) => undefined}
      onCreate={(d: any) => {
        createAssistantAssignment(d.key);
      }}
      disableData
    />
  );
}

export default ModelSettings;
