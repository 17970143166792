import React, { Suspense, useState, memo } from "react";
import SideNav, { APP_BAR_HEIGHT, useSideNav } from "../../components/navigation/SideNav";
import { useSelector } from "../../redux/reduxUtils/functions";
import { RootReducerType } from "../../redux/models/reduxTypes";
import RectangularLoading from "../../components/loading/RectangularLoading";
import { useUserScopedAppData } from "../../containers/UserScopedAppData/UserScopedAppData";
import { useGetOrganizationQuery } from "../../redux/services/organization";
import GuidanceData from "../../containers/GuidanceData";
import ResearchPanelData, { useResearchPanelData } from "../../containers/ResearchPanelData/ResearchPanelData";
import { Box, Stack, Typography } from "@mui/material";
import AdvisorHeader from "../../components/research/Advisor/AdvisorHeader";
import AdvisorFlow from "../../components/research/Advisor/AdvisorFlow";
import AdvisorResponses from "../../components/research/Advisor/AdvisorUserResponses";
import GuidanceOutline from '../../components/research/GuidanceMain/GuidanceOutline';
import GuidanceToolbar from "../../components/research/GuidanceMain/GuidanceToolbar";
import RectangularSkeleton from "../../components/loading/RectangularSkeleton";
import {
    QueryClient,
    QueryClientProvider
} from '@tanstack/react-query';

const queryClient = new QueryClient()

const GuidancePdfViewLazy = React.lazy(() => import('../../components/research/GuidanceMain/GuidancePdfView'));
const GuidanceLexicalViewLazy = React.lazy(() => import('../../components/research/GuidanceMain/GuidanceLexicalView'));

const ResearchComponent = () => {
	const {
		isOpen: isOpenSideNav
	} = useSideNav();

	const {
		targetDisplayMode,
		guidanceOutlineOpen
	} = useResearchPanelData();

	const [zoomLevel, setZoomLevel] = useState<number>(0.97);

	const drawerContainerStyle: React.CSSProperties = {
		height: `calc(100vh - ${2 * APP_BAR_HEIGHT}px)`,
		width: '100%',
		overflow: 'hidden',
	}

	const otherChildrenStyle: React.CSSProperties = {
		height: `calc(100vh - ${2 * APP_BAR_HEIGHT}px)`,
		zIndex: 1,
	};

	if (guidanceOutlineOpen) {
		otherChildrenStyle.width = (isOpenSideNav ? "calc(50vw - 300px)" : "calc(50vw - 200px)");
		otherChildrenStyle.marginLeft = '200px';
	} else {
		otherChildrenStyle.width = (isOpenSideNav ? "calc(50vw - 100px)" : "50vw");
	}

	return (
		<Stack 
			direction="row"
			divider={<div style={{ width: '1px', backgroundColor: '#ddd' }} />}
		>
			<Box
				padding={1}
				height="calc(100vh - 48px)"
				width="50%"
				display="flex"
				flexDirection="column"
			>
				<AdvisorHeader />
				<AdvisorFlow />
				<AdvisorResponses />
			</Box>
			<Box
				height="calc(100vh - 48px)"
				width="50%"
				display="flex"
				flexDirection="column"
			>
				<GuidanceToolbar
					zoomLevel={zoomLevel}
					setZoomLevel={setZoomLevel}
				/>
				<Box sx={drawerContainerStyle}>
					<GuidanceOutline
						topBuffer={2}
						leftPosition={isOpenSideNav ? "calc(50vw + 100px)" : "50vw"}
					/>
					<Box style={otherChildrenStyle}>
						{targetDisplayMode === "NONE" ? (
							<Box
								display="flex"
								justifyContent="center"
								alignItems="center"
								height="100%"
								width="100%"
							>
								<Typography variant="h6">Select Literature</Typography>
							</Box>
						) : (
							<Suspense fallback={<RectangularSkeleton />}>
								{targetDisplayMode === "PDF" && (
									<GuidancePdfViewLazy
										zoomLevel={zoomLevel}
									/>
								)}
								{(targetDisplayMode === "LEXICAL") && (
									<GuidanceLexicalViewLazy />
								)}
							</Suspense>
						)}
					</Box>
				</Box>
			</Box>
		</Stack>
	);
}

const MemoizedResearchComponent = memo(ResearchComponent);

const Research = () => {
	const { user } = useSelector((state: RootReducerType) => state.auth);
	const { selectedOrgId } = useUserScopedAppData();
	const {
		data: organization
	} = useGetOrganizationQuery(selectedOrgId);

	return (
		<QueryClientProvider client={queryClient}>
			<SideNav>
				{(!user || !organization) ? (
					<RectangularLoading data-testid="loading-taker" />
				) : (
					<GuidanceData guidanceReferences={organization.organizationGuidance.map(g =>
						({
							indexedGuidance: g.indexedGuidance,
							displayMode: g.displayMode,
							indexedGuidanceVersion: g.indexedGuidanceVersion,
							canCite: g.canCite
						})
					)}>
						<ResearchPanelData displayContext="STANDALONE">
							<MemoizedResearchComponent />
						</ResearchPanelData>
					</GuidanceData>
				)}
			</SideNav>
		</QueryClientProvider>
	);
}

export default Research;
